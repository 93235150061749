export const searchParamsForPage = {
  mentorsPage: {
    CURRENT_PAGE: 'page',
    SEARCH: 'search',
  },
  mentorDetailPage: {
    PREVIOUS_MENTORS_PAGE: 'previous-mentors-page',
  },

  eventList: {
    CURRENT_PAGE: 'page',
    SEARCH: 'search',
  },

  bookingPage: {
    tabStatusBooking: 'tab',
  },
};
