import { firebaseConfig } from '@/utils/firebaseConfig';
import ZoomVideo from '@zoom/videosdk';
import 'ckeditor5/ckeditor5.css';
import { initializeApp } from 'firebase/app';
import i18next from 'i18next';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { I18nextProvider } from 'react-i18next';
import 'react-loading-skeleton/dist/skeleton.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { SWRConfig } from 'swr';
import './App.css';
import ZoomContext from './context/zoom-context';
import { RouterApp } from './router/router';
import { initAppTranslation } from './translations';
const client = ZoomVideo.createClient();

function App() {
  initAppTranslation();
  initializeApp(firebaseConfig);

  return (
    <HelmetProvider>
      <I18nextProvider i18n={i18next}>
        <Helmet async>
          <script src="https://cdn.jsdelivr.net/npm/jquery@3.7.1/dist/jquery.min.js" type="text/javascript"></script>
          <script src="https://www.gstatic.com/firebasejs/10.7.1/firebase-app-compat.js"></script>
          <script src="https://www.gstatic.com/firebasejs/10.7.1/firebase-firestore-compat.js"></script>
        </Helmet>
        <ZoomContext.Provider value={client}>
          <SWRConfig
            value={{
              refreshInterval: 3000,
              fetcher: (resource, init) => fetch(resource, init).then((res) => res.json()),
            }}
          >
            <RouterApp />
          </SWRConfig>
          <ToastContainer
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
          />
        </ZoomContext.Provider>
      </I18nextProvider>
    </HelmetProvider>
  );
}

export default App;
