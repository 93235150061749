import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EventData, EventResponse } from '@/shared/interface';
import { IDataPaymentIntentEvent, IDataSavedEvent } from '../interfaces';

interface EventState {
  data: EventResponse | null;
  singleEventData: EventData | null;
  loading: boolean;
  error?: string;
  loadingGetSavedEvent: boolean;
  loadingGetEventDetail: boolean;
  loadingCreatePaymentRegisterEvent: boolean;
  paymentIntentEvent: IDataPaymentIntentEvent | null;
  paymentRegisterEventSuccess: boolean;
  paymentRegisterError: string;
  savedEvent: IDataSavedEvent | null;
  loadingCancelEvent: boolean;
  cancelEventFail: boolean;
  cancelEventSuccess: boolean;
  saveEventFailure: boolean;
}

const initialState: EventState = {
  data: null,
  singleEventData: null,
  loading: false,
  error: undefined,
  loadingGetSavedEvent: false,
  loadingGetEventDetail: false,
  loadingCreatePaymentRegisterEvent: false,
  paymentIntentEvent: null,
  paymentRegisterEventSuccess: false,
  paymentRegisterError: '',
  savedEvent: null,
  loadingCancelEvent: false,
  cancelEventFail: false,
  cancelEventSuccess: false,
  saveEventFailure: false,
};

export const eventSlide = createSlice({
  name: 'EventSlide',
  initialState,
  reducers: {
    getEvent: (state: EventState) => {
      state.loading = true;
    },
    getEventFailure: (state: EventState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.data = {
        data: {
          completed: [],
          upcoming: [],
          pending: [],
        },
      };
      state.error = action.payload;
    },
    getEventSuccess: (state: EventState, action: PayloadAction<EventResponse>) => {
      state.loading = false;
      state.data = action.payload;
    },
    getSingleEventState: (state: EventState) => {
      state.loadingGetEventDetail = true;
      state.singleEventData = null;
      state.error = '';
    },
    getSingleEventSuccess: (state: EventState, action: PayloadAction<EventData | null>) => {
      state.loadingGetEventDetail = false;
      state.singleEventData = action.payload;
    },
    getSingleEventFailure: (state: EventState, action: PayloadAction<string | undefined>) => {
      state.loadingGetEventDetail = false;
      state.singleEventData = null;
      state.error = action.payload;
    },
    clearSingleEvent: (state: EventState) => {
      state.loadingGetEventDetail = false;
      state.singleEventData = null;
      state.error = '';
    },
    getSavedEvent: (state: EventState) => {
      state.loadingGetSavedEvent = true;
      state.savedEvent = null;
    },
    getSavedEventSuccess: (state: EventState, action: PayloadAction<IDataSavedEvent>) => {
      state.loadingGetSavedEvent = false;
      state.savedEvent = action.payload;
    },
    getSavedEventFailure: (state: EventState, action: PayloadAction<string | undefined>) => {
      state.loadingGetSavedEvent = false;
      state.data = {
        data: {
          completed: [],
          upcoming: [],
          pending: [],
        },
      };
      state.error = action.payload;
      state.savedEvent = null;
    },
    saveEvent: (state: EventState) => {
      state.loadingGetSavedEvent = true;
      state.savedEvent = null;
      state.saveEventFailure = false;
    },

    saveEventSuccess: (state: EventState, action: PayloadAction<IDataSavedEvent>) => {
      state.loadingGetSavedEvent = false;
      state.savedEvent = action.payload;
      state.saveEventFailure = false;
    },

    saveEventFailure: (state: EventState) => {
      state.loadingGetSavedEvent = false;
      state.savedEvent = null;
      state.saveEventFailure = true;
    },

    createPaymentRegisterEvent: (state) => {
      state.loadingCreatePaymentRegisterEvent = true;
    },
    createPaymentRegisterEventSuccess: (state, action: PayloadAction<IDataPaymentIntentEvent>) => {
      state.loadingCreatePaymentRegisterEvent = false;
      state.paymentIntentEvent = action.payload;
      state.paymentRegisterEventSuccess = true;
    },
    createPaymentRegisterEventFailure: (state, action: PayloadAction<string>) => {
      state.loadingCreatePaymentRegisterEvent = false;
      state.paymentIntentEvent = null;
      state.paymentRegisterEventSuccess = false;
      state.paymentRegisterError = action.payload;
    },
    resetPaymentRegisterEvent: (state) => {
      state.paymentRegisterEventSuccess = false;
    },
    clearRegisterEventError: (state) => {
      state.paymentRegisterError = '';
    },

    cancelEvent: (state) => {
      state.loadingCancelEvent = true;
      state.cancelEventFail = false;
      state.cancelEventSuccess = false;
    },
    cancelEventSuccess: (state) => {
      state.loadingCancelEvent = false;
      state.cancelEventFail = false;
      state.cancelEventSuccess = true;
    },
    cancelEventFailure: (state) => {
      state.loadingCancelEvent = false;
      state.cancelEventFail = true;
      state.cancelEventSuccess = false;
    },
    clearCancelBooking: (state) => {
      state.loadingCancelEvent = false;
      state.cancelEventFail = false;
      state.cancelEventSuccess = false;
    },

    clearSaveEventFailure: (state) => {
      state.saveEventFailure = false;
    },
  },
});

export const {
  getEvent,
  getEventSuccess,
  getEventFailure,
  getSingleEventState,
  getSingleEventSuccess,
  getSingleEventFailure,
  getSavedEvent,
  getSavedEventSuccess,
  getSavedEventFailure,
  saveEvent,
  saveEventSuccess,
  saveEventFailure,
  createPaymentRegisterEvent,
  createPaymentRegisterEventSuccess,
  createPaymentRegisterEventFailure,
  resetPaymentRegisterEvent,
  clearRegisterEventError,
  cancelEvent,
  cancelEventFailure,
  cancelEventSuccess,
  clearCancelBooking,
  clearSaveEventFailure,
  clearSingleEvent,
} = eventSlide.actions;

export default eventSlide.reducer;
