import {
  CompleteLearningResponse,
  EntryResponse,
  GetEntryResource,
  GetResourcesResponse,
  OpenLearningResponse,
  Resource,
  ResourceByArrayContentType,
  ResourceDetailResponse,
  ResourceResponse,
  TrackingLearningResponse,
} from '@/shared/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface LearningState {
  resourceByArrayContentType: ResourceByArrayContentType | null;
  learningData: ResourceResponse | null;
  myLoveContentData: ResourceResponse | null;
  openLearningData: OpenLearningResponse | null;
  completeLearningData: CompleteLearningResponse | null;
  trackingLearningData: TrackingLearningResponse | null;
  loading: boolean;
  loadingCareerStories: boolean;
  loadingProfiles: boolean;
  error?: string;
  journalData: ResourceResponse | null;
  entries: EntryResponse | null;
  resourceDetail: ResourceDetailResponse | null;
  loadingGetResourceDetail: boolean;
  totalByArrayContentType: {
    article: number;
    career_stories: number;
    journal: number;
    podcast: number;
    profiles: number;
    video: number;
  };
}
const initialState: LearningState = {
  learningData: null,
  openLearningData: null,
  completeLearningData: null,
  myLoveContentData: null,
  trackingLearningData: null,
  loading: false,
  loadingCareerStories: false,
  loadingProfiles: false,
  error: undefined,
  journalData: null,
  entries: null,
  resourceDetail: null,
  loadingGetResourceDetail: false,
  resourceByArrayContentType: {
    article: [],
    career_stories: [],
    journal: [],
    podcast: [],
    profiles: [],
    video: [],
  },
  totalByArrayContentType: {
    article: 0,
    career_stories: 0,
    journal: 0,
    podcast: 0,
    profiles: 0,
    video: 0,
  },
};

export const learningSlice = createSlice({
  name: 'LearningState',
  initialState,
  reducers: {
    getLearning: (state: LearningState) => {
      state.loading = true;
    },
    getLearningSuccess: (state: LearningState, action: PayloadAction<ResourceResponse>) => {
      state.loading = false;
      state.learningData = action.payload;
    },
    getLearningFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    openLearning: (state: LearningState) => {
      state.loading = true;
    },
    openLearningSuccess: (state: LearningState, action: PayloadAction<OpenLearningResponse>) => {
      state.loading = false;
      state.openLearningData = action.payload;
    },
    openLearningFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    completeLearning: (state: LearningState) => {
      state.loading = true;
    },
    completeLearningSuccess: (state: LearningState, action: PayloadAction<CompleteLearningResponse>) => {
      state.loading = false;
      state.completeLearningData = action.payload;
    },
    completeLearningFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    trackingLearning: (state: LearningState) => {
      state.loading = true;
    },
    trackingLearningSuccess: (state: LearningState, action: PayloadAction<TrackingLearningResponse>) => {
      state.loading = false;
      state.trackingLearningData = action.payload;
    },
    trackingLearningFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getResource: (state: LearningState) => {
      state.loading = true;
      state.learningData = null;
    },
    getResourceSuccess: (state: LearningState, action: PayloadAction<GetResourcesResponse>) => {
      state.loading = false;
      state.learningData = action.payload;
    },
    getResourceFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    createEntry: (state: LearningState) => {
      state.loading = true;
    },
    createEntrySuccess: (state: LearningState) => {
      state.loading = false;
    },
    createEntryFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getUserEntry: (state: LearningState) => {
      state.loading = true;
    },
    getUserEntrySuccess: (state: LearningState, action: PayloadAction<GetEntryResource>) => {
      state.loading = false;
      state.entries = action.payload;
    },
    getUserEntryFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteEntry: (state: LearningState) => {
      state.loading = true;
    },
    deleteEntrySuccess: (state: LearningState) => {
      state.loading = false;
    },
    deleteEntryFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getResourceDetail: (state: LearningState) => {
      state.loadingGetResourceDetail = true;
      state.resourceDetail = null;
    },
    getResourceDetailSuccess: (state: LearningState, action: PayloadAction<ResourceDetailResponse>) => {
      state.loadingGetResourceDetail = false;
      state.resourceDetail = action.payload;
    },
    getResourceDetailFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loadingGetResourceDetail = false;
      state.error = action.payload;
    },
    clearLearning: (state: LearningState) => {
      state.loading = false;
      state.learningData = null;
    },
    getResourceByArrayContentTypeRequest(state) {
      state.loading = true;
      state.learningData = null;
    },

    // Handle careers stories success
    getResourceCareerStories(state) {
      state.loadingCareerStories = true;
    },

    getResourceCareerStoriesSuccess: (
      state: LearningState,
      action: PayloadAction<{ data: Resource[]; total: number; page: number }>
    ) => {
      const { data, total, page } = action.payload;
      state.loadingCareerStories = false;
      if (!state.resourceByArrayContentType) {
        state.resourceByArrayContentType = {};
      }
      if (page > 1) {
        state.resourceByArrayContentType.career_stories = [
          ...(state.resourceByArrayContentType.career_stories || []),
          ...data,
        ];
      } else {
        state.resourceByArrayContentType.career_stories = data || [];
      }
      state.totalByArrayContentType.career_stories = total;
    },

    // Handle profiles success
    getResourceProfiles(state) {
      state.loadingProfiles = true;
    },
    getResourceProfilesSuccess: (
      state: LearningState,
      action: PayloadAction<{ data: Resource[]; total: number; page: number }>
    ) => {
      const { data, total, page } = action.payload;
      state.loadingProfiles = false;

      if (!state.resourceByArrayContentType) {
        state.resourceByArrayContentType = {};
      }
      if (page > 1) {
        state.resourceByArrayContentType.profiles = [...(state.resourceByArrayContentType.profiles || []), ...data];
      } else {
        state.resourceByArrayContentType.profiles = data || [];
      }
      state.totalByArrayContentType.profiles = total;
    },
    getMyLoveContent: (state: LearningState) => {
      state.loading = true;
      state.myLoveContentData = null;
    },
    getMyLoveContentSuccess: (state: LearningState, action: PayloadAction<GetResourcesResponse>) => {
      state.loading = false;
      state.myLoveContentData = action.payload;
    },
    getMyLoveContentFailure: (state: LearningState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getLearning,
  getLearningSuccess,
  getLearningFailure,
  openLearning,
  openLearningSuccess,
  openLearningFailure,
  completeLearning,
  completeLearningSuccess,
  completeLearningFailure,
  trackingLearning,
  trackingLearningSuccess,
  trackingLearningFailure,
  getResource,
  getResourceSuccess,
  getResourceFailure,
  createEntry,
  createEntrySuccess,
  createEntryFailure,
  getUserEntry,
  getUserEntrySuccess,
  getUserEntryFailure,
  deleteEntry,
  deleteEntrySuccess,
  deleteEntryFailure,
  getResourceDetail,
  getResourceDetailSuccess,
  getResourceDetailFailure,
  clearLearning,
  getResourceCareerStories,
  getResourceCareerStoriesSuccess,
  getResourceProfiles,
  getResourceProfilesSuccess,
  getMyLoveContentSuccess,
  getMyLoveContentFailure,
} = learningSlice.actions;

export default learningSlice.reducer;
