import React, { CSSProperties } from 'react';
import './bannerFooter.scss';

type Props = {
  titleLeft: string;
  descriptionLeft: string;
  titleRight: string;
  descriptionRight: string;
  buttonsLeft: React.ReactNode;
  buttonsRight: React.ReactNode;
  bannerLeft: string;
  bannerRight: string;
};

const BannerFooter = React.memo(
  ({
    titleLeft,
    titleRight,
    buttonsLeft,
    buttonsRight,
    descriptionLeft,
    descriptionRight,
    bannerLeft,
    bannerRight,
  }: Props) => {
    return (
      <div className="banner-footer">
        <div
          className="banner-footer__group"
          style={{ background: `url(${bannerLeft}) center / cover no-repeat` } as CSSProperties}
        >
          <div className="banner-footer__group--content">
            <div className="banner-footer__group--title">{titleLeft}</div>
            <div className="banner-footer__group--text">{descriptionLeft}</div>
          </div>
          <div className="banner-footer__group--buttons">{buttonsLeft}</div>
        </div>
        <div
          className="banner-footer__group"
          style={{ background: `url(${bannerRight}) center / cover no-repeat` } as CSSProperties}
        >
          <div className="banner-footer__group--content">
            <div className="banner-footer__group--title">{titleRight}</div>
            <div className="banner-footer__group--text">{descriptionRight}</div>
          </div>
          <div className="banner-footer__group--buttons">{buttonsRight}</div>
        </div>
      </div>
    );
  }
);
BannerFooter.displayName = 'BannerFooter';
export default BannerFooter;
