import { TakeSurveyData } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SurveyState {
  data: {
    take: TakeSurveyData[];
    vak: TakeSurveyData[];
    human: TakeSurveyData[];
    brain: TakeSurveyData[];
  };
  loading: boolean;
  error?: string;
}

const initialState: SurveyState = {
  data: {
    take: [],
    vak: [],
    human: [],
    brain: [],
  },
  loading: false,
  error: undefined,
};

export const surveySlice = createSlice({
  name: 'surveyState',
  initialState,
  reducers: {
    requestSaveTakeSurvey: (state: SurveyState, action: PayloadAction<TakeSurveyData>) => {
      const existingIndex = state.data.take?.findIndex((item) => item.step === action.payload.step);
      let newData = state.data.take;
      if (existingIndex > -1) {
        newData[existingIndex] = action.payload;
      } else {
        newData = [...state.data.take, action.payload];
      }
      state.data = {
        ...state.data,
        take: newData,
      };
    },
    requestClearTakeSurvey: (state: SurveyState) => ({
      data: { ...state.data, take: [] },
      loading: false,
      error: undefined,
    }),
    requestVAK_LearningSurvey: (state: SurveyState, action: PayloadAction<TakeSurveyData>) => {
      const existingIndex = state.data.vak?.findIndex((item) => item.step === action.payload.step);
      let newData = state.data.vak;
      if (existingIndex > -1) {
        newData[existingIndex] = action.payload;
      } else {
        newData = [...state.data.vak, action.payload];
      }
      state.data = {
        ...state.data,
        vak: newData,
      };
    },
    requestCleanVAK_LearningSurvey: (state: SurveyState) => ({
      data: { ...state.data, vak: [] },
      loading: false,
      error: undefined,
    }),
    requestHumanSurvey: (state: SurveyState, action: PayloadAction<TakeSurveyData>) => {
      // const existingIndex = state.data.human?.findIndex((item) => item.step === action.payload.step);
      let newData = state.data.human;
      newData = [...state.data.human, action.payload];

      state.data = {
        ...state.data,
        human: newData,
      };
    },
    requestCleanHumanSurvey: (state: SurveyState) => ({
      data: { ...state.data, human: [] },
      loading: false,
      error: undefined,
    }),
    requestBrainSurvey: (state: SurveyState, action: PayloadAction<TakeSurveyData>) => {
      // const existingIndex = state.data.human?.findIndex((item) => item.step === action.payload.step);
      let newData = state.data.brain;
      newData = [...state.data.brain, action.payload];

      state.data = {
        ...state.data,
        brain: newData,
      };
    },
    requestCleanBrainSurvey: (state: SurveyState) => ({
      data: { ...state.data, brain: [] },
      loading: false,
      error: undefined,
    }),
  },
});

export const {
  requestSaveTakeSurvey,
  requestClearTakeSurvey,
  requestVAK_LearningSurvey,
  requestCleanVAK_LearningSurvey,
  requestHumanSurvey,
  requestCleanHumanSurvey,
  requestBrainSurvey,
  requestCleanBrainSurvey,
} = surveySlice.actions;

export default surveySlice.reducer;
