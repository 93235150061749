import i18next from 'i18next';
import en from './en';

const resources = { en };

const initAppTranslation = () =>
  i18next.init({
    interpolation: { escapeValue: false },
    lng: 'en',
    resources,
  });

const t = (key: string) => i18next.t(key);
export { initAppTranslation, t };
