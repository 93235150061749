import { ForgotPasswordApi } from '@/api/forgotPassword';
import { IForgotPassword, IForgotPasswordResponse, IResetPassword } from '@/shared/interface';
import { showErrorMessage, showSuccessMessage } from '@/utils/showMessage';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
  forgotPassword,
  forgotPasswordFailure,
  forgotPasswordSuccess,
  resendForgotPassword,
  resendForgotPasswordFailure,
  resendForgotPasswordSuccess,
  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,
  setForgotPasswordEmail,
} from './slice';

function* forgotPasswordRequest(action: PayloadAction<IForgotPassword>) {
  try {
    yield put(forgotPassword());
    const res: IForgotPasswordResponse = yield call(ForgotPasswordApi.forgotPassword, action.payload);
    if (res.success) {
      yield put(forgotPasswordSuccess());
      yield put(setForgotPasswordEmail(action.payload.email));
    } else {
      yield put(forgotPasswordFailure(res.message));
      showErrorMessage(res.message || '');
    }
  } catch (error) {
    const err = error as Error;
    yield put(forgotPasswordFailure(err.message));
    showErrorMessage(err.message);
  }
}

function* resetPasswordRequest(action: PayloadAction<IResetPassword>) {
  try {
    yield put(resetPassword());
    const res: IForgotPasswordResponse = yield call(ForgotPasswordApi.resetPassword, action.payload);
    if (res.success) {
      yield put(resetPasswordSuccess());
    } else {
      yield put(resetPasswordFailure(res.message));
      showErrorMessage(res.message || '');
    }
  } catch (error) {
    const err = error as Error;
    yield put(resetPasswordFailure(error?.toString()));
    showErrorMessage(err.message);
  }
}

function* resendForgotPasswordRequest(data: PayloadAction<IForgotPassword>) {
  try {
    yield put(resendForgotPassword());
    const res: IForgotPasswordResponse = yield call(ForgotPasswordApi.forgotPassword, data.payload);
    if (res.success) {
      yield put(resendForgotPasswordSuccess());
      showSuccessMessage(res.message || '');
    } else {
      yield put(resendForgotPasswordFailure(res.message));
    }
  } catch (error) {
    yield put(resendForgotPasswordFailure(error?.toString()));
  }
}

export function* watchForgotPassword() {
  yield takeLatest(actionTypes.FORGOT_PASSWORD, forgotPasswordRequest);
  yield takeLatest(actionTypes.RESET_PASSWORD, resetPasswordRequest);
  yield takeLatest(actionTypes.RESEND_FORGOT_PASSWORD, resendForgotPasswordRequest);
}
