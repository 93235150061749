import { LearningApi } from '@/api/learing';
import {
  BaseResponse,
  CompleteLearningResponse,
  CreateEntryRequest,
  DeleteEntryRequest,
  GetEntryResource,
  GetResourceDetailRequest,
  GetResourcesResponse,
  LearningRequest,
  OpenLearningResponse,
  ResourceDetailResponse,
  ResourceResponse,
  TrackingLearningRequest,
  TrackingLearningResponse,
} from '@/shared/interface';
import { showSuccessMessage } from '@/utils/showMessage';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
  completeLearning,
  completeLearningFailure,
  completeLearningSuccess,
  createEntry,
  createEntryFailure,
  createEntrySuccess,
  deleteEntry,
  deleteEntryFailure,
  deleteEntrySuccess,
  getLearning,
  getLearningFailure,
  getLearningSuccess,
  getResource,
  getResourceFailure,
  getResourceSuccess,
  getUserEntry,
  getUserEntryFailure,
  getUserEntrySuccess,
  openLearning,
  openLearningFailure,
  openLearningSuccess,
  trackingLearning,
  trackingLearningFailure,
  trackingLearningSuccess,
  getResourceDetail,
  getResourceDetailFailure,
  getResourceDetailSuccess,
  getResourceCareerStoriesSuccess,
  getResourceProfilesSuccess,
  getResourceCareerStories,
  getResourceProfiles,
  getMyLoveContentSuccess,
  getMyLoveContentFailure,
} from './slice';

function* getLearningRequest() {
  try {
    yield put(getLearning());
    const res: ResourceResponse = yield call(LearningApi.getLearning);
    if (res.success) {
      yield put(getLearningSuccess(res));
    } else {
      yield put(getLearningFailure(res.message));
    }
  } catch (error) {
    yield put(getLearningFailure(error?.toString()));
  }
}

function* openLearningRequest(action: PayloadAction<LearningRequest>) {
  try {
    yield put(openLearning());
    const res: OpenLearningResponse = yield call(LearningApi.openLearning, action.payload);
    if (res.success) {
      yield put(openLearningSuccess(res));
    } else {
      yield put(openLearningFailure(res.message));
    }
  } catch (error) {
    yield put(openLearningFailure(error?.toString()));
  }
}

function* completeLearningRequest(action: PayloadAction<LearningRequest>) {
  try {
    yield put(completeLearning());
    const res: CompleteLearningResponse = yield call(LearningApi.completeLearning, action.payload);
    if (res.success) {
      yield put(completeLearningSuccess(res));
    } else {
      yield put(completeLearningFailure(res.message));
    }
  } catch (error) {
    yield put(completeLearningFailure(error?.toString()));
  }
}

function* trackingLearningRequest(action: PayloadAction<TrackingLearningRequest>) {
  try {
    yield put(trackingLearning());
    const res: TrackingLearningResponse = yield call(LearningApi.trackLearning, action.payload);
    if (res.success) {
      yield put(trackingLearningSuccess(res));
    } else {
      yield put(trackingLearningFailure(res.message));
    }
  } catch (error) {
    yield put(trackingLearningFailure(error?.toString()));
  }
}

function* getResourceRequest(action: PayloadAction<Record<string, string>>) {
  try {
    yield put(getResource());
    const res: GetResourcesResponse = yield call(LearningApi.getResources, action.payload);
    if (res.success) {
      yield put(getResourceSuccess(res));
    } else {
      yield put(getResourceFailure(res.message));
    }
  } catch (error) {
    yield put(getResourceFailure(error?.toString()));
  }
}

function* createEntryRequest(action: PayloadAction<CreateEntryRequest>) {
  try {
    yield put(createEntry());
    const res: BaseResponse = yield call(LearningApi.createEntry, action.payload);
    if (res.success) {
      yield put(createEntrySuccess());
    } else {
      yield put(createEntryFailure(res.message));
    }
  } catch (error) {
    yield put(createEntryFailure(error?.toString()));
  }
}

function* getUserEntryRequest() {
  try {
    yield put(getUserEntry());
    const res: GetEntryResource = yield call(LearningApi.getUserEntry);
    if (res.success) {
      yield put(getUserEntrySuccess(res));
    } else {
      yield put(getUserEntryFailure(res.message));
    }
  } catch (error) {
    yield put(getUserEntryFailure(error?.toString()));
  }
}

function* deleteEntryRequest(action: PayloadAction<DeleteEntryRequest>) {
  try {
    yield put(deleteEntry());
    const res: BaseResponse = yield call(LearningApi.deleteEntry, action.payload);
    if (res.success) {
      yield put(deleteEntrySuccess());
      yield getUserEntryRequest();
      showSuccessMessage(res.message || 'Entry deleted successfully');
    } else {
      yield put(deleteEntryFailure(res.message));
    }
  } catch (error) {
    yield put(deleteEntryFailure(error?.toString()));
  }
}

function* getResourceDetailRequest(action: PayloadAction<GetResourceDetailRequest>) {
  try {
    yield put(getResourceDetail());
    const res: ResourceDetailResponse = yield call(LearningApi.getResourceDetail, action.payload);
    if (res.success) {
      yield put(getResourceDetailSuccess(res));
    } else {
      yield put(getResourceDetailFailure(res.message));
    }
  } catch (error) {
    yield put(getResourceDetailFailure(error?.toString()));
  }
}

function* fetchResourcesContentTypeCareerStories(action: PayloadAction<Record<string, string>>) {
  try {
    yield put(getResourceCareerStories());
    const res: GetResourcesResponse = yield call(LearningApi.getResources, action.payload);
    if (res.success) {
      const { page } = action.payload;
      const totalItems = res?.data?.total || 0;
      yield put(
        getResourceCareerStoriesSuccess({
          data: res?.data?.resources || [],
          total: totalItems,
          page: Number(page),
        })
      );
    } else {
      yield put(getResourceFailure(res.message));
    }
  } catch (error) {
    yield put(getResourceFailure(error?.toString()));
  }
}

function* fetchResourcesContentTypeProfiles(action: PayloadAction<Record<string, string>>) {
  try {
    yield put(getResourceProfiles());
    const res: GetResourcesResponse = yield call(LearningApi.getResources, action.payload);
    if (res.success) {
      const { page } = action.payload;
      const totalItems = res?.data?.total || 0;
      yield put(
        getResourceProfilesSuccess({
          data: res?.data?.resources || [],
          total: totalItems,
          page: Number(page),
        })
      );
    } else {
      yield put(getResourceFailure(res.message));
    }
  } catch (error) {
    yield put(getResourceFailure(error?.toString()));
  }
}

function* getMyLoveContentRequest(action: PayloadAction<Record<string, string>>) {
  try {
    yield put(getResource());
    const res: GetResourcesResponse = yield call(LearningApi.getResources, action.payload);
    if (res.success) {
      yield put(getMyLoveContentSuccess(res));
    } else {
      yield put(getMyLoveContentFailure(res.message));
    }
  } catch (error) {
    yield put(getMyLoveContentFailure(error?.toString()));
  }
}

export function* watchLearning() {
  yield takeLatest(actionTypes.GET_LEARNING, getLearningRequest);
  yield takeLatest(actionTypes.OPEN_LEARNING, openLearningRequest);
  yield takeLatest(actionTypes.COMPLETE_LEARNING, completeLearningRequest);
  yield takeLatest(actionTypes.TRACKING_LEARNING, trackingLearningRequest);
  yield takeLatest(actionTypes.GET_RESOURCE, getResourceRequest);
  yield takeLatest(actionTypes.CREATE_ENTRY, createEntryRequest);
  yield takeLatest(actionTypes.GET_USER_ENTRY, getUserEntryRequest);
  yield takeLatest(actionTypes.DELETE_ENTRY, deleteEntryRequest);
  yield takeLatest(actionTypes.GET_RESOURCE_DETAIL, getResourceDetailRequest);
  yield takeLatest(actionTypes.FETCH_RESOURCE_CONTENT_TYPE_CAREER_STORIES, fetchResourcesContentTypeCareerStories);
  yield takeLatest(actionTypes.FETCH_RESOURCE_CONTENT_TYPE_PROFILES, fetchResourcesContentTypeProfiles);
  yield takeLatest(actionTypes.GET_MY_LOVE_CONTENT_REQUEST, getMyLoveContentRequest);
}
