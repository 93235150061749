import { Location, LoginRequest, SessionData, TimeZone } from '@/shared/interface';
import * as actionTypes from './actionTypes';

export const loginAction = (payload: LoginRequest) => {
  return {
    type: actionTypes.LOGIN,
    payload,
  };
};

export const signOutAction = () => {
  return {
    type: actionTypes.SIGN_OUT,
  };
};

export const updateTokenAction = (payload: SessionData) => {
  return {
    type: actionTypes.UPDATE_TOKEN,
    payload,
  };
};

export const cleanErrorAction = () => {
  return {
    type: actionTypes.CLEAN_ERROR,
  };
};

export const getIPInfoAction = (payload: TimeZone) => {
  return {
    type: actionTypes.GET_IP_INFO,
    payload,
  };
};

export const getLocationAction = (payload: Location) => {
  return {
    type: actionTypes.LOCATION,
    payload,
  };
};
