import { combineReducers } from 'redux';
import availability from './availability/slice';
import bookingState from './booking/slice';
import categoriesState from './categories/slice';
import eventState from './event/slice';
import learningState from './learning/slice';
import learningStylesSlice from './learningStyles/slice';
import learningPaceSlice from './learningPace/slice';
import mentor from './mentor/slice';
import messageSlice from './messages/slice';
import onBoarding from './onboarding/slice';
import powerClassState from './powerClass/slice';
import profileSessionState from './profile/slice';
import resourcesState from './resources/slice';
import sessionState from './session/slice';
import signUp from './signUp/slice';
import surveyState from './survey/slice';
import playlistsSlice from './playlists/slice';
import paymentSlice from './payment/slice';
import commentState from './comment/slice';
import webStyleState from './webStyles/slice';
import adminResourceReducer from './adminResources/slice';
import commentResourceReducer from './commentResource/slice';
import zoomJoinState from './zoom/slice';
import forgotPasswordReducer from './forgotPassword/slice';
import notificationReducer from './notification/slice';

const reducers = combineReducers({
  sessionState,
  profileSessionState,
  eventState,
  resourcesState,
  surveyState,
  learningState,
  powerClassState,
  categoriesState,
  mentor,
  availability,
  signUp,
  onBoarding,
  bookingState,
  messageSlice,
  learningStylesSlice,
  learningPaceSlice,
  playlistsSlice,
  paymentSlice,
  commentState,
  webStyleState,
  adminResourceReducer,
  commentResourceReducer,
  zoomJoinState,
  forgotPasswordReducer,
  notificationReducer,
});

export default reducers;
