import {
  CreateEntryRequest,
  DeleteEntryRequest,
  GetResourceDetailRequest,
  GetResourcesResponse,
  LearningRequest,
  LearningResponse,
  OpenLearningResponse,
  TrackingLearningRequest,
  TrackingLearningResponse,
} from '@/shared/interface';
import { ApiCall, endpoints } from './api';

export const LearningApi = {
  getLearning: async (): Promise<LearningResponse> => await ApiCall.get(endpoints.learning),
  openLearning: async (data: LearningRequest): Promise<OpenLearningResponse> =>
    (await ApiCall.put())(`${endpoints.learning}/open`, data),
  completeLearning: async (data: LearningRequest): Promise<OpenLearningResponse> =>
    (await ApiCall.put())(`${endpoints.learning}/complete`, data),
  trackLearning: async (data: TrackingLearningRequest): Promise<TrackingLearningResponse> =>
    (await ApiCall.put())(`${endpoints.learning}/track`, data),
  getResources: async (query: Record<string, string | string>): Promise<GetResourcesResponse> => {
    const queryString = new URLSearchParams(query).toString();
    return await ApiCall.get(`${endpoints.resources}?${queryString}`, true);
  },
  createEntry: async (data: CreateEntryRequest) => {
    return await ApiCall.post(`${endpoints.entry}`, data, true);
  },
  getUserEntry: async () => {
    return await ApiCall.get(`${endpoints.entry}`, true);
  },
  deleteEntry: async (data: DeleteEntryRequest) => {
    return await ApiCall.delete(`${endpoints.entry}/${data.id}`, true);
  },
  getResourceDetail: async ({ id }: GetResourceDetailRequest) => {
    return await ApiCall.get(`${endpoints.resources}/${id}`, true);
  },
};
