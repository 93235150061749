import { learningStylesApi } from '@/api/learningStyles';
import { LearningStylesResponse } from '@/shared/interface';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { getLearningStyles, getLearningStylesFailure, getLearningStylesSuccess } from './slice';
import { RootState } from '../store';

function* getLearningStylesWorker() {
  try {
    const { isFetched } = yield select((state: RootState) => state.learningStylesSlice);

    if (isFetched) {
      const res: LearningStylesResponse = yield call(learningStylesApi.getAllLearningStyles);
      if (res.success) {
        yield put(getLearningStylesSuccess(res.data));
      } else {
        yield put(getLearningStylesFailure());
      }
      return;
    }
    yield put(getLearningStyles());
    const res: LearningStylesResponse = yield call(learningStylesApi.getAllLearningStyles);
    if (res.success) {
      yield put(getLearningStylesSuccess(res.data));
    } else {
      yield put(getLearningStylesFailure());
    }
  } catch (error) {
    yield put(getLearningStylesFailure());
  }
}

export function* watchLearningStyles() {
  yield takeLatest(actionTypes.GET_LEARNING_STYLES, getLearningStylesWorker);
}
