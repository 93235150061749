import { ProfileImageUpload, ProfileResponse } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IAvatarProfilePreview {
  file: File | null;
  preview: string;
  defaultAvatar: number | null;
}

interface OnBoardingState {
  dataOnBoarding: ProfileResponse | null;
  dataUploadImage: ProfileImageUpload | null;
  loading: boolean;
  error?: string;
  isSuccess: boolean;
  loadingUpload: boolean;
  errorUpload?: string;
  isSuccessUpload: boolean;
  avatarProfilePreview: IAvatarProfilePreview;
  showMenuUser: boolean;
  showMenuHeader: boolean;
  isTour: boolean;
}

const initialState: OnBoardingState = {
  dataOnBoarding: null,
  dataUploadImage: null,
  loading: false,
  error: undefined,
  isSuccess: false,
  loadingUpload: false,
  errorUpload: undefined,
  isSuccessUpload: false,
  avatarProfilePreview: {
    file: null,
    preview: '',
    defaultAvatar: null,
  },
  showMenuUser: false,
  isTour: false,
  showMenuHeader: false,
};

export const onBoardingSlice = createSlice({
  name: 'OnBoardingState',
  initialState,
  reducers: {
    updateOnBoarding: (state: OnBoardingState) => {
      state.loading = true;
    },
    updateOnBoardingSuccess: (state: OnBoardingState, action: PayloadAction<ProfileResponse>) => {
      state.loading = false;
      state.isSuccess = true;
      state.dataOnBoarding = action.payload;
    },
    updateOnBoardingFailure: (state: OnBoardingState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
    uploadImage: (state: OnBoardingState) => {
      state.loadingUpload = true;
    },
    uploadImageSuccess: (state: OnBoardingState, action: PayloadAction<ProfileImageUpload>) => {
      state.loadingUpload = false;
      state.isSuccessUpload = true;
      state.dataUploadImage = action.payload;
    },
    uploadImageFailure: (state: OnBoardingState, action: PayloadAction<string | undefined>) => {
      state.loadingUpload = false;
      state.isSuccessUpload = false;
      state.error = action.payload;
    },
    cleanUpdateImage: (state: OnBoardingState) => {
      state.dataUploadImage = null;
      state.isSuccessUpload = false;
    },
    cleanOnBoarding: () => {
      return initialState;
    },
    updateAvatarProfile: (state, action: PayloadAction<IAvatarProfilePreview>) => {
      state.avatarProfilePreview = action.payload;
    },
    clearAvatarProfile: (state) => {
      state.avatarProfilePreview = {
        defaultAvatar: null,
        file: null,
        preview: '',
      };
    },
    showMenuUser: (state: OnBoardingState, action: PayloadAction<boolean>) => {
      state.showMenuUser = action.payload;
    },
    showMenuUserHeader: (state: OnBoardingState, action: PayloadAction<boolean>) => {
      state.showMenuHeader = action.payload;
    },
    isTour: (state: OnBoardingState, action: PayloadAction<boolean>) => {
      state.isTour = action.payload;
    },
    clearUpdateSuccess: (state) => {
      state.isSuccess = false;
    },
    clearErrorOnBoarding: (state) => {
      state.error = undefined;
    },
  },
});

export const {
  updateOnBoarding,
  updateOnBoardingSuccess,
  updateOnBoardingFailure,
  uploadImage,
  uploadImageSuccess,
  uploadImageFailure,
  cleanOnBoarding,
  cleanUpdateImage,
  updateAvatarProfile,
  clearAvatarProfile,
  showMenuUser,
  showMenuUserHeader,
  isTour,
  clearUpdateSuccess,
  clearErrorOnBoarding,
} = onBoardingSlice.actions;

export default onBoardingSlice.reducer;
