import { playlistsApi } from '@/api/playlists';
import { ProfileResponse } from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import { IPayloadSaveToPlaylist, IPayloadUnSaveToPlaylist, IPlaylistResponse } from '../interfaces';
import { getProfileAction } from '../profile/action';
import { getProfileSuccess } from '../profile/slice';
import * as actionTypes from './actionTypes';
import {
  getAllSavedPlaylists,
  getAllSavedPlaylistsFailure,
  getAllSavedPlaylistsSuccess,
  saveToPlaylist,
  saveToPlaylistFailure,
  saveToPlaylistSuccess,
  unSaveToPlaylist,
  unSaveToPlaylistFailure,
  unSaveToPlaylistSuccess,
} from './slice';

function* getAllSavedPlaylistsWorker() {
  try {
    yield put(getAllSavedPlaylists());
    const res: IPlaylistResponse = yield call(playlistsApi.getAllSavedPlaylists);
    if (res.success) {
      yield put(getAllSavedPlaylistsSuccess(res.data));
    } else {
      yield put(getAllSavedPlaylistsFailure());
    }
  } catch (error) {
    yield put(getAllSavedPlaylistsFailure());
  }
}

function* saveToPlaylistWorker(action: PayloadAction<IPayloadSaveToPlaylist>) {
  try {
    yield put(saveToPlaylist());
    const res: ProfileResponse = yield call(playlistsApi.saveToPlaylist, action.payload);
    if (res.success) {
      yield put(getProfileAction());
      yield take(getProfileSuccess.type);
      yield put(saveToPlaylistSuccess());
    } else {
      yield put(saveToPlaylistFailure(`Save to playlist failed`));
    }
  } catch (error) {
    const err = error as AxiosError;
    yield put(saveToPlaylistFailure(err?.message));
  }
}

function* unSaveToPlaylistWorker(action: PayloadAction<IPayloadUnSaveToPlaylist>) {
  try {
    yield put(unSaveToPlaylist());
    const res: ProfileResponse = yield call(playlistsApi.unSaveToPlaylist, action.payload);
    const { type, id } = action.payload;
    if (res.success) {
      yield put(getProfileAction());
      yield take(getProfileSuccess.type);
      yield put(unSaveToPlaylistSuccess({ type: type, id }));
    } else {
      yield put(unSaveToPlaylistFailure(`UnSave from playlist failed`));
    }
  } catch (error) {
    const err = error as AxiosError;
    yield put(unSaveToPlaylistFailure(err.message));
  }
}

export function* watchPlaylists() {
  yield takeLatest(actionTypes.GET_ALL_SAVED_PLAYLISTS, getAllSavedPlaylistsWorker);
  yield takeLatest(actionTypes.SAVE_TO_PLAYLIST, saveToPlaylistWorker);
  yield takeLatest(actionTypes.UN_SAVE_TO_PLAYLIST, unSaveToPlaylistWorker);
}
