import { CommentsResponse } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface CommentState {
  data: CommentsResponse | null;
  loading: boolean;
  error?: string;
}

const initialState: CommentState = {
  data: null,
  loading: false,
  error: undefined,
};

export const commentResourceSlice = createSlice({
  name: 'comment-resource',
  initialState,
  reducers: {
    getCommentsList: (state: CommentState) => {
      state.loading = true;
    },
    getCommentsFailure: (state: CommentState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getCommentsSuccess: (state: CommentState, action: PayloadAction<CommentsResponse>) => {
      if (state) {
        state.loading = false;
        state.data = action.payload;
      }
    },
  },
});

export const { getCommentsList, getCommentsFailure, getCommentsSuccess } = commentResourceSlice.actions;

export default commentResourceSlice.reducer;
