import { AuthApi } from '@/api/auth';
import { SignUpRequest, SignUpResponse } from '@/shared/interface';
import { StorageKeys } from '@/utils/storage';
import { PayloadAction } from '@reduxjs/toolkit';
import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { cleanSignUp, updateSignUpData, updateSignUpFailure, updateSignUpSuccess } from './slice';

const expiresInMS = 3600;
interface ErrorMess {
  message: string;
  success: boolean;
}

function* signUpRequest(action: PayloadAction<SignUpRequest>) {
  try {
    yield put(updateSignUpData());
    const res: SignUpResponse = yield call(AuthApi.signUp, action.payload);
    if (res.success) {
      yield put(updateSignUpSuccess({ email: action.payload.email }));
      yield localStorage.setItem(StorageKeys.session, JSON.stringify(res.data));
      const expiresIn = moment().add(expiresInMS, 'seconds').valueOf();
      yield localStorage.setItem(StorageKeys.timeExpires, expiresIn.toString());
      yield localStorage.setItem(StorageKeys.refreshToken, res.data?.AuthenticationResult?.RefreshToken);
    } else {
      yield put(updateSignUpFailure(res.message ?? ''));
    }
  } catch (error) {
    if (error && typeof error === 'object' && 'message' in error && 'success' in error) {
      const typedError = error as ErrorMess;
      yield put(updateSignUpFailure(typedError.message));
    } else {
      yield put(updateSignUpFailure(error?.toString()));
    }
  }
}

function* cleanSignUpRequest() {
  yield put(cleanSignUp());
}

export function* watchSignUp() {
  yield takeLatest(actionTypes.SIGN_UP, signUpRequest);
  yield takeLatest(actionTypes.CLEAN_SIGN_UP, cleanSignUpRequest);
}
