import {
  MentorRequest,
  MentorsResponse,
  SingleMentorResponse,
  AvailabilityResponse,
  MentorListRequest,
} from '@/shared/interface';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import {
  getMentors,
  getMentorsSuccess,
  getMentorsFailure,
  getSingleMentorState,
  getSingleMentorSuccess,
  getSingleMentorFailure,
  getAvailabilityMentorStart,
  getAvailabilityMentorSuccess,
  getAvailabilityMentorFailure,
} from './slice';
import * as actionTypes from './actionTypes';
import { MentorApi } from '@/api/mentor';
import { PayloadAction } from '@reduxjs/toolkit';
import { AvailabilityApi } from '@/api/availability';
import { RootState } from '../store';

function* getMentor(action: PayloadAction<MentorListRequest>) {
  const payload = action?.payload;
  try {
    const { isFetchListMentorData } = yield select((state: RootState) => state.mentor);
    const shouldRefresh: boolean = payload ? Boolean(payload.forceRefresh) : isFetchListMentorData;
    if (shouldRefresh) {
      const queryParams = payload?.filterString;
      yield put(getMentors());
      const res: MentorsResponse = yield call(MentorApi.getListMentor, queryParams);
      if (res.success) {
        yield put(getMentorsSuccess(res));
      } else {
        yield put(getMentorsFailure(res.message));
      }
    } else {
      yield put(getMentors());
      const res: MentorsResponse = yield call(MentorApi.getListMentor);
      if (res.success) {
        yield put(getMentorsSuccess(res));
      } else {
        yield put(getMentorsFailure(res.message));
      }
    }
  } catch (error) {
    yield put(getMentorsFailure(error?.toString()));
  }
}

function* getSingleMentor(action: PayloadAction<MentorRequest>) {
  try {
    yield put(getSingleMentorState());
    const res: SingleMentorResponse = yield call(MentorApi.getSingleMentor, action.payload);
    if (res.success) {
      yield put(getSingleMentorSuccess(res.data));
    } else {
      yield put(getSingleMentorFailure(res.message));
    }
  } catch (error) {
    yield put(getSingleMentorFailure(error?.toString()));
  }
}

function* getAvailabilityMentor(action: PayloadAction<MentorRequest>) {
  try {
    yield put(getAvailabilityMentorStart());
    const res: AvailabilityResponse = yield call(AvailabilityApi.getAvailability, action.payload);
    if (res.success && res.data) {
      yield put(getAvailabilityMentorSuccess(res.data));
    } else {
      yield put(getAvailabilityMentorFailure(res.message));
    }
  } catch (error) {
    yield put(getAvailabilityMentorFailure(error?.toString()));
  }
}

export function* watchMentors() {
  yield takeLatest(actionTypes.GET_MENTOR, getMentor);
  yield takeLatest(actionTypes.GET_SINGLE_MENTOR, getSingleMentor);
  yield takeLatest(actionTypes.GET_AVAILABILITY_MENTOR, getAvailabilityMentor);
}
