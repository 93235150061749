import { CommentApi } from '@/api/comment';
import { CommentResourceApi } from '@/api/commentResource';
import { HeartFilledIcon, HeartIcon } from '@/assets/redesign/images';
import DefaultLogo from '@/assets/redesign/images/icons/account/person.svg';
import { ETypeComment } from '@/component/CommentCustomList';
import Image from '@/component/Image';
import { useAppDispatch, useAppSelector } from '@/redux/hook';
import { RootState } from '@/redux/store';
import { CommentData, EventData } from '@/shared/interface';
import React, { useCallback, useEffect, useMemo, useState, forwardRef } from 'react';
import './subCommentItem.scss';
import { getProfileAction } from '@/redux/profile/action';
import Avatar from '@/component/Avatar';

type Props = {
  comment: CommentData;
  event?: EventData;
  type: ETypeComment;
  resource_id?: string;
};

const SubCommentItem = forwardRef<HTMLDivElement, Props>(({ comment, type, event, resource_id }, ref) => {
  const dispatch = useAppDispatch();
  const { profileData } = useAppSelector((state: RootState) => state.profileSessionState);

  const [loadingLikeComment, setLoadingLikeComment] = useState<boolean>(false);
  const [commentLikeActive, setCommentLikeActive] = useState<string>('');
  const [isLikedComment, setIsLikedComment] = useState<boolean>(false);
  const [totalLike, setTotalLike] = useState<number>(0);

  const likeIconSrc = useMemo(() => {
    return isLikedComment ? HeartFilledIcon : HeartIcon;
  }, [isLikedComment]);

  const likeIconClass = `sub-comment-item__like  ${
    loadingLikeComment && commentLikeActive === comment._id ? 'sub-comment-item__like--loading' : ''
  }`;

  useEffect(() => {
    if (!profileData || !comment.likes) return;
    const like = profileData.liked_comments?.find((item) => item === comment._id);
    setIsLikedComment(!!like);
  }, [profileData, comment]);

  useEffect(() => {
    if (comment.likes) {
      setTotalLike(comment.likes.length);
    }
  }, [comment]);

  const handleLikeAComment = useCallback(
    async (comment_id: string) => {
      try {
        if (!comment) return;
        setLoadingLikeComment(true);
        setCommentLikeActive(comment_id);
        setTotalLike((prev) => prev + 1);
        setIsLikedComment(true);

        if (event?.id && type === ETypeComment.Event) {
          await CommentApi.likeAComment({ comment_id });
        }

        if (resource_id) {
          await CommentResourceApi.likeAComment(comment_id);
        }

        dispatch(getProfileAction());
        setLoadingLikeComment(false);
      } catch (error) {
        setLoadingLikeComment(false);
      }
    },
    [comment, dispatch, event, resource_id, type]
  );

  const handleUnLikeAComment = useCallback(
    async (comment_id: string) => {
      try {
        if (!comment) return;
        setLoadingLikeComment(true);
        setCommentLikeActive(comment_id);
        setTotalLike((prev) => prev - 1);
        setIsLikedComment(false);

        if (event?.id && type === ETypeComment.Event) {
          await CommentApi.unlikeAComment({ comment_id });
        }

        if (resource_id) {
          await CommentResourceApi.unlikeAComment(comment_id);
        }
        dispatch(getProfileAction());
        setLoadingLikeComment(false);
      } catch (error) {
        setLoadingLikeComment(false);
      }
    },
    [comment, dispatch, event, resource_id, type]
  );

  return (
    <div className="sub-comment-item" ref={ref}>
      <Avatar
        src={comment?.user?.user_profile ? comment.user?.user_profile : DefaultLogo}
        alt={comment?.name}
        first_name={comment?.user.first_name}
        className="sub-comment-item__avatar"
      />
      <div className="sub-comment-item__info">
        <h2 className="sub-comment-item__name">{comment?.name}</h2>

        <p className="sub-comment-item__content">{comment.content}</p>
        <div className="sub-comment-item__like--wrapper">
          {totalLike}
          <Image
            src={likeIconSrc}
            className={likeIconClass}
            onClick={() => {
              if (loadingLikeComment) return;
              isLikedComment ? handleUnLikeAComment(comment._id) : handleLikeAComment(comment._id);
            }}
          />
        </div>
      </div>
    </div>
  );
});

SubCommentItem.displayName = 'SubCommentItem';
export default SubCommentItem;
