import React, { useEffect, useRef } from 'react';
import { CommentData, EventData } from '@/shared/interface';
import { ETypeComment } from '../CommentCustomList';
import SubCommentItem from './SubCommentItem';

type Props = {
  event?: EventData;
  type: ETypeComment;
  resource_id?: string;
  subComments: CommentData[];
};

const SubCommentList: React.FC<Props> = ({ type, event, resource_id, subComments }) => {
  const itemRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  useEffect(() => {
    if (subComments.length > 0) {
      const firstOpenCommentId = subComments[0];
      if (itemRefs.current[firstOpenCommentId._id]) {
        itemRefs.current[firstOpenCommentId._id]!.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [subComments]);

  return (
    <>
      {subComments?.map((comment) => (
        <SubCommentItem
          key={comment._id}
          ref={(el) => (itemRefs.current[comment._id] = el)}
          comment={comment}
          event={event}
          resource_id={resource_id}
          type={type}
        />
      ))}
    </>
  );
};

export default SubCommentList;
