import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SignUpState {
  dataSignUp: { email: string } | null;
  loading: boolean;
  error?: string;
  isSuccess: boolean;
}
const initialState: SignUpState = {
  dataSignUp: null,
  isSuccess: false,
  loading: false,
  error: undefined,
};

export const SignUpSlice = createSlice({
  name: 'SignUpState',
  initialState,
  reducers: {
    updateSignUpData: (state: SignUpState) => {
      state.loading = true;
    },
    updateSignUpSuccess: (state: SignUpState, action: PayloadAction<{ email: string }>) => {
      state.loading = false;
      state.isSuccess = true;
      state.dataSignUp = action.payload;
    },
    updateSignUpFailure: (state: SignUpState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.isSuccess = false;
      state.error = action.payload;
    },
    cleanSignUp: () => {
      return initialState;
    },
  },
});

export const { updateSignUpData, updateSignUpSuccess, updateSignUpFailure, cleanSignUp } = SignUpSlice.actions;

export default SignUpSlice.reducer;
