import {
  BookingCreate,
  IAcceptBookingRequest,
  IAcceptBookingResponse,
  IBookingRequestStatus,
  ICreateBookingRequest,
  ICreateBookingResponse,
  IGetBookingsResponse,
  IGetMyBookingResponse,
  IGetSingleBookingRequest,
  IGetSingleBookingResponse,
  IRejectBookingRequest,
  IRejectBookingResponse,
  IRemoveBookingRequest,
  IRemoveBookingResponse,
  IStartBookingRequest,
  IStartBookingResponse,
  IUpdateBookingRequest,
  IUpdateBookingResponse,
} from '@/shared/interface';
import { ApiCall, endpoints } from './api';
export const BookingApi = {
  createBooking: async (data: ICreateBookingRequest): Promise<ICreateBookingResponse> =>
    await ApiCall.post(endpoints.booking, data),
  getSingleBooking: async (data: IGetSingleBookingRequest): Promise<IGetSingleBookingResponse> =>
    await ApiCall.get(`${endpoints.booking}?booking_id=${data.booking_id}`),
  getBookings: async (data?: IBookingRequestStatus): Promise<IGetBookingsResponse> =>
    await ApiCall.get(`${endpoints.bookings}?type=${data?.booking_status ? data?.booking_status : ''}`),
  startBooking: async (data: IStartBookingRequest): Promise<IStartBookingResponse> =>
    (await ApiCall.put())(`${endpoints.booking}/start`, data),
  acceptBooking: async (data: IAcceptBookingRequest): Promise<IAcceptBookingResponse> =>
    (await ApiCall.put())(`${endpoints.booking}/accept`, data),
  rejectBooking: async (data: IRejectBookingRequest): Promise<IRejectBookingResponse> =>
    (await ApiCall.put())(`${endpoints.booking}/reject`, data),
  removeBooking: async (data: IRemoveBookingRequest): Promise<IRemoveBookingResponse> =>
    (await ApiCall.put())(`${endpoints.booking}/remove`, data),
  updateBooking: async (data: IUpdateBookingRequest): Promise<IUpdateBookingResponse> =>
    (await ApiCall.put())(`${endpoints.booking}/update`, data),
  getBookingCreateApi: async (data: BookingCreate): Promise<IGetSingleBookingResponse> =>
    await ApiCall.get(`${endpoints.booking}?payment_intent=${data?.intent_id}`),
  getMyBookingApi: async (): Promise<IGetMyBookingResponse> => await ApiCall.get(`${endpoints.myBookings}`),
};
