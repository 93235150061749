import { EventApi } from '@/api/event';
import { EventRequest, EventResponse, EventsResponse, SingleEventResponse } from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  IPayloadCreatePaymentRegisterEvent,
  IPayloadFilterGetAllEvents,
  IPayloadSaveEvent,
  IResponseCreatePaymentRegisterEvent,
  IResponseGetSavedEvent,
} from '../interfaces';
import * as actionTypes from './actionTypes';
import {
  cancelEvent,
  cancelEventFailure,
  cancelEventSuccess,
  createPaymentRegisterEvent,
  createPaymentRegisterEventFailure,
  createPaymentRegisterEventSuccess,
  getEvent,
  getEventFailure,
  getEventSuccess,
  getSavedEvent,
  getSavedEventFailure,
  getSavedEventSuccess,
  getSingleEventFailure,
  getSingleEventState,
  getSingleEventSuccess,
  saveEvent,
  saveEventFailure,
  saveEventSuccess,
} from './slice';

function* eventRequest() {
  try {
    yield put(getEvent());
    const res: EventResponse = yield call(EventApi.events);
    if (res.success) {
      yield put(getEventSuccess(res));
    } else {
      yield put(getEventFailure(res.message));
    }
  } catch (error) {
    yield put(getEventFailure(error?.toString()));
  }
}

function* getUpcomingEvents(action: PayloadAction<IPayloadFilterGetAllEvents>) {
  try {
    yield put(getEvent());
    const res: EventsResponse = yield call(EventApi.getUpcomingEvents, action.payload);
    if (res.data) {
      yield put(
        getEventSuccess({
          data: { upcoming: res.data.events || [], completed: [], pending: [] },
          total: res.data.total,
        })
      );
    } else {
      yield put(getEventFailure(res.message));
    }
  } catch (error) {
    yield put(getEventFailure(error?.toString()));
  }
}

function* getPastEvents(action: PayloadAction<IPayloadFilterGetAllEvents>) {
  try {
    yield put(getEvent());
    const res: EventsResponse = yield call(EventApi.getPastEvents, action.payload);
    if (res.data) {
      yield put(
        getEventSuccess({
          data: { upcoming: [], completed: res.data.events || [], pending: [] },
          total: res.data.total,
        })
      );
    } else {
      yield put(getEventFailure(res.message));
    }
  } catch (error) {
    yield put(getEventFailure(error?.toString()));
  }
}

function* getSingleEvent(action: PayloadAction<EventRequest>) {
  try {
    yield put(getSingleEventState());
    const res: SingleEventResponse = yield call(EventApi.getEventDetail, action.payload);
    if (res.data) {
      yield put(getSingleEventSuccess(res.data));
    } else {
      yield put(getSingleEventFailure(res.message));
    }
  } catch (error) {
    yield put(getSingleEventFailure(error?.toString()));
  }
}

function* createPaymentRegisterEventWorker(action: PayloadAction<IPayloadCreatePaymentRegisterEvent>) {
  try {
    yield put(createPaymentRegisterEvent());
    const res: IResponseCreatePaymentRegisterEvent = yield call(EventApi.createPaymentRegisterEvent, action.payload);
    if (res.success) {
      yield put(createPaymentRegisterEventSuccess(res.data));
    } else {
      yield put(createPaymentRegisterEventFailure(res?.message ?? 'Register Attendance has been failed'));
    }
  } catch (error) {
    const err = error as { message: string };
    yield put(createPaymentRegisterEventFailure(err?.message || 'Register Attendance has been failed'));
  }
}

function* getSavedEvents(action: PayloadAction<Record<string, string>>) {
  try {
    yield put(getSavedEvent());
    const res: IResponseGetSavedEvent = yield call(EventApi.getSavedEvent, action.payload);
    if (res.data) {
      yield put(getSavedEventSuccess(res.data));
    } else {
      yield put(getSavedEventFailure(res.message ?? 'Get saved event has been failed'));
    }
  } catch (error) {
    const err = error as { message: string };
    yield put(getSavedEventFailure(err?.message || 'Get saved event has been failed'));
  }
}

function* saveEvents(action: PayloadAction<IPayloadSaveEvent>) {
  try {
    yield put(saveEvent());
    const res: IResponseGetSavedEvent = yield call(EventApi.saveEvent, action.payload);
    if (res.data) {
      yield put(saveEventSuccess(res.data));
    } else {
      yield put(saveEventFailure());
    }
  } catch (error) {
    yield put(getEventFailure());
  }
}

function* cancelEventWorker(action: PayloadAction<string>) {
  try {
    yield put(cancelEvent());
    const res: IResponseGetSavedEvent = yield call(EventApi.cancelEvent, action.payload);
    if (res) {
      yield put(cancelEventSuccess());
    } else {
      yield put(cancelEventFailure());
    }
  } catch (error) {
    yield put(cancelEventFailure());
  }
}

export function* watchEvents() {
  yield takeLatest(actionTypes.GET_EVENT, eventRequest);
  yield takeLatest(actionTypes.GET_UPCOMING_EVENT, getUpcomingEvents);
  yield takeLatest(actionTypes.GET_PAST_EVENT, getPastEvents);
  yield takeLatest(actionTypes.GET_SINGLE_EVENT, getSingleEvent);
  yield takeLatest(actionTypes.GET_EVENT, getEvent);
  yield takeLatest(actionTypes.CREATE_PAYMENT_REGISTER_EVENT, createPaymentRegisterEventWorker);
  yield takeLatest(actionTypes.GET_SAVED_EVENT, getSavedEvents);
  yield takeLatest(actionTypes.SAVE_EVENT, saveEvents);
  yield takeLatest(actionTypes.CANCEL_EVENT, cancelEventWorker);
}
