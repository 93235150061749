import React from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
type Props = {
  children: React.ReactNode;
};

const StripeProvider = ({ children }: Props) => {
  const stripePromise = loadStripe(
    process.env.STRIPE_PUBLISH_KEY ??
      'pk_test_51PiSJWC5kPqmw4mTVobg09uI03nm5xq0QBNY1WvBCIS8PRYUrOA5pseDUPV41AU4dWjIRe1eiiHYSIfpRJPYMF7200kexY3oY9'
  );
  return <Elements stripe={stripePromise}>{children}</Elements>;
};

export default StripeProvider;
