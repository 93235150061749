import { GetPowerClassResponse, OpenPowerClassResponse, CompletePowerClassResponse } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PowerClassState {
  powerClassData: GetPowerClassResponse | null;
  openPowerClassData: OpenPowerClassResponse | null;
  completePowerClassData: CompletePowerClassResponse | null;
  loading: boolean;
  error?: string;
}
const initialState: PowerClassState = {
  powerClassData: null,
  openPowerClassData: null,
  completePowerClassData: null,
  loading: false,
  error: undefined,
};

export const powerClassSlice = createSlice({
  name: 'PowerClassState',
  initialState,
  reducers: {
    getPowerClassData: (state: PowerClassState) => {
      state.loading = true;
    },
    getPowerClassDataSuccess: (state: PowerClassState, action: PayloadAction<GetPowerClassResponse>) => {
      state.loading = false;
      state.powerClassData = action.payload;
    },
    getPowerClassDataFailure: (state: PowerClassState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    openPowerClassData: (state: PowerClassState) => {
      state.loading = true;
    },
    openPowerClassDataSuccess: (state: PowerClassState, action: PayloadAction<OpenPowerClassResponse>) => {
      state.loading = false;
      state.openPowerClassData = action.payload;
    },
    openPowerClassDataFailure: (state: PowerClassState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    completePowerClassData: (state: PowerClassState) => {
      state.loading = true;
    },
    completePowerClassDataSuccess: (state: PowerClassState, action: PayloadAction<CompletePowerClassResponse>) => {
      state.loading = false;
      state.completePowerClassData = action.payload;
    },
    completePowerClassDataFailure: (state: PowerClassState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  getPowerClassData,
  getPowerClassDataSuccess,
  getPowerClassDataFailure,
  openPowerClassData,
  openPowerClassDataSuccess,
  openPowerClassDataFailure,
  completePowerClassData,
  completePowerClassDataSuccess,
  completePowerClassDataFailure,
} = powerClassSlice.actions;

export default powerClassSlice.reducer;
