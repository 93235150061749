import React from 'react';
import LoadingCircular from '../LoadingCircular';
import './suspenseLoading.scss';
const SuspenseLoading = () => {
  return (
    <div className="suspense-loading">
      <LoadingCircular className="suspense-loading__circle" />
    </div>
  );
};

export default SuspenseLoading;
