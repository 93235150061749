import { CommentData, ProfileData } from '@/shared/interface';

export const handleConvertNewComment = (data: CommentData, profile: ProfileData | null) => {
  return {
    ...data,
    user: {
      ...data.user,
      user_profile: profile?.user_profile ?? '',
    },
  };
};
