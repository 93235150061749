import { CommentApi } from '@/api/comment';
import { CommentResourceApi } from '@/api/commentResource';
import { ArrowCTA, AvatarPlaceholder } from '@/assets/redesign/images';
import { useAppSelector } from '@/redux/hook';
import { RootState } from '@/redux/store';
import { CommentData, EventData } from '@/shared/interface';
import { Input } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import CommentCustomItem from '../CommentCustomItem';
import Image from '../Image';
import LoadingCircular from '../LoadingCircular';
import './commentCustomList.scss';
import { handleConvertNewComment } from '@/utils/convertNewComment';
import Avatar from '../Avatar';

export enum ETypeComment {
  Event = 'Event',
  Article = 'Article',
  Video = 'Video',
  Podcast = 'Podcast',
  Journal = 'Journal',
  CareerStories = 'Career Stories',
  Profiles = 'Profiles',
  NewsFeed = 'NewsFeed',
}
type Props = {
  type: ETypeComment;
  comments: CommentData[];
  event?: EventData;
  resource_id?: string;
};

const CommentCustomList = ({ comments, type, event, resource_id }: Props) => {
  const dispatch = useDispatch();
  const { profileData: profile } = useAppSelector((state: RootState) => state.profileSessionState);
  const { loading } = useAppSelector((state: RootState) => state.commentResourceReducer);

  const [content, setContent] = useState<string>('');
  const [loadingComment, setLoadingComment] = useState<boolean>(false);
  const [newComments, setNewComments] = useState<CommentData[]>([]);

  useEffect(() => {
    setNewComments(comments);
  }, [comments]);

  const handleChangeComment = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setContent(e.target.value);
  }, []);

  const handlePostingComment = useCallback(
    async (e: React.SyntheticEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (content.length <= 0) return;
      try {
        setLoadingComment(true);

        //****** Comment EVENT ******//
        if (comments && event?.id && type === ETypeComment.Event) {
          const { data } = await CommentApi.postCommentOnEvent({ content, event_id: event.id });
          setContent('');

          if (data) {
            const newComment = handleConvertNewComment(data, profile);

            setNewComments((prev) => [...prev, newComment]);
          }
        }

        // ****** Comment RESOURCE *****//
        if (resource_id) {
          const { data } = await CommentResourceApi.postCommentOnEvent({ content, resource_id });
          if (data) {
            const newComment = handleConvertNewComment(data, profile);
            setNewComments((prev) => [...prev, newComment]);
          }
          setContent('');
        }
        setLoadingComment(false);
      } catch (error) {
        setLoadingComment(false);
      }
    },
    [comments, dispatch, content, event, resource_id, handleConvertNewComment]
  );

  const commentsDisplay = useMemo(() => {
    return [...newComments].sort(
      (a, b) => new Date(b?.createdAt ?? '').getTime() - new Date(a?.createdAt ?? '').getTime()
    );
  }, [newComments]);

  return (
    <div>
      <form onSubmit={handlePostingComment} className="comment-custom-list__script--comments-input">
        <Avatar
          first_name={profile?.first_name}
          src={profile?.user_profile || AvatarPlaceholder}
          className="comment-custom-list__info--avatar"
        />
        <div className="comment-custom-list__input--wrapper">
          <Input
            value={content}
            placeholder={t('pastEvent:startTyping')}
            onChange={(e) => {
              handleChangeComment(e);
            }}
            className="comment-custom-list__input"
          />
          {loadingComment && (
            <div className="comment-custom-list__loading--comment">
              <LoadingCircular />
            </div>
          )}
        </div>
        <button
          type="submit"
          disabled={loadingComment}
          className={`sending-comment-button ${loadingComment ? 'sending-comment-button__loading' : ''}`}
        >
          <Image src={ArrowCTA} />
        </button>
      </form>
      <div className="comment-custom-list__line--divide"></div>
      <div className="comment-custom-list__comment--list">
        {loading ? (
          <LoadingCircular />
        ) : (
          <>
            {comments &&
              commentsDisplay?.map((comment) => {
                return (
                  <CommentCustomItem
                    comment={comment}
                    key={comment._id}
                    type={type}
                    event={event ? event : undefined}
                    resource_id={resource_id}
                  />
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};

export default CommentCustomList;
