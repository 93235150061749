import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BaseResponse, GenerateZoomTokenResponse, ZoomResponse } from '@/shared/interface';

interface ZoomJoinState {
  dataCheckZoom: BaseResponse | null;
  loading: boolean;
  error?: string;
  isJoinZoom: boolean;
  dataToken: GenerateZoomTokenResponse | null;
  loadingToken: boolean;
  errorToken?: string;
}

const initialState: ZoomJoinState = {
  dataCheckZoom: null,
  loading: false,
  error: undefined,
  isJoinZoom: false,
  dataToken: null,
  loadingToken: false,
  errorToken: undefined,
};

export const ZoomJoinSlide = createSlice({
  name: 'EventSlide',
  initialState,
  reducers: {
    joinZoomRequest: (state: ZoomJoinState) => {
      state.loading = true;
    },
    joinZoomRequestFailure: (state: ZoomJoinState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.dataCheckZoom = null;
      state.error = action.payload;
      state.isJoinZoom = false;
    },
    joinZoomRequestSuccess: (state: ZoomJoinState, action: PayloadAction<ZoomResponse>) => {
      state.loading = false;
      state.dataCheckZoom = action.payload;
      state.isJoinZoom = true;
    },
    generateTokenRequest: (state: ZoomJoinState) => {
      state.loadingToken = true;
    },
    generateTokenRequestFailure: (state: ZoomJoinState, action: PayloadAction<string | undefined>) => {
      state.loadingToken = false;
      state.dataToken = null;
      state.errorToken = action.payload;
    },
    generateTokenRequestSuccess: (state: ZoomJoinState, action: PayloadAction<GenerateZoomTokenResponse>) => {
      state.loadingToken = false;
      state.dataToken = action.payload;
    },
    clearGenerateTokenRequest: (state: ZoomJoinState) => {
      state.loadingToken = false;
      state.dataToken = null;
      state.errorToken = undefined;
    },
  },
});

export const {
  joinZoomRequest,
  joinZoomRequestFailure,
  joinZoomRequestSuccess,
  generateTokenRequest,
  generateTokenRequestFailure,
  generateTokenRequestSuccess,
  clearGenerateTokenRequest,
} = ZoomJoinSlide.actions;

export default ZoomJoinSlide.reducer;
