import { EventRequest, EventResponse, EventsResponse, IError, SingleEventResponse } from '@/shared/interface';
import { ApiCall, endpoints } from './api';
import {
  ETypeEventBusiness,
  IPayloadCreatePaymentRegisterEvent,
  IPayloadFilterGetAllEvents,
  IPayloadSaveEvent,
  IResponseCreatePaymentRegisterEvent,
} from '@/redux/interfaces';
import { showErrorMessage } from '@/utils/showMessage';
import queryString from 'query-string';

export const EventApi = {
  events: async (): Promise<EventResponse> => await ApiCall.get(endpoints.events),
  getEventDetail: async (data: EventRequest): Promise<SingleEventResponse> => {
    try {
      const response = await ApiCall.get(`${endpoints.events}?id=${data?.event_id}`);

      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  getUpcomingEvents: async (payload: IPayloadFilterGetAllEvents): Promise<EventsResponse | IError> => {
    try {
      const query = queryString.stringify({ ...payload, type: ETypeEventBusiness.Upcoming });
      const response = await ApiCall.get(`${endpoints.businessEvents}?${query}`);

      return response;
    } catch (error) {
      const err = error as IError;
      return err;
    }
  },
  getPastEvents: async (payload: IPayloadFilterGetAllEvents): Promise<EventsResponse | IError> => {
    try {
      const query = queryString.stringify({ ...payload, type: ETypeEventBusiness.Past });

      const response = await ApiCall.get(`${endpoints.businessEvents}?${query}`);
      return response;
    } catch (error) {
      const err = error as IError;
      return err;
    }
  },

  createPaymentRegisterEvent: async (
    payload: IPayloadCreatePaymentRegisterEvent
  ): Promise<IResponseCreatePaymentRegisterEvent> => {
    return await ApiCall.post(endpoints.createPaymentEvent, payload, true);
  },

  getSavedEvent: async (query: Record<string, string>): Promise<EventResponse> => {
    const queryString = new URLSearchParams(query).toString();
    return await ApiCall.get(`${endpoints.savedEvent}?${queryString}`, true);
  },

  saveEvent: async (payload: IPayloadSaveEvent): Promise<EventResponse | IError> => {
    try {
      return await ApiCall.post(endpoints.saveEvent, payload);
    } catch (error) {
      const err = error as IError;
      showErrorMessage(err?.message ?? 'Register Event has been failure');
      return err;
    }
  },

  cancelEvent: async (id: string) => {
    try {
      const res = (await ApiCall.put())(`${endpoints.events}/cancel/${id}`, {}, true);
      return res;
    } catch (error) {
      return { data: null, error };
    }
  },
};
