/* eslint-disable no-useless-catch */
import { MentorsMatchResponse, MentorRequest } from '@/shared/interface';
import { ApiCall, endpoints } from './api';

export const MentorApi = {
  getListMentor: async (queryParams?: string): Promise<MentorsMatchResponse> =>
    await ApiCall.get(endpoints.mentors + (queryParams || '')),
  getSingleMentor: async (data: MentorRequest): Promise<MentorsMatchResponse> =>
    await ApiCall.get(`${endpoints.single_mentor}?mentorId=${data?.mentorId}`),
  getAllMentorsMatch: async (url: string): Promise<MentorsMatchResponse> => {
    try {
      const res = await ApiCall.get(url, true);
      return res;
    } catch (error) {
      throw error;
    }
  },
};
