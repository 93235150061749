import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

import { getMessaging } from 'firebase/messaging';

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASUREMENT_ID,
};

export const firebaseVapidKey = process.env.FIREBASE_VAPID_KEY;
export const firebaseApp = initializeApp(firebaseConfig);

export const firebaseMessaging = getMessaging(firebaseApp);
export const db = getFirestore(firebaseApp);
export const firebaseStorage = getStorage();
