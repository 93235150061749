import '../../assets/scss/component/loading.scss';
interface Props {
  className?: string;
  classNameSpinner?: string;
}
const LoadingCircular = ({ className, classNameSpinner }: Props) => {
  return (
    <div className={`loading-indicator ${className}`}>
      <div className={`spinner ${classNameSpinner}`}></div>
    </div>
  );
};

export default LoadingCircular;
