import { memo } from 'react';
import './index.scss';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const LoadingResourceDetail = memo(() => {
  return (
    <div className="loading-resource-detail">
      <div className="loading-resource-detail__banner">
        <Skeleton baseColor="#d8d8d8" highlightColor="#fafafa" width={'100%'} height={'100%'} />
      </div>

      <div className="loading-resource-detail__content-wrapper">
        <Skeleton baseColor="#d8d8d8" highlightColor="#fafafa" width={'100%'} height={'100%'} />
      </div>
    </div>
  );
});

LoadingResourceDetail.displayName = 'LoadingResourceDetail';
export default LoadingResourceDetail;
