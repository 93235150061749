export interface IPayloadUpdateProfile {
  first_name: string;
  last_name: string;
  job_title: string;
  industry: string;
  use_avatar?: boolean;
  avatar?: number;
  learning_style?: string[];
  learning_pace?: string;
  pronouns?: string;
  avatarFile?: File | null;
  mentor: {
    gender: EGenderUser | string;
    coach_roles: string[];
  };
}

export interface IPayloadVerifyAccount {
  email: string;
  otp: string;
}

export interface IPayloadResendOTP {
  email: string;
}

export interface IPayloadChangePassword {
  new_password: string;
  current_password: string;
  access_token: string;
}

export enum EGenderUser {
  Male = 'Male',
  Female = 'Female',
}
