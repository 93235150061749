import { AuthApi } from '@/api/auth';
import { tourGuideApi } from '@/api/tourGuide';
import { uploadFile } from '@/api/upLoadFile';
import {
  BaseResponse,
  IResourceStatisticsResponse,
  ProfileResponse,
  onBoardingRequest,
  updateNotificationTokenRequest,
} from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, take, takeLatest } from 'redux-saga/effects';
import { IPayloadChangePassword, IPayloadVerifyAccount } from '../interfaces';
import { requestSignOut } from '../session/slice';
import { getProfileAction } from './action';
import * as actionTypes from './actionTypes';
import {
  changePassword,
  changePasswordFailure,
  changePasswordSuccess,
  deleteAccount,
  deleteAccountFailure,
  deleteAccountSuccess,
  getProfile,
  getProfileFailure,
  getProfileSuccess,
  getResourceStatistics,
  getResourceStatisticsFailure,
  getResourceStatisticsSuccess,
  resendOTPVerify,
  resendOTPVerifyFailure,
  resendOTPVerifySuccess,
  updateProfile,
  updateProfileFailure,
  updateProfileSuccess,
  updateTourCompleted,
  updateTourCompletedFail,
  updateTourCompletedSuccess,
  uploadImageToService,
  uploadImageToServiceFailure,
  uploadImageToServiceSuccess,
  verifyAccount,
  verifyAccountFailure,
  verifyAccountSuccess,
} from './slice';
import { showErrorMessage, showSuccessMessage } from '@/utils/showMessage';

function* profileRequest() {
  try {
    yield put(getProfile());
    const res: ProfileResponse = yield call(AuthApi.profile);
    if (res.success) {
      yield put(getProfileSuccess(res));
    } else {
      yield put(getProfileFailure(res.message));
    }
  } catch (error) {
    yield put(getProfileFailure(error?.toString()));
  }
}

function* updateProfileRequest(action: PayloadAction<onBoardingRequest>) {
  try {
    yield put(updateProfile());
    const res: ProfileResponse = yield call(AuthApi.updateProfile, action.payload);
    if (res.success) {
      yield put(updateProfileSuccess());
    } else {
      yield put(updateProfileFailure(res.message));
    }
  } catch (error) {
    yield put(updateProfileFailure(error?.toString()));
  }
}

function* updateProfileMentorRequest(action: PayloadAction<onBoardingRequest>) {
  try {
    yield put(updateProfile());
    const res: ProfileResponse = yield call(AuthApi.updateProfileMentor, action.payload);
    if (res.success) {
      yield put(updateProfileSuccess());
    } else {
      yield put(updateProfileFailure(res.message));
    }
  } catch (error) {
    yield put(updateProfileFailure(error?.toString()));
  }
}

function* updateTourCompletedAction() {
  try {
    yield put(updateTourCompleted());
    const res: ProfileResponse = yield call(tourGuideApi.tourCompleted);
    if (res.success) {
      yield put(updateTourCompletedSuccess());
    } else {
      yield put(updateTourCompletedFail());
    }
  } catch (error) {
    yield put(updateTourCompletedFail());
  }
}
function* deleteAccountWorker() {
  try {
    yield put(deleteAccount());
    const res: BaseResponse = yield call(AuthApi.deleteAccount);
    if (res.success) {
      yield put(deleteAccountSuccess());
      yield put(requestSignOut());
    } else {
      yield put(deleteAccountFailure());
    }
  } catch (error) {
    yield put(deleteAccountFailure());
  }
}

function* uploadImageToServiceWorker(action: PayloadAction<{ URL: string; image?: File | string }>) {
  try {
    yield put(uploadImageToService());
    const { URL, image } = action.payload;
    const res: Response = yield call(uploadFile, URL, image);
    if (res.status === 200) {
      yield put(getProfileAction());
      yield take(getProfileSuccess.type);
      yield put(uploadImageToServiceSuccess());
    } else {
      yield put(uploadImageToServiceFailure());
    }
  } catch (error) {
    yield put(uploadImageToServiceFailure());
  }
}

function* verifyAccountWorker(action: PayloadAction<IPayloadVerifyAccount>) {
  try {
    yield put(verifyAccount());
    const res: BaseResponse = yield call(AuthApi.verifyAccount, action.payload);
    if (res.success) {
      yield put(verifyAccountSuccess());
    } else {
      yield put(verifyAccountFailure('Verify account failed'));
    }
  } catch (error) {
    const { message } = error as Error;
    yield put(verifyAccountFailure(message));
  }
}

function* resendOTPVerifyAccountWorker(action: PayloadAction<IPayloadVerifyAccount>) {
  try {
    yield put(resendOTPVerify());
    const res: BaseResponse = yield call(AuthApi.resendOTPVerify, action.payload);
    if (res.success) {
      yield put(resendOTPVerifySuccess());
    } else {
      yield put(resendOTPVerifyFailure('Resend OTP code failed'));
    }
  } catch (error) {
    yield put(resendOTPVerifyFailure('Resend OTP code failed'));
  }
}

function* changePasswordRequest(action: PayloadAction<IPayloadChangePassword>) {
  try {
    yield put(changePassword());
    const res: BaseResponse = yield call(AuthApi.changePassword, action.payload);
    if (res.success) {
      yield put(changePasswordSuccess());
      showSuccessMessage('Change password successfully');
    } else {
      yield put(changePasswordFailure());
      showErrorMessage(res.message || 'Change password failed');
    }
  } catch (error) {
    const err = error as Error;
    showErrorMessage(err.message);
  }
}

function* getResourceStatisticsRequest() {
  try {
    yield put(getResourceStatistics());
    const res: IResourceStatisticsResponse = yield call(AuthApi.getResourceStatistics);
    if (res.success) {
      yield put(getResourceStatisticsSuccess(res.data));
    } else {
      yield put(getResourceStatisticsFailure());
    }
  } catch (error) {
    yield put(getResourceStatisticsFailure());
  }
}

function* updateProfileTokenRequest(action: PayloadAction<updateNotificationTokenRequest>) {
  try {
    yield put(updateProfile());
    const res: ProfileResponse = yield call(AuthApi.updateNotiTokenProfile, action.payload);
    if (res.success) {
      yield put(updateProfileSuccess());
    } else {
      yield put(updateProfileFailure(res.message));
    }
  } catch (error) {
    yield put(updateProfileFailure(error?.toString()));
  }
}

export function* watchProfile() {
  yield takeLatest(actionTypes.GET_PROFILE, profileRequest);
  yield takeLatest(actionTypes.UPDATE_PROFILE, updateProfileRequest);
  yield takeLatest(actionTypes.UPDATE_PROFILE_MENTOR, updateProfileMentorRequest);
  yield takeLatest(actionTypes.UPDATE_TOUR_COMPLETED, updateTourCompletedAction);
  yield takeLatest(actionTypes.DELETE_ACCOUNT, deleteAccountWorker);
  yield takeLatest(actionTypes.UPLOAD_IMAGE_TO_SERVICE, uploadImageToServiceWorker);
  yield takeLatest(actionTypes.VERIFY_ACCOUNT, verifyAccountWorker);
  yield takeLatest(actionTypes.RESEND_CODE_VERIFY_ACCOUNT, resendOTPVerifyAccountWorker);
  yield takeLatest(actionTypes.CHANGE_PASSWORD, changePasswordRequest);
  yield takeLatest(actionTypes.GET_RESOURCE_STATISTICS, getResourceStatisticsRequest);
  yield takeLatest(actionTypes.UPDATE_TOKEN_PROFILE, updateProfileTokenRequest);
}
