import { onBoardingRequest, ProfileImageUpload, ProfileResponse } from '@/shared/interface';
import { ApiCall, endpoints } from './api';

export const onBoardingApi = {
  updateOnBoarding: async (data: onBoardingRequest): Promise<ProfileResponse> =>
    await ApiCall.post(`${endpoints.onboarding}`, data),
  upLoadImage: async (): Promise<ProfileImageUpload> => await ApiCall.get(endpoints.profileImageUpload),
  updateOnBoardingMentor: async (data: onBoardingRequest): Promise<ProfileResponse> =>
    await ApiCall.post(`${endpoints.onboardingMentor}`, data),
};
