import * as actionTypes from './actionTypes';
import {
  CreateEntryRequest,
  DeleteEntryRequest,
  GetResourceDetailRequest,
  LearningRequest,
  TrackingLearningRequest,
} from '@/shared/interface';

export const getLearningAction = () => {
  return {
    type: actionTypes.GET_LEARNING,
  };
};

export const openLearningAction = (payload: LearningRequest) => {
  return {
    type: actionTypes.OPEN_LEARNING,
    payload,
  };
};

export const completeLearningAction = (payload: LearningRequest) => {
  return {
    type: actionTypes.COMPLETE_LEARNING,
    payload,
  };
};

export const trackingLearningAction = (payload: TrackingLearningRequest) => {
  return {
    type: actionTypes.TRACKING_LEARNING,
    payload,
  };
};

export const getResourceAction = (payload: Record<string, string | boolean>) => {
  return {
    type: actionTypes.GET_RESOURCE,
    payload,
  };
};

export const getResourceContentTypeCareersToriesAction = (payload: Record<string, string | boolean>) => {
  return {
    type: actionTypes.FETCH_RESOURCE_CONTENT_TYPE_CAREER_STORIES,
    payload,
  };
};

export const getResourceContentTypeProfilesAction = (payload: Record<string, string | boolean>) => {
  return {
    type: actionTypes.FETCH_RESOURCE_CONTENT_TYPE_PROFILES,
    payload,
  };
};

export const createEntry = (payload: CreateEntryRequest) => {
  return {
    type: actionTypes.CREATE_ENTRY,
    payload,
  };
};

export const getUserEntry = () => {
  return {
    type: actionTypes.GET_USER_ENTRY,
  };
};

export const deleteEntry = (payload: DeleteEntryRequest) => {
  return {
    type: actionTypes.DELETE_ENTRY,
    payload,
  };
};

export const getResourceDetail = (payload: GetResourceDetailRequest) => {
  return {
    type: actionTypes.GET_RESOURCE_DETAIL,
    payload,
  };
};

export const getMyLoveContent = (payload: Record<string, string | boolean>) => {
  return {
    type: actionTypes.GET_MY_LOVE_CONTENT_REQUEST,
    payload,
  };
};
