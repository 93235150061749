import { paymentApi } from '@/api/payment';
import {
  ConfirmStripeResponse,
  CreateStripeResponse,
  ICreateBookingRequest,
  KeyStripeResponse,
  ProfileResponse,
} from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  ICardResponse,
  IPayloadAddCard,
  IPayloadConfirm,
  IPayloadDeleteCard,
  IPayloadRateMentor,
  IPayloadUpdateDefaultCard,
} from '../interfaces';
import { getAllCardsPaymentAction } from './action';
import * as actionTypes from './actionTypes';
import {
  addCardPayment,
  addCardPaymentFailure,
  addCardPaymentSuccess,
  deleteCardPayment,
  deleteCardPaymentFailure,
  deleteCardPaymentSuccess,
  getAllCardsPayment,
  getAllCardsPaymentFailure,
  getAllCardsPaymentSuccess,
  updateCardPaymentDefault,
  updateCardPaymentDefaultFailure,
  updateCardPaymentDefaultSuccess,
  getKeyStripe,
  getKeyStripeSuccess,
  getKeyStripeFailure,
  createPaymentStripe,
  createPaymentSuccess,
  createPaymentFailure,
  mentorRateRequest,
  mentorRateRequestSuccess,
  mentorRateRequestFailure,
  updatePaymentStripe,
  updatePaymentSuccess,
  updatePaymentFailure,
  confirmStripeRequest,
  confirmStripeRequestSuccess,
  confirmStripeRequestFailure,
} from './slice';

function* getAllCardsPaymentWorker() {
  try {
    yield put(getAllCardsPayment());
    const res: ProfileResponse = yield call(paymentApi.getAllCardsPayment);
    if (res.success) {
      yield put(getAllCardsPaymentSuccess(res.data.card));
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(getAllCardsPaymentFailure(err?.meta || `Get all cards payment failed`));
  }
}

function* addCardPaymentWorker(action: PayloadAction<IPayloadAddCard>) {
  try {
    yield put(addCardPayment());
    yield call(paymentApi.addCardPayment, action.payload);
    yield put(addCardPaymentSuccess(action.payload));
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(addCardPaymentFailure(err?.meta ?? `Add payment failed`));
  }
}

function* deleteCardPaymentWorker(action: PayloadAction<IPayloadDeleteCard>) {
  try {
    yield put(deleteCardPayment());
    const res: ICardResponse = yield call(paymentApi.deleteCardPayment, action.payload);
    if (res.success) {
      yield put(deleteCardPaymentSuccess(action.payload));
    } else {
      yield put(deleteCardPaymentFailure(`Delete card failed`));
    }
  } catch (error) {
    yield put(deleteCardPaymentFailure(`Delete card failed`));
  }
}

function* updateCardPaymentDefaultWorker(action: PayloadAction<IPayloadUpdateDefaultCard>) {
  try {
    yield put(updateCardPaymentDefault());
    const res: ICardResponse = yield call(paymentApi.updateCardPaymentDefault, action.payload);
    if (res.success) {
      yield put(getAllCardsPaymentAction());
      yield put(updateCardPaymentDefaultSuccess());
    } else {
      yield put(updateCardPaymentDefaultFailure());
    }
  } catch (error) {
    yield put(updateCardPaymentDefaultFailure());
  }
}

function* getKeyStripePayment(action: PayloadAction<ICreateBookingRequest>) {
  try {
    yield put(getKeyStripe());
    const res: KeyStripeResponse = yield call(paymentApi.getKeyStripe, action.payload);
    if (res.success) {
      yield put(getKeyStripeSuccess(res));
      // yield put(updateCardPaymentDefaultSuccess());
    } else {
      yield put(getKeyStripeFailure('Get key stripe failed'));
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(getKeyStripeFailure(err?.message));
  }
}

function* createStripePayment() {
  try {
    yield put(createPaymentStripe());
    const res: CreateStripeResponse = yield call(paymentApi.createPayment);
    if (res.success) {
      yield put(createPaymentSuccess(res));
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(createPaymentFailure(err?.meta));
  }
}

function* updateMentorRate(action: PayloadAction<IPayloadRateMentor>) {
  try {
    yield put(mentorRateRequest());
    const res: CreateStripeResponse = yield call(paymentApi.rateMentor, action.payload);
    if (res.success) {
      yield put(mentorRateRequestSuccess());
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(mentorRateRequestFailure(err?.meta));
  }
}

function* updateStripePayment() {
  try {
    yield put(updatePaymentStripe());
    const res: CreateStripeResponse = yield call(paymentApi.updateStripeMentor);
    if (res.success) {
      yield put(updatePaymentSuccess(res));
    } else {
      yield put(updatePaymentFailure(res.message ?? 'Update Account Failed'));
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(updatePaymentFailure(err?.meta));
  }
}

function* confirmStripeSaga(action: PayloadAction<IPayloadConfirm>) {
  try {
    yield put(confirmStripeRequest());
    const res: ConfirmStripeResponse = yield call(paymentApi.confirmPayment, action.payload);
    if (res.success) {
      yield put(confirmStripeRequestSuccess(res));
    }
  } catch (error) {
    const err = error as { message: string; meta: string };
    yield put(confirmStripeRequestFailure(err?.meta));
  }
}

export function* watchPayment() {
  yield takeLatest(actionTypes.ADD_CARD_PAYMENT, addCardPaymentWorker);
  yield takeLatest(actionTypes.GET_ALL_CARDS_PAYMENT, getAllCardsPaymentWorker);
  yield takeLatest(actionTypes.DELETE_CARD_PAYMENT, deleteCardPaymentWorker);
  yield takeLatest(actionTypes.UPDATE_CARD_PAYMENT_DEFAULT, updateCardPaymentDefaultWorker);
  yield takeLatest(actionTypes.GET_KEY_STRIPE_PAYMENT, getKeyStripePayment);
  yield takeLatest(actionTypes.CREATE_PAYMENT, createStripePayment);
  yield takeLatest(actionTypes.MENTOR_RATE, updateMentorRate);
  yield takeLatest(actionTypes.STRIPE_UPDATE, updateStripePayment);
  yield takeLatest(actionTypes.CONFIRM_PAYMENT, confirmStripeSaga);
}
