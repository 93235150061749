import { NotFoundImage } from '@/assets/images';
import React from 'react';

interface ImageProps extends React.HTMLProps<HTMLImageElement> {
  src: string;
  alt?: string;
}

const Image: React.FC<ImageProps> = ({ alt, src, ...props }) => {
  return (
    <img
      src={src}
      alt={alt}
      onError={(e) => {
        e.currentTarget.src = NotFoundImage;
      }}
      {...props}
    />
  );
};

export default Image;
