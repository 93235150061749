import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WebStyleResponse } from '@/shared/interface';

interface WebStyleState {
  data: WebStyleResponse | null;
  loading: boolean;
  error?: string;
}

const initialState: WebStyleState = {
  data: null,
  loading: false,
  error: undefined,
};

export const webStyleSlide = createSlice({
  name: 'EventSlide',
  initialState,
  reducers: {
    getWebStyle: (state: WebStyleState) => {
      state.loading = true;
    },
    getWebStyleFailure: (state: WebStyleState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    },
    getWebStyleSuccess: (state: WebStyleState, action: PayloadAction<WebStyleResponse>) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { getWebStyle, getWebStyleFailure, getWebStyleSuccess } = webStyleSlide.actions;

export default webStyleSlide.reducer;
