import { onBoardingRequest, ProfileImageUpload, ProfileResponse } from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  updateOnBoarding,
  updateOnBoardingSuccess,
  updateOnBoardingFailure,
  uploadImage,
  uploadImageSuccess,
  uploadImageFailure,
  cleanOnBoarding,
  showMenuUser,
  isTour,
  showMenuUserHeader,
} from './slice';
import * as actionTypes from './actionTypes';
import { onBoardingApi } from '@/api/onBoarding';

function* updateOnBoardingAction(action: PayloadAction<onBoardingRequest>) {
  try {
    yield put(updateOnBoarding());
    const res: ProfileResponse = yield call(onBoardingApi.updateOnBoarding, action.payload);
    if (res.success) {
      yield put(updateOnBoardingSuccess(res));
    } else {
      yield put(updateOnBoardingFailure(res?.meta));
    }
  } catch (error) {
    yield put(updateOnBoardingFailure(error?.toString()));
  }
}

function* updateImageAction() {
  try {
    yield put(uploadImage());
    const res: ProfileImageUpload = yield call(onBoardingApi.upLoadImage);
    if (res.success) {
      yield put(uploadImageSuccess(res));
    } else {
      yield put(uploadImageFailure(res.message));
    }
  } catch (error) {
    yield put(uploadImageFailure(error?.toString()));
  }
}

function* clearAllDataOnBoardingAction() {
  yield put(cleanOnBoarding());
}

function* updateOnBoardingMentorAction(action: PayloadAction<onBoardingRequest>) {
  try {
    yield put(updateOnBoarding());
    const res: ProfileResponse = yield call(onBoardingApi.updateOnBoardingMentor, action.payload);
    if (res.success) {
      yield put(updateOnBoardingSuccess(res));
    } else {
      yield put(updateOnBoardingFailure(res?.meta));
    }
  } catch (error) {
    yield put(updateOnBoardingFailure(error?.toString()));
  }
}

function* onShowMenuUserAction(action: PayloadAction<boolean>) {
  yield put(showMenuUser(action.payload));
}

function* onShowMenuHeaderAction(action: PayloadAction<boolean>) {
  yield put(showMenuUserHeader(action.payload));
}

function* onIsTourAction(action: PayloadAction<boolean>) {
  yield put(isTour(action.payload));
}

export function* watchOnBoarding() {
  yield takeLatest(actionTypes.ONBOARDING, updateOnBoardingAction);
  yield takeLatest(actionTypes.ONBOARDING_MENTOR, updateOnBoardingMentorAction);
  yield takeLatest(actionTypes.UPLOAD_IMAGE, updateImageAction);
  yield takeLatest(actionTypes.CLEAR_ON_BOARDING, clearAllDataOnBoardingAction);
  yield takeLatest(actionTypes.SHOW_MENU, onShowMenuUserAction);
  yield takeLatest(actionTypes.SHOW_MENU_HEADER, onShowMenuHeaderAction);
  yield takeLatest(actionTypes.IS_TOUR, onIsTourAction);
}
