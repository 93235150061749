export const formatDate = {
  fullDate: 'YYYY-MM-DD HH:mm:ss',
  fullDateNotSeconds: 'YYYY-MM-DD HH:mm',
  hoursAndMinutes: 'HHmm',
  HHmm: 'HH:mm',
  fullDateNotTime: 'YYYY-MM-DD',
  YYYYMDD: 'YYYY-M-DD',
  hhmmA: 'hh:mm A',
  ddddMMMMDoYYYY: 'dddd, MMMM Do YYYY',
  MMDDyyyy: 'MM/DD/yyyy',
  DDMMYYYY: 'DD/MM/YYYY',
  MMYY: 'MM/YY',
};
