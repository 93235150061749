import { AvailabilityResponse, UpdateAvailabilityResponse } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AvailabilityState {
  dataAvailability: AvailabilityResponse | null;
  dataUpdateAvailability: UpdateAvailabilityResponse | null;
  loading: boolean;
  error?: string;
  loadingUpdate: boolean;
  isSuccessUpdate: boolean;
}
const initialState: AvailabilityState = {
  dataAvailability: null,
  dataUpdateAvailability: null,
  loading: false,
  error: undefined,
  loadingUpdate: false,
  isSuccessUpdate: false,
};

export const AvailabilitySlice = createSlice({
  name: 'PowerClassState',
  initialState,
  reducers: {
    getAvailabilityData: (state: AvailabilityState) => {
      state.loading = true;
    },
    getAvailabilitySuccess: (state: AvailabilityState, action: PayloadAction<AvailabilityResponse>) => {
      state.loading = false;
      state.dataAvailability = action.payload;
    },
    getAvailabilityFailure: (state: AvailabilityState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    updateAvailabilityData: (state: AvailabilityState) => {
      state.loadingUpdate = true;
    },
    updateAvailabilitySuccess: (state: AvailabilityState, action: PayloadAction<UpdateAvailabilityResponse>) => {
      state.loadingUpdate = false;
      state.isSuccessUpdate = true;
      state.dataUpdateAvailability = action.payload;
    },
    updateAvailabilityFailure: (state: AvailabilityState, action: PayloadAction<string | undefined>) => {
      state.loadingUpdate = false;
      state.isSuccessUpdate = false;
      state.error = action.payload;
    },
    clearSuccessUpdate: (state) => {
      state.isSuccessUpdate = false;
    },
  },
});

export const {
  getAvailabilityData,
  getAvailabilitySuccess,
  getAvailabilityFailure,
  updateAvailabilityData,
  updateAvailabilitySuccess,
  updateAvailabilityFailure,
  clearSuccessUpdate,
} = AvailabilitySlice.actions;

export default AvailabilitySlice.reducer;
