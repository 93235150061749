import { learningPaceApi } from '@/api/learningPace';
import { LearningPaceResponse } from '@/shared/interface';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { getLearningPace, getLearningPaceFailure, getLearningPaceSuccess } from './slice';
import { RootState } from '../store';

function* getAllLearningPaceWorker() {
  try {
    const { isFetched } = yield select((state: RootState) => state.learningPaceSlice);
    if (isFetched) {
      const res: LearningPaceResponse = yield call(learningPaceApi.getAllLearningPace);
      if (res.success && res.data) {
        yield put(getLearningPaceSuccess(res.data));
      } else {
        yield put(getLearningPaceFailure());
      }
      return;
    }
    yield put(getLearningPace());
    const res: LearningPaceResponse = yield call(learningPaceApi.getAllLearningPace);
    if (res.success && res.data) {
      yield put(getLearningPaceSuccess(res.data));
    } else {
      yield put(getLearningPaceFailure());
    }
  } catch (error) {
    yield put(getLearningPaceFailure());
  }
}

export function* watchLearningPace() {
  yield takeLatest(actionTypes.GET_LEARNING_PACE, getAllLearningPaceWorker);
}
