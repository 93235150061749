import { GenerateZoomTokenResponse, IPayloadGenerateZoom, IPayloadZoom, ZoomResponse } from '@/shared/interface';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
  joinZoomRequest,
  joinZoomRequestFailure,
  joinZoomRequestSuccess,
  generateTokenRequest,
  generateTokenRequestFailure,
  generateTokenRequestSuccess,
} from './slice';
import { PayloadAction } from '@reduxjs/toolkit';
import { JoinZoomApi } from '@/api/zoom';
interface ErrorMess {
  message: string;
  success: boolean;
}

function* MentorJoinZoom(action: PayloadAction<IPayloadZoom>) {
  try {
    yield put(joinZoomRequest());
    const res: ZoomResponse = yield call(JoinZoomApi.checkZoomJoin, action.payload);
    if (res.success) {
      yield put(joinZoomRequestSuccess(res));
    } else {
      yield put(joinZoomRequestFailure(res.message));
    }
  } catch (error) {
    yield put(joinZoomRequestFailure(error?.toString()));
  }
}

function* GenerateTokenJoinZoom(action: PayloadAction<IPayloadGenerateZoom>) {
  try {
    yield put(generateTokenRequest());
    const res: GenerateZoomTokenResponse = yield call(JoinZoomApi.generateTokenZoom, action.payload);
    if (res.success) {
      yield put(generateTokenRequestSuccess(res));
    } else {
      yield put(generateTokenRequestFailure(res.message));
    }
  } catch (error) {
    if (error && typeof error === 'object' && 'message' in error && 'success' in error) {
      const typedError = error as ErrorMess;
      yield put(generateTokenRequestFailure(typedError.message));
    } else {
      yield put(generateTokenRequestFailure(error?.toString()));
    }
  }
}

export function* watchZoom() {
  yield takeLatest(actionTypes.CHECK_ZOOM, MentorJoinZoom);
  yield takeLatest(actionTypes.GENERATE_TOKEN_ZOOM, GenerateTokenJoinZoom);
}
