import { all, fork } from 'redux-saga/effects';
import { watchProfile } from './profile/saga';
import { watchSession } from './session/saga';
import { watchEvents } from './event/saga';
import { watchResources } from './resources/saga';
import { watchSurvey } from './survey/saga';
import { watchLearning } from './learning/saga';
import { watchPowerClass } from './powerClass/saga';
import { watchCategories } from './categories/saga';
import { watchMentors } from './mentor/saga';
import { watchAvailability } from './availability/saga';
import { watchSignUp } from './signUp/saga';
import { watchOnBoarding } from './onboarding/saga';
import { watchBooking } from './booking/saga';
import { watchLearningStyles } from './learningStyles/saga';
import { watchLearningPace } from './learningPace/saga';
import { watchPlaylists } from './playlists/saga';
import { watchPayment } from './payment/saga';
import { watchComments } from './comment/saga';
import { watchWebStyle } from './webStyles/saga';
import { watchResourcesAdmin } from './adminResources/saga';
import { watchCommentsResource } from './commentResource/saga';
import { watchZoom } from './zoom/saga';
import { watchForgotPassword } from './forgotPassword/saga';
import { watchNotification } from './notification/saga';

export default function* rootSaga() {
  yield all([
    fork(watchSession),
    fork(watchProfile),
    fork(watchEvents),
    fork(watchResources),
    fork(watchSurvey),
    fork(watchLearning),
    fork(watchPowerClass),
    fork(watchCategories),
    fork(watchMentors),
    fork(watchAvailability),
    fork(watchSignUp),
    fork(watchOnBoarding),
    fork(watchBooking),
    fork(watchLearningStyles),
    fork(watchLearningPace),
    fork(watchPlaylists),
    fork(watchPayment),
    fork(watchComments),
    fork(watchWebStyle),
    fork(watchResourcesAdmin),
    fork(watchCommentsResource),
    fork(watchZoom),
    fork(watchForgotPassword),
    fork(watchNotification),
  ]);
}
