/* eslint-disable @typescript-eslint/no-explicit-any */
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducer';
import saga from './saga';
import * as actionTypes from './session/actionTypes';
// import { createLogger } from 'redux-logger';

const sagaMiddleware = createSagaMiddleware();

// const logger = createLogger({
//   collapsed: true,
// });

const persistConfig = {
  key: 'root',
  storage,
};

const rootReducer = (state: any, action: any) => {
  if (action.type === actionTypes.SIGN_OUT) {
    state = { undefined, webStyleState: state.webStyleState };
    return reducers(state, action);
  }

  return reducers(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
// const middleware = process.env.NODE_ENV !== 'production' ? [sagaMiddleware, logger] : [sagaMiddleware];
const middleware = [sagaMiddleware];

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }).concat(middleware),
});

const persistor = persistStore(store);

sagaMiddleware.run(saga);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default { store, persistor };
