import { resourceAdminApi } from '@/api/admin/resource';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { getResourcesAdmin, getResourcesAdminFailure, getResourcesAdminSuccess } from './slice';
import { ResourceResponse } from '@/shared/interface';

function* getAllResourceWorker() {
  try {
    yield put(getResourcesAdmin());
    const res: ResourceResponse = yield call(resourceAdminApi.getAll);
    if (res.success) {
      yield put(getResourcesAdminSuccess(res));
    } else {
      yield put(getResourcesAdminFailure(res.message));
    }
  } catch (error) {
    yield put(getResourcesAdminFailure(error?.toString()));
  }
}

export function* watchResourcesAdmin() {
  yield takeLatest(actionTypes.GET_ADMIN_RESOURCES, getAllResourceWorker);
}
