/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentType } from 'react';

export const lazyRetry = (
  componentImport: () => Promise<{ default: ComponentType<any> }>, // Ensure the correct type
  retries: number = 3,
  delay: number = 1000 // Time between retries
): Promise<{ default: ComponentType<any> }> => {
  const attempt = (retriesLeft: number): Promise<{ default: ComponentType<any> }> => {
    const reloadPage = () => {
      window.location.reload();
    };
    return componentImport()
      .then((module) => {
        return module; // This module must have { default: ComponentType<any> }
      })
      .catch((error) => {
        if (retriesLeft > 0) {
          // Retry after a delay
          return new Promise((resolve, reject) => {
            setTimeout(() => {
              attempt(retriesLeft - 1)
                .then(resolve)
                .catch(reject);
            }, delay);
          });
        } else {
          // No retries left, reject
          reloadPage();
          return Promise.reject(error);
        }
      });
  };

  return attempt(retries); // Start with the specified number of retries
};
