import { PowerClassApi } from './../../api/powerClass';
import {
  CompletePowerClassResponse,
  GetPowerClassResponse,
  OpenPowerClassResponse,
  PowerClassRequest,
} from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  getPowerClassData,
  getPowerClassDataSuccess,
  getPowerClassDataFailure,
  openPowerClassData,
  openPowerClassDataSuccess,
  openPowerClassDataFailure,
  completePowerClassData,
  completePowerClassDataSuccess,
  completePowerClassDataFailure,
} from './slice';
import * as actionTypes from './actionTypes';

function* getPowerClassDataRequest() {
  try {
    yield put(getPowerClassData());
    const res: GetPowerClassResponse = yield call(PowerClassApi.getPowerClassData);
    if (res.success) {
      yield put(getPowerClassDataSuccess(res));
    } else {
      yield put(getPowerClassDataFailure(res.message));
    }
  } catch (error) {
    yield put(getPowerClassDataFailure(error?.toString()));
  }
}

function* openPowerClassRequest(action: PayloadAction<PowerClassRequest>) {
  try {
    yield put(openPowerClassData());
    const res: OpenPowerClassResponse = yield call(PowerClassApi.openPowerClass, action.payload);
    if (res.success) {
      yield put(openPowerClassDataSuccess(res));
    } else {
      yield put(openPowerClassDataFailure(res.message));
    }
  } catch (error) {
    yield put(openPowerClassDataFailure(error?.toString()));
  }
}

function* completePowerClassRequest(action: PayloadAction<PowerClassRequest>) {
  try {
    yield put(completePowerClassData());
    const res: CompletePowerClassResponse = yield call(PowerClassApi?.completePowerClass, action.payload);
    if (res.success) {
      yield put(completePowerClassDataSuccess(res));
    } else {
      yield put(completePowerClassDataFailure(res.message));
    }
  } catch (error) {
    yield put(completePowerClassDataFailure(error?.toString()));
  }
}

export function* watchPowerClass() {
  yield takeLatest(actionTypes.GET_POWER_CLASS, getPowerClassDataRequest);
  yield takeLatest(actionTypes.OPEN_POWER_CLASS, openPowerClassRequest);
  yield takeLatest(actionTypes.COMPLETE_POWER_CLASS, completePowerClassRequest);
}
