import {
  AvailabilityResponse,
  MentorRequest,
  UpdateAvailabilityRequest,
  UpdateAvailabilityResponse,
} from '@/shared/interface';
import { ApiCall, endpoints } from './api';

export const AvailabilityApi = {
  getAvailability: async (data: MentorRequest): Promise<AvailabilityResponse> =>
    await ApiCall.get(`${endpoints.availability}?mentorId=${data?.mentorId}`),
  updateAvailability: async (data: UpdateAvailabilityRequest): Promise<UpdateAvailabilityResponse> =>
    (await ApiCall.put())(`${endpoints.availability}`, data),
};
