import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICard, IPayloadDeleteCard } from '../interfaces';
import {
  ConfirmStripe,
  ConfirmStripeResponse,
  CreateStripeResponse,
  IKey,
  KeyStripeResponse,
} from '@/shared/interface';

interface IInitialState {
  cards: ICard[];
  loadingAddCard: boolean;
  loadingGetAllCards: boolean;
  loadingDeleteCard: boolean;
  loadingUpdateCardDefault: boolean;
  updateCardDefaultSuccess: boolean;
  deleteCardSuccess: boolean;
  addCardError: string;
  addCardSuccess: boolean;
  getAllCardSuccess: boolean;
  getAllCardError: string;
  deleteCardError: string;
  stripeKey: IKey;
  dataCreatePayment: {
    object: string;
    created: number;
    expires_at: number;
    url: string;
  };
  getKeyStripeSuccess: boolean;
  loadingGetKeyStripe: boolean;
  errorGetKeyStripe: string;
  createStripeSuccess: boolean;
  createStripeFailure: boolean;
  loadingCreatePaymentStripe: boolean;
  errorCreatePaymentStripe: string;
  updateMentorRateSuccess: boolean;
  loadingUpdateMentorRate: boolean;
  errorUpdateMentorRate: string;
  dataUpdatePayment: {
    object: string;
    created: number;
    expires_at: number;
    url: string;
  };
  updateStripeSuccess: boolean;
  loadingUpdatePaymentStripe: boolean;
  errorUpdatePaymentStripe: string;
  dataConfirmStripe: ConfirmStripe;
  confirmStripeSuccess: boolean;
  loadingConfirmStripe: boolean;
  errorConfirmStripeStripe: string;
}

const initialState: IInitialState = {
  cards: [],
  loadingAddCard: false,
  addCardError: '',
  loadingGetAllCards: false,
  addCardSuccess: false,
  loadingDeleteCard: false,
  deleteCardSuccess: false,
  loadingUpdateCardDefault: false,
  updateCardDefaultSuccess: false,
  getAllCardError: '',
  getAllCardSuccess: false,
  deleteCardError: '',
  stripeKey: {
    intent_id: '',
    client_secret: '',
    publishable_key: '',
    setup_intent: '',
  },
  getKeyStripeSuccess: false,
  dataCreatePayment: {
    object: '',
    created: 0,
    expires_at: 0,
    url: '',
  },
  loadingGetKeyStripe: false,
  errorGetKeyStripe: '',
  loadingCreatePaymentStripe: false,
  errorCreatePaymentStripe: '',
  createStripeSuccess: false,
  createStripeFailure: false,
  updateMentorRateSuccess: false,
  loadingUpdateMentorRate: false,
  errorUpdateMentorRate: '',
  dataUpdatePayment: {
    object: '',
    created: 0,
    expires_at: 0,
    url: '',
  },
  updateStripeSuccess: false,
  loadingUpdatePaymentStripe: false,
  errorUpdatePaymentStripe: '',
  dataConfirmStripe: {
    id: '',
    object: '',
    amount: 0,
    amount_capturable: 0,
    amount_details: {
      tip: {},
    },
    amount_received: 0,
    application: null,
    application_fee_amount: 0,
    automatic_payment_methods: null,
    canceled_at: null,
    cancellation_reason: null,
    capture_method: '',
    client_secret: '',
    confirmation_method: '',
    created: 1711438768,
    currency: '',
    customer: '',
    description: null,
    invoice: null,
    last_payment_error: null,
    latest_charge: '',
    livemode: false,
    metadata: {
      mentee_email: '',
      mentee_id: '',
      mentor_email: '',
      mentor_id: '',
      mentor_name: '',
      rate: '',
    },
    next_action: {
      redirect_to_url: {
        return_url: '',
        url: '',
      },
      type: '',
    },
    on_behalf_of: null,
    payment_method: '',
    payment_method_configuration_details: null,
    payment_method_options: {
      card: {
        installments: null,
        mandate_options: null,
        network: null,
        request_three_d_secure: '',
      },
    },
    payment_method_types: [''],
    processing: null,
    receipt_email: '',
    review: null,
    setup_future_usage: null,
    shipping: null,
    source: null,
    statement_descriptor: null,
    statement_descriptor_suffix: null,
    status: '',
    transfer_data: {
      destination: '',
    },
    transfer_group: '',
  },
  confirmStripeSuccess: false,
  loadingConfirmStripe: false,
  errorConfirmStripeStripe: '',
};

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    getAllCardsPayment: (state) => {
      state.loadingGetAllCards = true;
      state.getAllCardSuccess = false;
    },
    getAllCardsPaymentSuccess: (state, action: PayloadAction<ICard[]>) => {
      state.loadingGetAllCards = false;
      state.cards = action.payload.sort(
        (a, b) => new Date(String(b.created_at)).getTime() - new Date(String(a.created_at)).getTime()
      );
      state.getAllCardSuccess = true;
    },
    getAllCardsPaymentFailure: (state, action: PayloadAction<string>) => {
      state.loadingGetAllCards = false;
      state.getAllCardSuccess = false;
      state.getAllCardError = action.payload;
    },
    clearGetAllCardPayment: (state) => {
      state.loadingGetAllCards = false;
      state.getAllCardSuccess = false;
      state.getAllCardError = '';
      state.cards = [];
    },
    addCardPayment: (state) => {
      state.loadingAddCard = true;
      state.addCardSuccess = false;
    },
    addCardPaymentSuccess: (state, action: PayloadAction<ICard>) => {
      state.loadingAddCard = false;
      state.addCardSuccess = true;
      let newCard: ICard;
      if (state.cards.length <= 0) {
        newCard = { ...action.payload, default: true };
      } else {
        newCard = { ...action.payload };
      }
      state.cards = [newCard, ...state.cards];
    },
    addCardPaymentFailure: (state, action: PayloadAction<string>) => {
      state.loadingAddCard = false;
      state.addCardSuccess = false;
      state.addCardError = action.payload || 'Card payment add failed';
    },
    deleteCardPayment: (state) => {
      state.deleteCardError = '';
      state.loadingDeleteCard = true;
      state.deleteCardSuccess = false;
    },
    deleteCardPaymentSuccess: (state, action: PayloadAction<IPayloadDeleteCard>) => {
      state.loadingDeleteCard = false;
      state.deleteCardSuccess = true;
      state.cards = state.cards.filter((item) => item.payment_method_id !== action.payload.payment_method_id);
    },
    deleteCardPaymentFailure: (state, action: PayloadAction<string>) => {
      state.loadingDeleteCard = false;
      state.deleteCardSuccess = false;
      state.deleteCardError = action.payload;
    },
    updateCardPaymentDefault: (state) => {
      state.loadingUpdateCardDefault = true;
      state.updateCardDefaultSuccess = false;
    },
    updateCardPaymentDefaultSuccess: (state) => {
      state.loadingUpdateCardDefault = false;
      state.updateCardDefaultSuccess = true;
    },
    updateCardPaymentDefaultFailure: (state) => {
      state.loadingUpdateCardDefault = false;
      state.updateCardDefaultSuccess = false;
    },
    clearAddCardError: (state) => {
      state.addCardError = '';
    },
    clearAddCardSuccess: (state) => {
      state.addCardSuccess = false;
    },
    clearDeleteCardSuccess: (state) => {
      state.deleteCardSuccess = false;
    },
    clearUpdateCardDefaultSuccess: (state) => {
      state.updateCardDefaultSuccess = false;
    },
    getKeyStripe: (state) => {
      state.loadingGetKeyStripe = true;
      state.errorGetKeyStripe = '';
    },
    getKeyStripeSuccess: (state, action: PayloadAction<KeyStripeResponse>) => {
      state.loadingGetKeyStripe = false;
      state.stripeKey = action.payload?.data;
      state.getKeyStripeSuccess = true;
    },
    getKeyStripeFailure: (state, action: PayloadAction<string>) => {
      state.loadingGetKeyStripe = false;
      state.errorGetKeyStripe = action.payload;
      state.getKeyStripeSuccess = false;
    },
    clearKeyStripe: (state) => {
      state.stripeKey = {
        intent_id: '',
        client_secret: '',
        publishable_key: '',
        setup_intent: '',
      };
      state.loadingGetKeyStripe = false;
      state.getKeyStripeSuccess = false;
      state.errorGetKeyStripe = '';
    },
    createPaymentStripe: (state) => {
      state.loadingCreatePaymentStripe = true;
      state.createStripeFailure = false;
    },
    createPaymentSuccess: (state, action: PayloadAction<CreateStripeResponse>) => {
      state.loadingCreatePaymentStripe = false;
      state.dataCreatePayment = action.payload?.data;
      state.createStripeSuccess = true;
      state.createStripeFailure = false;
    },
    createPaymentFailure: (state, action: PayloadAction<string>) => {
      state.loadingCreatePaymentStripe = false;
      state.errorGetKeyStripe = action.payload;
      state.createStripeSuccess = false;
      state.createStripeFailure = true;
    },
    clearCreatePaymentStripe: (state) => {
      state.dataCreatePayment = {
        object: '',
        created: 0,
        expires_at: 0,
        url: '',
      };
      state.loadingCreatePaymentStripe = false;
      state.createStripeSuccess = false;
    },
    mentorRateRequest: (state) => {
      state.loadingUpdateMentorRate = true;
    },
    mentorRateRequestSuccess: (state) => {
      state.loadingUpdateMentorRate = false;
      state.updateMentorRateSuccess = true;
    },
    mentorRateRequestFailure: (state, action: PayloadAction<string>) => {
      state.loadingUpdateMentorRate = false;
      state.errorUpdateMentorRate = action.payload;
      state.updateMentorRateSuccess = false;
    },
    clearMentorRateRequest: (state) => {
      state.errorUpdateMentorRate = '';
      state.loadingUpdateMentorRate = false;
      state.updateMentorRateSuccess = false;
    },
    updatePaymentStripe: (state) => {
      state.loadingUpdatePaymentStripe = true;
    },
    updatePaymentSuccess: (state, action: PayloadAction<CreateStripeResponse>) => {
      state.loadingUpdatePaymentStripe = false;
      state.dataUpdatePayment = action.payload?.data;
      state.updateStripeSuccess = true;
    },
    updatePaymentFailure: (state, action: PayloadAction<string>) => {
      state.loadingUpdatePaymentStripe = false;
      state.errorGetKeyStripe = action.payload;
      state.updateStripeSuccess = false;
    },
    clearUpdatePaymentStripe: (state) => {
      state.dataUpdatePayment = {
        object: '',
        created: 0,
        expires_at: 0,
        url: '',
      };
      state.loadingUpdatePaymentStripe = false;
      state.updateStripeSuccess = false;
    },
    confirmStripeRequest: (state) => {
      state.loadingConfirmStripe = true;
    },
    confirmStripeRequestSuccess: (state, action: PayloadAction<ConfirmStripeResponse>) => {
      state.loadingConfirmStripe = false;
      state.confirmStripeSuccess = true;
      state.dataConfirmStripe = action.payload?.data;
    },
    confirmStripeRequestFailure: (state, action: PayloadAction<string>) => {
      state.loadingConfirmStripe = false;
      state.errorConfirmStripeStripe = action.payload;
      state.confirmStripeSuccess = false;
    },
    clearConfirmStripeRequest: (state) => {
      state.errorConfirmStripeStripe = '';
      state.loadingConfirmStripe = false;
      state.confirmStripeSuccess = false;
      state.dataConfirmStripe = {
        id: '',
        object: '',
        amount: 0,
        amount_capturable: 0,
        amount_details: {
          tip: {},
        },
        amount_received: 0,
        application: null,
        application_fee_amount: 0,
        automatic_payment_methods: null,
        canceled_at: null,
        cancellation_reason: null,
        capture_method: '',
        client_secret: '',
        confirmation_method: '',
        created: 1711438768,
        currency: '',
        customer: '',
        description: null,
        invoice: null,
        last_payment_error: null,
        latest_charge: '',
        livemode: false,
        metadata: {
          mentee_email: '',
          mentee_id: '',
          mentor_email: '',
          mentor_id: '',
          mentor_name: '',
          rate: '',
        },
        next_action: {
          redirect_to_url: {
            return_url: '',
            url: '',
          },
          type: '',
        },
        on_behalf_of: null,
        payment_method: '',
        payment_method_configuration_details: null,
        payment_method_options: {
          card: {
            installments: null,
            mandate_options: null,
            network: null,
            request_three_d_secure: '',
          },
        },
        payment_method_types: [''],
        processing: null,
        receipt_email: '',
        review: null,
        setup_future_usage: null,
        shipping: null,
        source: null,
        statement_descriptor: null,
        statement_descriptor_suffix: null,
        status: '',
        transfer_data: {
          destination: '',
        },
        transfer_group: '',
      };
    },
  },
});

export const {
  getAllCardsPayment,
  getAllCardsPaymentFailure,
  getAllCardsPaymentSuccess,
  addCardPayment,
  addCardPaymentFailure,
  addCardPaymentSuccess,
  deleteCardPayment,
  deleteCardPaymentFailure,
  deleteCardPaymentSuccess,
  updateCardPaymentDefault,
  updateCardPaymentDefaultFailure,
  updateCardPaymentDefaultSuccess,
  clearAddCardError,
  clearAddCardSuccess,
  clearDeleteCardSuccess,
  clearUpdateCardDefaultSuccess,
  getKeyStripe,
  getKeyStripeSuccess,
  getKeyStripeFailure,
  createPaymentStripe,
  createPaymentSuccess,
  createPaymentFailure,
  clearKeyStripe,
  clearCreatePaymentStripe,
  mentorRateRequest,
  mentorRateRequestSuccess,
  mentorRateRequestFailure,
  clearMentorRateRequest,
  updatePaymentStripe,
  updatePaymentSuccess,
  updatePaymentFailure,
  clearUpdatePaymentStripe,
  confirmStripeRequest,
  confirmStripeRequestSuccess,
  confirmStripeRequestFailure,
  clearConfirmStripeRequest,
  clearGetAllCardPayment,
} = paymentSlice.actions;

export default paymentSlice.reducer;
