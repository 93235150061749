import { CommentResourceApi, IParamsGetAllComments } from '@/api/commentResource';
import { CommentsResponse } from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { getCommentsFailure, getCommentsList, getCommentsSuccess } from './slice';

function* getAllCommentsWorker(action: PayloadAction<IParamsGetAllComments>) {
  try {
    yield put(getCommentsList());
    const res: CommentsResponse = yield call(CommentResourceApi.getCommentsList, action.payload);
    if (res.success) {
      yield put(getCommentsSuccess(res));
    } else {
      yield put(getCommentsFailure(res.message));
    }
  } catch (error) {
    yield put(getCommentsFailure(error?.toString()));
  }
}

export function* watchCommentsResource() {
  yield takeLatest(actionTypes.GET_All_COMMENTS_RESOURCE, getAllCommentsWorker);
}
