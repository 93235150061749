import { NotificationsApi } from '@/api/notification';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import { GetNotificationsResponse, ReadNotificationsResponse } from '../interfaces';
import * as actionTypes from './actionTypes';
import {
  getNotification,
  getNotificationFailure,
  getNotificationSuccess,
  readNotificationFailure,
  readNotificationSuccess,
} from './slice';

function* notificationRequest() {
  try {
    yield put(getNotification());
    const res: GetNotificationsResponse = yield call(NotificationsApi.getNotifications);
    if (res.success) {
      yield put(getNotificationSuccess(res));
    } else {
      yield put(getNotificationFailure(res.message));
    }
  } catch (error) {
    yield put(getNotificationFailure(error?.toString()));
  }
}

function* readNotificationWorker(action: PayloadAction<string>) {
  try {
    yield put(getNotification());
    const res: ReadNotificationsResponse = yield call(NotificationsApi.readNotification, action.payload);
    if (res.success) {
      yield put(readNotificationSuccess());
    } else {
      yield put(readNotificationFailure(res.message));
    }
  } catch (error) {
    yield put(readNotificationFailure(error?.toString()));
  }
}

export function* watchNotification() {
  yield takeLatest(actionTypes.GET_NOTIFICATION, notificationRequest);
  yield takeLatest(actionTypes.READ_NOTIFICATION, readNotificationWorker);
}
