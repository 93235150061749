export default {
  common: {},
  header: {
    login: 'LOG IN',
    myStory: 'MY STORY',
    workWithMe: 'WORK WITH ME',
    theCollective: 'WOMEN LOVE TECH',
    blog: 'BLOG',
    media: 'MEDIA',
    contact: 'CONTACT',
    theWomenLoveTech: 'WOMEN LOVE TECH',
    dashBoard: 'DASHBOARD',
    theWomen: 'WOMEN LOVE TECH',
  },
  footer: {
    slogan:
      'Blooming Success in Every Pixel. Cultivate your digital presence with our transformative services, where innovation meets aspiration.',
    explore: 'Explore',
    events: 'Events',
    profile: 'Profile',
    support: 'Support',
    content: 'Content',
    articles: 'Articles',
    webinars: 'Webinars',
    videos: 'Videos',
    bookAMentor: 'Book a Mentor',
    resources: 'Resources',
    surveys: 'Surveys',
    calculators: 'Calculators',
    copyright: '@ Copyright Lorem Ipsum',
    privacyPolicy: 'Privacy Policy',
    termsOfService: 'Website Terms of Service',
    termsAndConditions: 'Terms & Conditions',
    womenLoveTech: 'Women Love Tech',
    lotusCollective: 'Women Love Tech',
    codeOfConduct: 'Code of Conduct',
    ctaTitleOne: 'Discover Other Ways To Learn',
    ctaTitleTwo: 'Revisit Your Playlist',
    ctaSubTitleOne:
      'Embrace enriching experiences at our events. Connect, learn, and grow with each encounter. Your adventure into wellness starts here.',
    ctaSubTitleTwo: 'Saved a resource for later to check out? View and manage your resources.',
    powerBy: 'Powered by',
    elladex: 'Elladex',
    mySavedList: 'My saved list',
    women: 'Women Love Tech',
  },
  home: {
    title: 'Hi Claire – We’ve got you covered',
    welcome1: 'Welcome! Take a look around–',
    welcome2: 'Get this and more when you\nbecome a Stella member.',
    buttonFindOutMore: 'Find Out More',
    addANewPolicy: 'Add a new policy',
    titleExplore: 'We’re more than just insurance.\n Level up with',
    wellness: 'Wellness',
    financialWellbeing: 'Financial wellbeing',
    careerGrowth: 'Career Growth',
    buttonExplore: 'Explore My Stelladex',
    whatOn: 'What’s On',
    learnMore: 'learn More',
    buttonListen: 'Listen Now',
    getCovered: 'Get Covered',
    titleLife: 'Live a life of impact',
    coachingAndMentorShip: 'Coaching & mentorship for ambitious women with grit and grace',
    showMeHowTitleButton: 'Show Me How',
    purposeTitleSub: 'Discover your',
    purposeTitleMain: 'purpose',
    purposeReWriteDescription: '(Re)write your successs story and live unapologectically',
    feedTitle: 'feeling stuck or unfullfilled',
    feedDespiteDescription:
      " Despite having achieved greate success on the surface, you can't shake the feeling there should be more. More happiness freedom, joy, success, passion, fullfilment, purpose",
    feedTimeBreakDown:
      "It's time to break down the barrier, limmiting beliefs android old narratives that are keeping you playing",
    feedTimeUnleash: "It's time to unleash your potential.",
    feedBtnReady: "I'm Ready",
    deepFullFillTitle: 'Deep fullfillment',
    deppFullFillIsAbout: 'is about',
    deepFullFillMoreCareer: 'more than career success.',
    deppFullFillFindingPurpose:
      "It's about finding purpose and meaning in your life, and having the courage to pursue it to posibely impact the lives of others.",
    deepFullFillHiThere: 'Hi there',
    deepFullFillAuthorName: "I'm justine",
    deepFullFillBoxDescription:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla suscipit nec enim id dignissim. Vivamus fringilla sapien ac finibus mattis.',
    deepFullFillMenuText: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
    deepFullFillBoxFooterText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla suscipit nec enim id dignissim. Vivamus fringilla sapien ac finibus mattis. Morbi id orci nec nisi iaculis efficitur.',
    workWithMeTitle: 'Work with me',
    workStepOneTitle: 'One-time Clarity',
    workStepOneDescription:
      'Need help right now ? My 3-hour private coaching intansive is desigred to give you in-the-moment help to start mapping a new path foward towards a more fullfilled and authentic life',
    workStepTwoTitle: 'Ongoing 1:1 Mentorship',
    workStepTwoDescription:
      'For lasting tranformation , ongoing personalised coaching will support. encounrage and challenge you to realise your purpose unleash your potential and write your future',
    workStepThreeTitle: 'Empowerment Workshops & Retreats',
    workStepThreeDescription:
      'Embrace self-discovery while exploring challenges with like-minded people on topics including coutage building shame reslience and uncovering the power of vulenability',
    workStepFourTitle: 'Women Love Tech',
    workStepFourDescription:
      'Human being are wired for connection . We are happier together as a collective. Positive system on change within a family, an organisation or a community attends to the needs of the individual and the collective. we offer inititives focused on positive connections, authenic leadership and growth as collectives',
    myGiftTitle: 'MY GIFT TO YOU',
    myGiftLabel: 'The Woman of Impact Guide for Becoming the Best Version of Yourself',
    myGiftQuestion:
      'Do you want to discover your unique strengths and purpose and make a podlive impact in your community and beyond?',
    myGiftBtnGetCode: 'GET THE FREE GUIDE',
    coachingTitle: 'Will coaching work for me?',
    coachingLostMySelfTitle: `I'VE LOST MYSELF AND MY SENSE OF PURPOSE IN LIFE. I FEEL NUMB. HOW DO I BECOME "ME" ?`,
    coachingLostMySelfDescription:
      "We'll create actionable steps to move you form your head to your heart and back to becoming you.",
    coachingFearTitle: "FEAR IS KEEPING ME STUCK. I KNOW I'M MEANT FOR MORE, BUT I CAN'T SEEM TO GET THERE",
    coachingFearDescription:
      'I will support you to step out of comfort and into crungs to be all that you dream on being.',
    coachingBurnoutTitle:
      "I DON'T WANT TO WAIT FOR BURNOUT BEFORE MAKING MY NEXT MOVE, I NEED HELP DISCOVERING WHAT'S NEXT.",
    coachingBurnoutDescription:
      "I'll challenge you in discover a future true to your passions and gifts and write new success.",
  },
  topics: {
    developConfidence: 'Develop my confidence',
    careerAdvice: 'Career advice',
    goalSetting: 'Goal setting',
    industrySpecific: 'Industry specific advice',
    improveJob: 'Improve job effectiveness',
    roleUpskilling: 'Role upskilling or promotion',
  },
  bookingMentorProfile: {
    startSession: 'JOIN SESSION',
    cancelSession: 'CANCEL SESSION',
    addToCalendar: 'ADD TO CALENDAR',
    mentorSession: 'Stella: Mentor Session with',
  },
  bookingResult: {
    findAnotherTime: 'FIND ANOTHER TIME',
    findAnotherMentor: 'FIND ANOTHER MENTOR',
    bookAgain: 'BOOK AGAIN',
    iWouldLike: 'I would like to discuss –',
    weDiscussed: 'We discussed –',
    focusAreas: 'Focus areas',
    bookingStatus: 'BOOKING STATUS',
    discussPlaceholder: 'Add an agenda, your questions or anything in particular you"d like to discuss',
    bookingAccepted: "You've already accepted this booking",
    bookingRejected: "You've already rejected this booking",
    bookingCompleted: "You've already completed this booking",
    noneData: "Booking doesn't exist",
    optionAppleCalendar: 'Apple',
    optionGoogleCalendar: 'Google',
    requestSent: 'Request Sent!',
    thisIs: 'This is not a confirmed booking. Check the Pending tab on',
    myBooking: 'My Booking',
    toCheck: 'to check the status of this request',
    acceptRequest: 'Your card will only be charged once our mentor accepts your request',
  },
  confirmRequest: {
    update: 'Update',
  },
  mentorCalling: {
    mentoringWith: 'Mentoring with ',
    leaveSession: 'LEAVE SESSION',
    cancel: 'CANCEL',
    endIn: 'Your session will end in 10 min',
    titlePermissionBoth: 'Camera and Audio permission',
    messagePermissionBoth:
      'Camera and Audio permission is blocked in my device. Please open settings and allow the app to access camera and audio',
    titlePermission: '{{0}} permission',
    messagePermission:
      '{{0}} permission is blocked in my device. Please open settings and allow the app to access {{0}}',
    openSettings: 'OPEN SETTINGS',
  },
  eventPage: {
    loadMore: 'Load More',
    title: 'My Stelladex',
    wellNess: 'Wellness',
    finance: 'Finance',
    career: 'Career',
    mentoring: 'Mentoring',
    events: 'EVENTS',
    subTitle:
      'Embrace enriching experiences at our events. Connect,\n learn, and grow with each encounter. Your adventure into\n wellness starts here.',
    newSubTitle: 'Elevate your mindset, expand your capabilities and embark on a transformative journey.',
    newSubTitle_2024_11: 'Browse our upcoming events designed to elevate your mindset, broaden your skillset, and enhance your expertise, empowering you on your transformative journey.',
    upcomingEvents: 'Upcoming Events',
    upcomingEventsDescription: `Don't miss out on our exciting events that will boost your business and personal growth. Join us for transformative sessions with business leaders and tech experts who will share their insights and experience.`,
    attendedEvents: 'Attended Events',
    attendedEventsDescription:
      "Celebrate the journey you've embarked on with our past events. Each experience has\n contributed to your journey of empowerment and wellness. Treasure these memories as\n foundations of your growth.",
    register: 'Register',
    attendees: 'Attendees',
    paragraph1:
      'Embark on an enchanting journey through an evening dedicated to fostering connections, self-reflection, and the exploration of diverse cultures. Immerse yourself in a night of celebration, where narratives, customs, and heritage gracefully converge.',
    paragraph2: `Participate in a heartwarming and enriching experience guided by a captivating Lebanese mother-daughter duo. They will skillfully intertwine tales, creating a sumptuous feast of tradition and interconnectedness. Attend this special event to truly be seen, heard, and cherished, as we collectively revel in the emotional celebration of women's stories.`,
    subTitleEventDetails:
      'Discover new horizons with our upcoming events. Engage in transformative sessions that enrich and inspire. Your next step in personal growth awaits.',
    price: 'Price',
    backTitle: 'Events / Event Details',
    discoverMore: 'DISCOVER MORE',
    myEventBookings: 'MY EVENT BOOKINGS',
    filterBy: 'FILTER BY',
    filter: 'FILTER',
    search: 'SEARCH',
    type: 'TYPE',
    topic: 'TOPIC',
    explorePastEvents: 'Explore Past Events',
    leftFooterDescription:
      'Embrace enriching experiences at our events. Connect, learn, and grow with each encounter. Your adventure into wellness starts here.',
    leftFooterButtonText: 'Past events',
    manageMyBookings: 'Manage My Bookings',
    rightFooterDescription: 'View and manage your event bookings. You’re in total control.',
    rightFooterButtonText: 'My event bookings',
    backToUpComingEvent: 'Back to upcoming events',
    saveToPlaylist: 'Save to playlist',
    pastEvents: 'Past Events',
    doesNotHaveEvent: 'Does not have any events',
    searchByTitleEvent: 'Search by title of the event',
    creditOrDebitCard: 'Credit or debit card',
    orderSummary: 'ORDER SUMMARY',
  },
  eventDetail: {
    paymentInfo: 'Payment Information',
    eventNotExist: `Event doesn't exist`,
    industry: 'Industry',
    location: 'Location',
    dateAndTime: 'Date and time',
    price: 'Price',
    backToUpComingEvent: 'Back to upcoming events',
    backToPastEvent: 'Back to past events',
    saveToPlaylist: 'Save to playlist',
    linkedIn: 'LINKEDIN',
    overView: 'Overview',
    over3To6Month: 'Over 3 or 6 months working together, we will:',
    registerYourAttendance: 'Register Your Attendance',
    sessionTimes: 'Session Times',
    yearsOfExperience: 'Years of Experience',
    level: 'Level',
    expertise: 'Expertise',
    requestBooking: 'Request Booking',
    discussPlaceholder: "Add an agenda, your questions or anything in particular you'd like to discuss",
    yearsExperience: 'years experience in industry',
    perSession: 'per session',
    sessionsCompleted: 'sessions completed',
    speakers: 'Speakers',
    details: 'Details',
    agenda: 'Agenda',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    checkBoxConfirm: 'My Ticket information is the same as my Billing information',
    nameOnCard: 'Name on Card',
    cardNumber: 'Card Number',
    expirationDate: 'Expiration Date',
    checkBoxConfirmPayment: 'Save payment details to your account',
    cvv: 'CVV',
    thanksForYourOrder: 'Thanks for your order!',
    lookForward: 'We look forward to see you at this event.',
    addToCalendar: 'ADD TO CALENDAR',
    viewDiscussionBoard: 'VIEW DISCUSSION BOARD',
    thisEventHappen: 'This event has already happened!',
    viewUpcomingEvent: 'VIEW UPCOMING EVENT',
    billingInformation: 'Billing information',
    orderSummary: 'Order summary',
    eventCancelled: 'Event has been cancelled',
    eventNotFound: `Event doesn't exist`,
  },
  pastEvent: {
    loadMore: 'Load More',
    title: 'My Stelladex',
    wellNess: 'Wellness',
    finance: 'Finance',
    career: 'Career',
    mentoring: 'Mentoring',
    events: 'EVENTS',
    subTitle:
      'Embrace enriching experiences at our events. Connect,\n learn, and grow with each encounter. Your adventure into\n wellness starts here.',
    newSubTitle: 'Elevate your mindset, expand your capabilities and embark on a transformative journey.',
    newSubTitle_2024_11_08: 'Explore our past events to revisit inspiring sessions that elevated mindsets, expanded skillsets, and fostered transformative journeys, showcasing the impact of our community.',
    upcomingEvents: 'Upcoming Events',
    upcomingEventsDescription:
      'Discover new horizons with our upcoming events. Engage in\n transformative sessions that enrich and inspire. Your next step\n in personal growth awaits.',
    attendedEvents: 'Attended Events',
    attendedEventsDescription:
      "Celebrate the journey you've embarked on with our past events. Each experience has\n contributed to your journey of empowerment and wellness. Treasure these memories as\n foundations of your growth.",
    register: 'Register',
    attendees: 'Attendees',
    paragraph1:
      'Embark on an enchanting journey through an evening dedicated to fostering connections, self-reflection, and the exploration of diverse cultures. Immerse yourself in a night of celebration, where narratives, customs, and heritage gracefully converge.',
    paragraph2: `Participate in a heartwarming and enriching experience guided by a captivating Lebanese mother-daughter duo. They will skillfully intertwine tales, creating a sumptuous feast of tradition and interconnectedness. Attend this special event to truly be seen, heard, and cherished, as we collectively revel in the emotional celebration of women's stories.`,
    subTitleEventDetails:
      'Discover new horizons with our upcoming events. Engage in transformative sessions that enrich and inspire. Your next step in personal growth awaits.',
    price: 'Price',
    backTitle: 'Events / Event Details',
    discoverMore: 'DISCOVER MORE',
    myEventBookings: 'MY EVENT BOOKINGS',
    filterBy: 'FILTER BY',
    filter: 'FILTER',
    search: 'SEARCH',
    type: 'TYPE',
    topic: 'TOPIC',
    exploreUpcomingEvent: 'Explore Upcoming Events',
    leftFooterDescription:
      'Embrace enriching experiences at our events. Connect, learn, and grow with each encounter. Your adventure into wellness starts here.',
    leftFooterButtonText: 'Upcoming events',
    manageMyBookings: 'Manage My Bookings',
    rightFooterDescription: 'View and manage your event bookings. You’re in total control.',
    rightFooterButtonText: 'My event bookings',
    backToUpComingEvent: 'Back to upcoming events',
    saveToPlaylist: 'Save to playlist',
    pastEvents: 'Past Events',
    discussionBoard: 'Discussion Board',
    shareYourThoughts: 'Share your thoughts and takeaways!',
    comments: 'Comments',
    startTyping: 'Start typing...',
  },
  bookingDetails: {
    engage: 'Engage / Your Booking',
    btnAcceptTitle: 'Accept',
    btnRejectTitle: 'Reject',
  },
  finance: {
    title: 'Finance',
    buildYour: 'Build your financial literacy and grow your wealth',
    whyBeing: 'Why being part of Stella is so much more than insurance',
    loadMore: 'Load More',
    search: 'Search',
    filter: 'Filter',
    explore: 'Explore more',
    nextUp: 'Next up',
    seeMore: 'See More',
  },
  moreUpComing: {
    more: 'More Up Coming Events',
    learnMore: 'Learn More',
  },
  menu: {
    dashBoard: 'DASHBOARD',
    learn: 'LEARN',
    mentors: 'MENTORS',
    about: 'ABOUT',
    aboutUs: 'ABOUT US',
    newsFeed: 'NEWS FEED',
    content: 'CONTENT',
    support: 'SUPPORT',
    signOut: 'SIGN OUT',
    events: 'EVENTS',
    money: 'MONEY',
    survey: 'SURVEY',
    powerClasses: 'POWERCLASSES',
    profile: 'Profile',
    elevate: 'Elevate',
    engage: 'Engage',
    messages: 'MESSAGES',
    logIn: 'LOGIN',
  },
  childrenMenuLogged: {
    newsFeed: 'NEWSFEED',
    learnByActivity: 'LEARN BY ACTIVITY',
    readArticles: 'READ | ARTICLES',
    watchVideos: 'WATCH | VIDEOS',
    writeJournal: 'WRITE | JOURNAL',
    listenPodcasts: 'LISTEN | PODCASTS',
    learnByTopic: 'LEARN BY TOPIC',
    leadership: 'LEADERSHIP',
    wellness: 'WELLNESS',
    diversity: 'DIVERSITY',
    finance: 'FINANCE',
    business: 'BUSINESS',
    findMentors: 'FIND MENTORS',
    myMentorBookings: 'MY MENTOR BOOKINGS',
    upcomingEvents: 'UPCOMING EVENTS',
    myEventBookings: 'MY EVENT BOOKINGS',
    passEvents: 'PAST EVENTS',
    theLotusCollective: 'THE LOTUS COLLECTIVE',
    womenLoveTech: 'WOMEN LOVE TECH',
    myStory: 'MY STORY',
    faq: 'FAQ',
    contactUs: 'CONTACT US',
    myBooking: 'MY BOOKING',
  },

  login: {
    title: 'Log in',
    emailLabel: 'Email',
    passwordLabel: 'Password',
    forgotPassword: 'Forgot password?',
    doNotHaveAccount: `Don't have an account yet?`,
    signUp: 'SIGN UP',
    emailAddress: 'Email Address',
    name: 'Name',
    location: 'Location (Country, City)',
    btnLoginText: 'LOG IN',
    orText: 'OR',
  },
  dashBoard: {
    title: 'Dashboard',
    wantMore: 'Want More?',
    videoInfoCardCarousel: 'Video',
    onBoardStepOneDescription:
      'The Daily Streak keeps you motivated by tracking and celebrating your consistent engagement, rewarding you for completing learning activities every week.',
    onBoardStepTwoDescription:
      'The My Progress feature  highlights weekly tasks and completion progress, empowering you to stay organised and achieve your goals step by step.',
    onBoardStepThreeDescription:
      'Here under My Bookings, you will find a snapshot of your upcoming Mentor sessions and Event bookings.',
    onBoardStepFourDescription:
      'Scroll through the latest insights, new events and mentors readily available on the Newsfeed.',
    onBoardStepFiveDescription:
      'Ready to start learning? You can jump straight into an activity through these shortcuts!',
    onBoardStepSixDescription:
      'The navigation menu serves as your compass to help you effortlessly explore the platform by providing access to learning resources, mentors, events, and help and support if you need it!',
    onBoardStepSevenDescription:
      'The secondary menu under your profile conveniently consolidates all aspects related to your profile, progress, bookings, and settings, offering a streamlined and accessible hub for managing your personal learning journey.',
    welcome: 'A Welcome Message From Robyn Foyster',
    welcomeContent: 'We’re thrilled to have you here.',
    welcomeSubContentOne: 'A message from Justine Campbell',
    welcomeSubContentTwo:
      'We are delighted to welcome you to WLT Academy. This platform is a place where you can live, learn, grow, and connect with each other, including the WLT sisterhood, mentors, coaches, and brands. We look forward to building our futures together.',
    helloTitle: 'Say hello to your own Dashboard!',
    helloContent:
      'Here you will find a snapshot of all the important things including your progress, bookings, and what’s new.',
    helloSubContent: 'Let’s take a look!',
    filterBy: 'Filter by:',
    search: 'Search:',
    articlesTitle: 'Read | Articles',
    videosTitle: 'Watch | Videos',
    podcastsTitle: 'Game Changers champions the world’s most inspiring entrepreneurs and leaders.',
    journalTitle: 'Write | Journal',
    newsfeedTitle: 'Newsfeed',
    articlesSubTitle: 'Be inspired by female role models and find out the latest news to supercharge your career.',
    newsfeedSubTitle_2024_11_08: 'Stay inspired with fresh insights from female leaders and trailblazers across industries, empowering you to learn, grow, and drive your career forward.',
    articlesSubTitle_2024_11_08: 'Explore empowering articles featuring fresh perspectives, insights, and stories from inspiring women leaders to fuel your growth and career success.',
    journalSubTitle_2024_11_08: 'Start your journey of self-reflection with guided journaling, designed to help you capture thoughts, explore ideas, and gain clarity as you reflect on each day.',
    videosSubTitle_2024_11_08: 'Discover practical videos and inspiring interviews with women leaders, designed to equip you with skills, insights, and motivation to advance your career.',
    articlesFilterText:
      'Dive into insightful articles crafted to empower and inspire personal and professional growth.',
    commentInput: 'Start typing...',
    dailyStreak: 'Daily Streak',
    youDoingGreat: "You're doing great",
    youDontHaveBookings: "You don't have any bookings",
    next: 'NEXT',
    done: 'DONE',
    previousStep: 'PREVIOUS STEP',
    learn: 'Learn',
    myBooking: 'My Booking',
  },
  myPlaylist: {
    title: 'My Playlist',
    continue: 'CONTINUE WATCHING',
    next: 'NEXT UP',
    watch: 'WATCHED',
  },
  component: {
    filter: 'FILTER',
    viewDetails: 'View Details',
    search: 'SEARCH',
    viewMore: 'View More',
  },
  findMentor: {
    title: 'Find Mentors',
    match: 'MATCH WITH A MENTOR',
  },
  myBooking: {
    title: 'My Bookings',
    upcoming: 'UPCOMING',
    completed: 'COMPLETED',
    seeAll: 'See all booking >>',
    requested: 'REQUESTED',
    pending: 'PENDING',
    mangeBooking: 'MANAGE BOOKING',
    joinNow: 'JOIN NOW',
    mentor: 'mentor',
    event: 'event',
    pendingBooking: 'Pending booking',
    upcomingBooking: 'Upcoming booking',
    pendingEvent: 'Pending event',
    upcomingEvent: 'Upcoming event',
    noUpcomingBookings: 'There are no upcoming bookings. Explore our pending booking.',
    noPendingBookings: 'There are no pending bookings. Explore our upcoming booking.',
    noCompletedBookings: 'There are no completed bookings. Explore our upcoming booking.',
    noUpcomingBookingsEvent: 'There are no upcoming event bookings. Explore our pending event booking.',
    noPendingBookingsEvent: 'There are no pending event bookings. Explore our upcoming event booking.',
    noCompletedBookingsEvent: 'There are no completed event bookings. Explore our upcoming event booking.',
    changeBooking: 'CHANGE BOOKING',
    acceptBooking: 'ACCEPT BOOKING',
    cancelBooking: 'CANCEL BOOKING',
    cancel: 'Cancel Booking',
    confirmCancel: 'CONFIRM CANCELLATION',
    areYouSure: 'Are you sure you want to cancel your booking?',
    successCancel: 'Your booking has been cancelled.',
    change: 'Change Booking',
    myCanceledEvents: 'My canceled events',
    visitDiscussBoard: 'VISIT DISCUSSION BOARD',
    canceled: 'CANCELLED',
    noCancelledBookings: 'There are no cancelled bookings. Explore our upcoming booking.',
    startDate: '- Start date: ',
    endDate: '- End date: ',
    accept: 'Accept Booking',
    confirmAccept: 'CONFIRM ACCEPT',
    areYouSureAccept: 'Are you sure you want to accept your booking?',
    successAccept: 'Your booking has been accepted.',
    myAcceptedEvents: 'My booking',
    notAvailableAccept: 'This booking is not available to accept. Mentee needs to complete the payment first.',
  },
  read: {
    title: 'Read',
  },
  watch: {
    title: 'Watch',
    dailyLearning: 'DAILY LEARNING',
    thoughtLeader: 'THOUGHT LEADERSHIP SERIES',
    webinar: 'WEBINAR',
  },
  write: {
    title: 'Write',
    dailyLearning: 'DAILY LEARNING',
    thoughtLeader: 'THOUGHT LEADERSHIP SERIES',
    webinar: 'WEBINAR',
    addEntry: '+ ADD ENTRY',
  },
  content: {
    title: 'Content',
    noneData: "Opp! Don't have anything content here",
  },
  newFeed: {
    title: 'News Feed',
    event: 'Events',
    engage: 'Engage',
    discoverOtherWays: 'Discover Other Ways To Learn',
    embraceEnrichingExperience:
      'Embrace enriching experiences at our events. Connect, learn, and grow with each encounter. Your adventure into wellness starts here.',
    revisitYourPlaylist: 'Revisit Your Playlist',
    savedAResource: 'Saved a resource for later to check out? View and manage your resources.',
    articles: 'ARTICLES',
    videos: 'VIDEOS',
    podcasts: 'PODCASTS',
    journal: 'JOURNAL',
    mySavedPlaylist: 'MY SAVED PLAYLIST',
  },
  moneyCalculator: {
    homeLoan: 'Home Loan Savings Calculator',
    payCalculator: 'Pay Calculator',
    moneyCalculator: 'Money Calculator',
    money: 'MONEY',
    budgetPlanner: 'Budget Planner',
  },
  calculator: {
    budgetPlannerTitle: 'Budget Planner',
    budgetPassengerOne: `Getting a hold of your budget is the 101 of good money management.
    But where do you start? Budgets can be so time-consuming and
    confusing to complete. Not anymore! We want to make sure you’re
    always across what’s coming in, and what’s going out so that you can
    be in control of your money, and be in a position to better spend,
    save and grow your wealth.`,
    budgetPassengerTwo: `We’ve made this Budget Planner super easy to use. It’s intuitive and
    lets you put in your expenses as they come. Got quarterly bills? No
    need to divide them into weekly payments or multiply into annual
    amounts – let the Budget Planner do the work for you. So get your
    numbers in and take control of your money! You can keep changing
    things around to work out how much you can save or how it affects
    your budget. The power is now in your hands.`,
    budgetDescLineThree: 'Here’s to your financial clarity and independence!',
    weekly: 'Weekly',
    fortnightly: 'Fortnightly',
    monthly: 'Monthly',
    quarterly: 'Quarterly',
    annually: 'Annually',
    income: 'Income (after tax)',
    household: 'Household Expenses',
    insurance: 'Insurance & credit cards',
    groceries: 'Groceries',
    personal: 'Personal & medical',
    entertainment: 'Entertainment & eating-out',
    transport: 'Transport & auto',
    children: 'Children',
    summary: 'Summary',
    disposable: 'Disposable Income',
    tooltipTax: `If you need help working out your income
    after tax, use our `,
    tooltipCal: 'Pay Calculator',
    current: 'Current',
    proposed: 'Proposed',
    payment: 'Payment Frequency',
    totalIncome: 'Total Income',
    totalExpenses: 'Total Expenses',
    surplus: 'Surplus/deficit',
    clearFields: 'Clear fields for this section',
    clearAllFields: 'Clear all fields',
    back: 'Back',
    next: 'Next',
    save: 'Save result',
  },
  homeLoan: {
    title: 'Home Loan Savings Calculator',
    label:
      ' This powerful calculator helps you work out how to save thousands of dollars worth of interest off your home loan, or how you can reduce your payment term by years (or decades!) simply by changing how much you repay and how often.',
    label1:
      'We encourage you to use this calculator as frequently as you can so that whenever your financia situation changes, you can see just how much of an impact it can have on reducing interest across your lifetime.',
    calculate: 'Calculate',
    current: 'Current or Ideal Borrowing',
    money: '$',
    loanTerm: 'Loan Term',
    years: 'years',
    repaymentType: 'Repayment Type',
    interestRate: 'Interest Rate',
    percent: '%',
    repaymentFrequency: 'Repayment Frequency',
    results: 'Results',
    extra: 'Extra',
    repayments: 'Repayments',
    seeTheDifference:
      'See the difference making extra repayments will have on your total interest payable. You can also adjust the desired loan term at the start of the calculator to see how this impacts your interest.',
    mostBank: 'Most banks will only let you pay Interest only for a maximum of 5 years.',
    weVeDefault: ` We've defaulted this at 3.5% - you can adjust this to more accurately reflect your currentor proposed rate.`,
    totalLoanRepayments: 'Total loan repayments',
    includingExtraRepayments: '*Including extra repayments of',
    totalInterestCharged: 'Total interest charged',
    i: 'i',
  },

  selfAware: {
    selfAwarenessSurvey: 'Self Awareness Survey',
    previousResponses: 'Previous Responses',
    none: 'None',
    results: 'Results',
    notAvalable: 'Not Available',
  },
  survey: {
    resources: 'Resources',
    surveys: 'Surveys',
    viewMore: 'View More Surveys',
    retakeSurvey: 'Retake Survey',
    typeYourAnswerHere: 'Type your answer here...',
    calculators: 'Calculators',
    finance: 'Finance',
    calculate: 'Calculate',
  },

  learn: {
    title: 'Elevate',
    learn: 'Learn',
    relatedResources: 'Related Resources',
    nextLearn: 'Next Learning',
    yourLearningPlayList: 'Learning Playlist',
    allContentTab: 'All Content',
    powerClass: 'Power Class',
    powerClassesTab: 'Power Classes',
    webinarsTab: 'WEBINARS',
    nextUpSection: 'NEXT UP',
    continueWatching: 'CONTINUE WATCHING',
    previouslyViewed: 'PREVIOUSLY VIEWED',
    startAVideo: 'Start a video, and we’ll track it for you.\nCome back later to continue!',
    dontLoseSight: 'Don’t lose sight of your learning journey!  Come back to revisit at any time.',
    seeAll: 'See all',
    filterLearning: 'Level Up - Learn',
    type: 'Type',
    category: 'Category',
    placeHolderSearch: 'Search "Networking"',
    mentorResources: 'Mentor Resources',
    readMore: 'READ MORE',
    timeToLevelUp: " It's Time to Level Up",
    noVideo: 'No videos to continue watching',
    continueWat: 'Get started now with Your Learning Playlist. Come back at anytime.',
    watchNow: 'Watch Now',
    nothingToSee: 'Nothing (yet) to see here',
    previouslyWat: 'Get started now with Your Learning Playlist',
    videoSeries: 'video series',
    futuredvideoSeries: 'FEATURED VIDEO SERIES',
    moreFromThis: 'More from this series',
    powerClassesTitle: 'POWERCLASS',
    resourceTitle: 'FEATURED',
    listenNow: 'Let’s Go',
    wantMore: 'Want more?',
    inspirationAndExpertise:
      '10,000+ minutes of inspiration and expertise from some of our best\nand brightest female professionals.',
    dailyLearning: 'Daily Learning',
    discoverMore: 'Discover more',
    trackMyProgress: 'Track my progress',
    topic: 'TOPIC',
    typeUppercase: 'TYPE',
    minRead: 'MIN READ',
    discussBoard: 'Discussion Board',
    shareYourThought: 'Share your thoughts and takeaways!',
    comments: 'Comments',
    journalAddToJournal: 'Add to journal',
    journalViewMyJournal: 'View my journal',
    journalEntry: 'Entry',
  },
  error: {
    unknown_error: 'Something went wrong. Please pull down to refresh.',
    sorry: 'Sorry! Something seems to be wrong...',
    error: 'Error',
    success: 'Success',
    your_account: 'Sorry! Something seem to be wrong with your account',
    cardError: 'Sorry! Something seems to be wrong with your card',
    end: 'Sorry! Something seem to be wrong with your account. Please login again',
    internetIssue: 'Sorry! Please check your internet',
  },
  dailyLearning: {
    title: 'DAILY LEARNING',
    nextUp: 'Next up',
    aboutThisVideo: 'About this video',
    moreLikeThis: 'More like this',
    ideas: 'Ellevated Ideas',
    share: 'Share your thoughts and takeaways!',
    typing: 'Start typing...',
    comment: 'Comments',
    video: 'VIDEO',
    book: 'ARTICLE',
    errorSame: 'Already have a comment like you, please change the comment content',
    webinar: 'WEBINAR',
    blur: 'How are you leveling up today?',
    myPlayList: 'MY PLAYLIST',
    goPremium: 'GO PREMIUM',
    read: 'Read',
    search: 'Search',
    filter: 'Filter',
    searchPlaceholder: 'Type search here. Hit enter to submit or escape to close. ',
    watchNow: 'Watch Now',
    watch: 'Watch',
    mentor: 'Mentor',
  },
  myAvailability: {
    title: 'My availability',
    description: 'Select your preferred days and set your 1 hour start times.\nCome back and update at anytime.',
    startingAt: 'Starting at:',
    addNewTime: 'Add another time',
    unavailable: 'Unavailable',
    add_A_Block: 'Add a block for certain dates',
    save: 'Saved',
    yourNew: ' Your new times will now be reflected with your mentor profile.',
    sessionError: 'Sessions must be 1 hr apart. Please select another time',
    ok: 'OK',
    cancel: 'Cancel',
    selectTime: 'Select time',
    selectTimeZone: 'Please select your time zone',
    goToMyAvailability: 'Go To My Availability',
  },
  button: {
    save: 'SAVE',
    next: 'NEXT',
    requestBooking: 'REQUEST BOOKING',
    sendRequest: 'SEND REQUEST',
    bookAnotherSession: 'BOOK ANOTHER SESSION',
    goToDashboard: 'GO TO DASHBOARD',
    done: 'DONE',
    bookAgain: 'BOOK AGAIN',
    bookNow: 'BOOK NOW',
    close: 'Close',
    submit: 'Submit',
    requestSent: 'REQUEST SENT',
    bookAnother: 'BOOK ANOTHER',
    apply: 'APPLY',
    returnHome: 'RETURN TO HOME SCREEN',
  },
  profile: {
    title: 'Profile',
    followers: 'followers',
    follower: 'follower',
    following: 'following',
    editProfile: 'Edit',
    saveEdit: 'Save Edit',
  },
  onBoarding: {
    backToLogin: 'BACK TO LOGIN',
    setupYourProfile: 'Set Up Your Profile',
    title: 'Update profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    enterFirstName: 'Enter your first name',
    enterLastName: 'Enter your last name',
    pleaseFN: 'Please enter your first name',
    pleaseLN: 'Please enter your last name',
    pleaseJob: 'Please enter your job',
    pleasePhoneNum: 'Enter your phone number',
    jobTitle: 'Job Title',
    invalidatePhone: 'Invalid phone number',
    phoneNumber: 'Mobile',
    industry: 'Industry',
    ambition: 'Ambitions',
    takePhoto: 'Take photo',
    choosePhoto: 'Choose photo',
    chooseAvatar: 'CHOOSE AVATAR',
    uploadPicture: 'UPLOAD PICTURE',
    removePicture: 'REMOVE PICTURE',
    chooseARemarkableAvatar: 'Choose a remarkable avatar',
    pleasePickImage: 'Please pick your image',
    specialTopic: 'Special Topic',
    pleaseMentorBio: 'Please enter your mentor bio',
    pronounsLabel: 'Pronouns',
    optional: 'Optional',
    roleTitle: 'Role Title',
    enterYourRoleTitle: 'Enter your role title',
    selectPronounsYouWant: 'Select pronouns you want',
    selectYourIndustry: 'Select your industry',
    btnNextText: 'NEXT',
    btnPreviousText: 'PREVIOUS STEP',
    addYourProfilePicture: 'Add Your Profile Picture',
    ambitionsTitle: 'Tell us about your ambitions',
    ambitionsDescription:
      'Choose 1-3 focus areas below, so we can design the right learning experience for you. You can update your focus areas at any time!',
    learningStylesTitle: 'Tell us about your learning styles',
    learningStylesDescription:
      'We understand everyone is unique. Please choose your favourite learning styles so we can customise your learning experience.',
    learningPaceTitle: 'Tell us about your ideal learning pace',
    learningPaceDescription:
      'Choose the the level of learning intensity you would like to pace yourself with, so that we can sync learning tasks with your busy schedule.',
    youReAllSet: 'You’re all set!',
    getStartedJourney: 'Let’s get started on your development journey.',
    btnDoneText: 'DONE',
  },
  signUp: {
    title: 'Sign Up',
    emailLabel: 'Email',
    name: 'Name',
    passwordLabel: 'Password',
    confirmPasswordLabel: 'Confirm password',
    invitationCodeLabel: 'Invitation Code (optional)',
    pleaseEnterEmail: 'Please enter your email',
    pleaseValidEmail: 'Please enter a valid email',
    pleaseName: 'Please enter your name',
    passWordShort: 'Password is too short - should be 8 character minimum.',
    pleaseEnterPass: 'Please enter your password',
    placeHolderName: 'Enter your name',
    placeHolderPass: 'Enter your password',
    placeHolderEmail: 'Enter your email',
    passwordDontMatchFormat: 'Must contain 8 characters, lowercase, number and special case character',
    passwordDontMatch: "Password don't match",
    backToLogin: 'BACK TO LOGIN',
    btnSignUpText: 'Sign Up',
    agreeConditionText: 'I have read and agree to Women Love Tech’s',
    andCondition: ' and ',
    termCondition: 'Terms & Conditions ',
    privacyPolicy: 'Privacy Policy.',
    alreadyMember: 'Already a member?',
  },
  verifyCode: {
    title: 'Verify',
    enterVerifyCodeLabel: 'Enter verification code from email.',
    resendCode: 'Resend code',
    btnNextText: 'NEXT',
  },
  elevate: {
    mentorResources: 'Resources',
    myPlaylist: 'My PlayList',
    allContent: 'All Content',
  },
  formInput: {
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm your Password',
    login: 'Login',
    signUp: 'Sign Up',
    alreadyHasAccount: 'Already has an account?',
    createProfile: 'Create Profile',
    firstName: 'First Name',
    lastName: 'Last Name',
    jobTitle: 'Job Title',
    submit: 'Submit',
    mentorBio: 'Mentor Bio',
    location: 'Location',
    capture: 'Capture photo',
    close: 'Close',
  },
  engage: {
    upcomingBooking: 'Upcoming booking',
    completeBooking: 'Completed',
    myBooking: 'My Bookings',
    myAvailability: 'My Availability',
    bookingSent: 'REQUEST SENT',
    bookingConfirm: 'CONFIRMED',
    bookingCancel: 'CANCELLED',
    bookingDecline: 'REQUEST DECLINED',
    bookingCompleted: 'COMPLETED',
    getReady: 'Get ready to level up and book a mentor today!',
    noCompleteBooking: 'You have no completed booking',
    noRequestedBooking: 'You have no requested booking',
    noUpcomingBooking: 'You have no upcoming booking',
    requestedBooking: 'Requested booking',
    completeBookingMentor: 'Completed booking',
  },
  notFound: {
    notFound404: '404',
    pageNotFound: 'Page Not Found',
    goHome: 'Back to Homepage',
    goOnboarding: 'Back to On Boarding',
    goDashBoard: 'Back to Dashboard',
  },
  payment: {
    title: 'Choose a payment method',
    blur: 'You will be changed upon completion of the session.',
    details: 'Details',
    mentor: 'Mentor',
    addPayment: 'Add new payment',
    cancel:
      'No cancellations allowed within 6 hours of the session. Cancellations within 6 hours of the session start time will incur a X% of the session fee.',
    cancelPayment: 'Cancel',
    paymentSetting: 'Payment Settings',
    setupAccountDetails: 'SETUP ACCOUNT DETAILS',
    myMentorRate: 'My Mentor Rate',
    changeMyRate: 'CHANGE MY RATE',
    mentorRateDesc:
      'To set your mentor rate, consider experience, expertise, and demand. Factor in time commitment and market rates for fair value. Remember you can update this at anytime.',
    updateDetails: 'UPDATE ACCOUNT DETAILS',
    upcomingPayments: 'Upcoming payments',
    total: 'Total',
    nextPaymentDate: 'Next payment date',
    paidEarnings: 'Paid Earnings',
    areYouSure: 'Are you sure you want to delete this card?',
    cardRemoved: 'Card removed successfully!',
    cancelButton: 'Cancel',
    save: 'SAVE',
    whatDoYouWantToDiscuss: 'Let your mentor know what you’d like to discuss',
    notSure: 'Not sure what to say?',
    choose: 'Choose 1-2 Focus Areas',
    selectDate: 'Select A Date',
    selectTime: 'Select A Time',
    noteTimeCurrent: 'All times are set in your current timezone',
  },
  messages: {
    yourMentee: 'Your mentees',
    yourMentor: 'Your mentors',
    noMentors: 'No mentors (yet)',
    noMentees: 'No mentees (yet)',
    mentorAppearHere: 'Your mentors will appear here after connecting with them.',
    menteeAppearHere: 'Your mentee will appear here after connecting with them.',
    noMessage: 'No messages. Start new message',
    messageMentorAppearHere: 'Messages sent after connecting with a mentor will appear here.',
    messageMenteeAppearHere: 'Messages sent after connecting with a mentee will appear here.',
    viewMentors: 'View mentors',
    viewBookings: 'View bookings',
    myMessages: 'My Messages',
    messages: 'Messages',
    you: 'You',
    sendAnImage: 'send a image',
    mentor: 'Mentor',
    mentee: 'Mentee',
    back: 'BACK',
  },

  mentors: {
    findMentors: 'Find Mentors',
    bannerDescription: 'Ongoing, personalised coaching to help you realise your purpose and unleash your potential.',
    bannerDescription_2024_11_08: 'Ongoing, personalised coaching to help you realise your purpose and unleash your potential—connect with a mentor who is the perfect match for your unique journey and goals.',
    discoverMore: 'DISCOVER MORE',
    myMentorBooking: 'MY MENTOR BOOKING',
    noSureWhereToStart: 'Not sure where to start? Let us match you with a mentor!',
    mentorMatch: 'MENTOR MATCH',
    filterBy: 'FILTER BY',
    search: 'SEARCH',
    searchByNameMentor: 'Search by name of mentor',
    expertise: 'EXPERTISE',
    level: 'LEVEL',
    industry: 'INDUSTRY',
    yearsOfExperience: 'YEARS OF EXPERIENCE',
    availability: 'AVAILABILITY',
    save: 'SAVE',
    loadMore: 'LOAD MORE',
    explore: 'Explore Past Events',
    embrace:
      'Embrace enriching experiences at our events. Connect, learn, and grow with each encounter. Your adventure into wellness starts here.',
    pastEvent: 'PAST EVENTS',
    manageMyBooking: 'Manage My Bookings',
    viewAndMana: `View and manage your event bookings. You're in total control`,
    myEventBooking: 'MY EVENT BOOKINGS',
    book: 'BOOK',
    next: 'NEXT',
    filter: 'FILTER',
    sendRequest: 'SEND REQUEST',
    exploreUpcomingEvent: 'Explore Upcoming Events',
    embraceUpcomingEvent:
      'Embrace enriching experiences at our events. Connect, learn, and grow with each encounter. Your adventure into wellness starts here.',
    upcomingEventButtonText: 'UPCOMING EVENTS',
  },

  mentorDetail: {
    industry: 'Industry',
    location: 'Location',
    backToMentors: 'BACK TO FIND MENTORS',
    saveToPlaylist: 'SAVE TO PLAYLIST',
    linkedIn: 'LINKEDIN',
    overView: 'Overview',
    over3To6Month: 'Over 3 or 6 months working together, we will:',
    atTheGlance: 'At a Glance',
    sessionTimes: 'Session Times',
    yearsOfExperience: 'Years of Experience',
    price: 'Price',
    level: 'Level',
    expertise: 'Expertise',
    requestBooking: 'Request Booking',
    discussPlaceholder: "Add an agenda, your questions or anything in particular you'd like to discuss",
    yearsExperience: 'years experience in industry',
    perSession: 'per session',
    sessionsCompleted: 'sessions completed',
  },
  menuUser: {
    notifications: 'NOTIFICATIONS',
    myMessages: 'MY MESSAGES',
    myBookings: 'MY BOOKINGS',
    myMentorBookings: 'MY MENTOR BOOKINGS',
    myProgress: 'MY PROGRESS',
    mySavePlaylist: 'MY SAVE PLAYLIST',
    myJournal: 'MY JOURNAL',
    accountSettings: 'ACCOUNT SETTINGS',
    signOut: 'SIGN OUT',
    myAvailability: ' MY AVAILABILITY',
  },
  aboutTheLotus: {
    title: 'Women Love Tech',
    subTitle:
      'The collectives we create are focused on real people, real conversations, real connections and real change.',
    learnMore: 'LEARN MORE',
    betterTogether: 'We are Better Together.',
    strongerTogether: 'Stronger Together.',
    humanBeing:
      'Human beings are wired for connection and authentic connection goes to the heart of what I do. In alignment with my core offerings, I am an advocate for bringing people together to form collectives that drive authentic connection and positive change in families, communities and organisations.',
    discoverOtherWays: 'Discover Other Ways To Learn',
    emBraceEnriching:
      'Embrace enriching experiences at our events. Connect, learn, and grow with each encounter. Your adventure into wellness starts here.',
    articles: 'ARTICLES',
    videos: 'VIDEOS',
    podcasts: 'PODCASTS',
    journal: 'JOURNAL',
    mySavedPlaylist: 'MY SAVED PLAYLIST',
    revisitYourPlaylist: 'Revisit Your Playlist',
    savedAResource: 'Saved a resource for later to check out? View and manage your resources. ',
    titleStory: ' My Story',
    subTitleStory: 'Starring roadblocks, curveballs and a determination to find my purpose and passion.',
    hiThere: 'Hi There',
    imJustin: `I'm Justin`,
    itIsMission: ` It's my mission to empower, encourage and challenge high-achieving women to connect to who they are at
    their core, to`,
    liveALife: 'live a life of impact true to their passions and strengths.',
    myFocus: ` My focus is on supporting my client to reignite the light that is within them so that they can shine in the world as their unapologetic authentic selves.`,
    itIsNot: ` It's not my MBA or post-graduate qualifications in Counseling, Positive Psychology and Systemic Therapy
    that are going to help me do that for you, though. It's the fact that I have been where you are and I get
    it.`,
    growthIs: `Growth is not easy, but having a trusted confidante and guide to walk alongside you can make all the
    difference.`,
    iSeeYou: `I see you. I've been you. I get it.`,
    struggled: `I've struggled with imposter syndrome and questioned my own abilities.`,
    soughtVa: `I've sought validation and love from others instead of from myself.`,
    myToughest: `I've been my toughest critic and disrespected myself and my potential`,
    stuckIn: `I've been stuck in a complex web of double-bind messages as a woman`,
    butAlso: `But I've also done the deep work of rumbling with my own uncomfortable narratives around`,
    shame: 'shame, vulnerability and fear, ',
    andWorked: 'and worked my way back to becoming',
    me: 'me',
    andWho: 'and who I am at may core; my most true, authentic self',
    becoming: 'Becoming yourself is easier said than done, though.',
    manyOf: `Many of us don't even realise that we've un-become ourselves. We've just`,
    changedWho: 'changed who are over time to fit it',
    withWhat: 'with what society deems necessary in order to be likeable, lovable, respectable.',
    asAn: 'As an Empowerment Coach, I’ll support you to create a large impact in a small space. I’ll give you the tools and help you develop the skills to make a difference in your world.',
    fromStrug:
      'From struggling to a place of strength, I’ll empower you to embrace grit and grace to overcome life’s challenges and become YOU.',
    iWant: 'I want to support women on their journey to becoming, which is at the heart of',
    trueBelonging: ' true belonging',
    untilWe: 'Until we own our stories, they will define us.',
    asALittle: `As as little girl, I told myself`,
    iHadToDo: 'I had to do things perfectly to keep myself safe.',
    thisBePrefect: `This 'be prefect' story followed me through senior marketing positions across eight countries.`,
    keepingUp: 'Keeping up the facade of perfection nearly killed me',
    literally: '-literally-until I learned perfectionism is rooted in shame.',
    theBroken: 'The Broken Bride',
    onlyMonth:
      'Only months after my marriage, me relationship broke down. It was a wake-up call to do the work to heal my self. I came to understand that',
    lifeIs: 'life is unpredictable',
    andWe: 'and we need to look at the story behing the story. Change is inevitable but',
    growth: 'growth is a choice.',
    thePoisonous: 'The Poisonous Mom',
    myIntroduction: 'My introduction to',
    motherhood: 'motherhood was tough.',
    theStress: 'The stress of juggling motherhood, a sick baby and work saw my weight plummet to below 50kgs.',
    perfection: `Perfectionism was back in the driver's seat. `,
    readMore: 'Read More',
    theCourageous: 'The Courageous Woman',
    asMuchAs:
      'As much as I saw vulnerability as courage in others, I saw it as weakness in my self. The crunch came when I jumped off a haystack for a photo and smashed my foot to pieces. Cue multiple surgeries and chronic ongoing pain.',
    wasIGoing: 'Was I going to be a victim or a warrior?',
    theAnti: 'The Anti-Therapist',
    after25: `After 25 years as an ex-pat, I returned to Australia with my children. Then Covid's closed borders happened. It was the`,
    catalyst: 'catalyst for me to be still, take stock and synthesize everything I had learned about my own story.',
    becomingMe: 'Becoming Me',
    iHave:
      'I have come to realise that every twist and turn in my story has lead me to where I am now. I am doing work as a Coach that aligns with my values of',
    authenticity: 'authenticity, ethical practice and a (healthy) pursuit of excellence.',
    showingUp: 'I am showing up as my unapologetic self to live wholeheartedly in all domains of my life',
    myJourney: 'My Journey',
    toMe: 'TO ME',
    trueBelong: 'True BELONGING',
  },
  faq: {
    stillHaveQuestion: 'Still have questions? Contact Us today.',
    faq: 'FAQ',
    exploreFAQCommonly:
      "Got Questions? We've Got Answers! Explore our FAQ to find quick solutions to commonly asked queries.",
    contactUs: 'CONTACT US',
  },
  contactUs: {
    contactUs: 'Contact Us',
    reactOutAnyQuestion: 'Reach out with any questions you have and we’ll be in touch with answers.',
    sendMessage: 'Send us a message!',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Email',
    contactNumber: 'Contact Number',
    howCanWeHelp: 'How can we help?',
    message: 'Message',
    pleaseEnterYourFirstName: 'Please enter your first name',
    pleaseEnterYourLastName: 'Please enter your last name',
    pleaseEnterYourContactNumber: 'Please enter your contact number',
    youHaveAProblem: 'You have a problem?',
    textMeAnyQuestion: 'Text me any questions',
    submit: 'SUBMIT',
    agreeConditionText: 'I have read and agree to <strong>Women Love Tech’s</strong>',
    andCondition: ' and ',
    termCondition: 'Terms & Conditions',
    privacyPolicy: ' Privacy Policy.',
  },
  tabsProfileSetting: {
    notifications: 'NOTIFICATIONS',
    myMessages: 'MY MESSAGES',
    myBookings: 'MY BOOKINGS',
    myMentorBookings: 'MY MENTOR BOOKINGS',
    myProgress: 'MY PROGRESS',
    mySavedPlaylist: 'MY SAVED PLAYLIST',
    myJournal: 'MY JOURNAL',
    accountSettings: 'ACCOUNT SETTINGS',
  },
  notifications: {
    notifications: 'Notifications',
    view: 'VIEW',
  },
  myProgress: {
    myProgress: 'My Progress',
    monthlyInsights: 'Monthly Insights',
    lastMonthYouCompleted: 'Last Month, You completed a total of:',
    dailyStreak: 'Daily Streak',
    youDoingGreat: 'You’re doing great!',
    weeklyStreak: 'Weekly Streak',
    keepUpTheWork: 'Keep up the work!',
    engagementChart: 'Engagement Chart',
    ofTaskEngagement: '# of Tasks & Engagements',
    week: 'Week',
  },
  accountSettings: {
    accountSettings: 'Account Settings',
    firstName: 'First Name',
    lastName: 'Last Name',
    pronouns: 'Pronouns',
    roleTitle: 'Role Title',
    industry: 'Industry',
    updateFile: 'Update file',
    uploadProfilePhoto: 'Upload profile photo',
    chooseAvatar: 'Choose avatar',
    removePicture: 'Remove picture',
    makeSureFile: 'Make sure file is below 2mb',
    enterYourFirstName: 'Enter your first name',
    enterYourLastName: 'Enter your last name',
    entrepreneur: 'Entrepreneur',
    optional: 'Optional',
    selectPronounsYouWant: 'Select pronouns you want',
    update: 'UPDATE',
    myProfile: 'MY PROFILE',
    learningPreference: 'LEARNING PREFERENCE',
    loginAndSecurity: 'LOGIN AND SECURITY',
    paymentDetail: 'PAYMENT DETAIL',
    tellUsAboutYourAmbitions: 'Tell us about your ambitions',
    chooseFocusAreasBelow:
      'Choose 1-3 focus areas below, so we can design the right learning experience for you. You can update your focus areas at any time!',
    tellUsAboutLearningStyles: 'Tell us about your learning styles',
    chooseFavoriteLearningStyles:
      'We understand everyone is unique. Please choose your favorite learning styles so we can customize your learning experience.',
    tellUsIdealLearningPace: 'Tell us about your ideal learning pace',
    chooseLevelLearningPace:
      'Choose the the level of learning intensity you would like to pace yourself with, so that we can sync learning tasks with your busy schedule.',
    email: 'Email',
    deleteYourAccount: 'DELETE YOUR ACCOUNT',
    changePassword: 'Change Password',
    currentPassword: 'Current password',
    newPassword: 'New password',
    reEnterNewPassword: 'Re-enter new password',
    currentPasswordIsRequired: 'Current password is required',
    passwordTooShort: 'Password too short',
    passwordDoesNotMatchFormat: 'Must Contain 8 Characters, Lowercase, Number and  Special Case Character',
    newPasswordIsRequired: 'New password is required',
    passwordNotMatch: "Password don't match",
    savedPaymentMethod: 'Saved payments methods',
    addPaymentMethod: '+ Add payment method',
    delete: 'DELETE',
    exp: 'Exp',
    thisIsDefaultPayment: 'This is your default payment method.',
    setAsDefaultPayment: 'Set as default payment method',
    deleteYourAccountModal: 'Delete your account',
    youSureDeleteAccount: 'Are you sure delete this account?',
    btnCancelText: 'Cancel',
    btnDeleteText: 'Delete',
    enterAccountFirstNameToDelete: "Enter account's first name to confirm delete",
    enterAccountLastNameToDelete: "Enter account's last name to confirm delete",
    addNewPayment: 'Add New Payment',
    btnAddCardText: 'Add card',
    youDontHaveAnyCardPayment: "You don't have any card payment",
    profileUpdatedFail: 'Profile has been updated fail',
    profileUpdatedSuccess: 'Profile has been updated successfully',
    firstNameIsRequired: 'First name is required',
    lastNameIsRequired: 'Last name is required',
    roleTitleIsRequired: 'Role title is required',
    industryIsRequired: 'Industry is required',
    profilePictureIsRequired: 'Profile picture is required',
    genderLabel: 'Gender',
    selectYourGender: 'Select your gender',
    coachRolesLabel: 'Coach roles',
    selectYourRoles: 'Select your roles',
  },
  mySavedPlaylist: {
    mySavedPlaylist: 'My Saved Playlist',
    articles: 'ARTICLES',
    podcasts: 'PODCASTS',
    videos: 'VIDEOS',
    journalingActivities: 'JOURNALING ACTIVITIES',
    careerStories: 'CAREER STORIES',
    topProfiles: 'TOP PROFILES',
    mentors: 'MENTORS',
    events: 'EVENTS',
    remove: 'REMOVE',
    view: 'VIEW',
    mentor: 'Mentor',
    read: 'READ',
    dontHaveAnyMentorSaved: "You don't have any mentor saved",
    online: 'Online',
    webinar: 'Webinar',
  },
  myJournal: {
    myJournal: 'My Journal',
    journalingActivity: 'Journaling Activity',
    entry: 'Entry',
    edit: 'EDIT',
    delete: 'DELETE',
    back: 'BACK',
    viewEntry: 'VIEW ENTRY',
  },
  topicPage: {
    leadership: 'Leadership',
    elevateYourMindset: 'Elevate your mindset, expand your capabilities, and embark on a transformative journey.',
    elevateYourMindset_2024_11_08: 'Elevate your mindset, expand your leadership capabilities, and embark on a transformative journey with insights and articles designed to empower aspiring and established leaders.',
    diveInsightArticles:
      'Dive into insightful articles crafted to empower and inspire personal and professional growth. ',
    diveInsightVideos: 'Dive into insightful videos crafted to empower and inspire personal and professional growth. ',
    diveInsightPodcasts: 'Be Inspired by our Game Changers featuring leaders from around the world',
    diveInsightPodcasts_2024_11_13: 'Game Changers champions the world’s most inspiring entrepreneurs and leaders.',
    diveInsightJournals:
      'Dive into insightful journals crafted to empower and inspire personal and professional growth. ',
    type: 'Type',
    filterBy: 'FILTER BY:',
    search: 'SEARCH',
    filter: 'FILTER',
    searchByTitleResource: 'Search by title the resource',
    article: 'Article',
    video: 'Video',
    podcast: 'Podcast',
    journal: 'Journal',
  },
  resource: {
    thereAreNoResource: `There are no resources here`,
  },
  registerAttendanceEvent: {
    nameCardIsRequired: 'Name Card is required',
    cardNumberIsRequired: 'Card number is required',
    expireDateIsRequired: 'Expire Date is required',
    cvcIsRequired: 'CVC is required',
  },
  forgotPassword: {
    title: 'Forgot Password',
    emailLabel: 'Email',
    pleaseEnterEmail: 'Please enter your email',
    pleaseValidEmail: 'Please enter a valid email',
    backToLogin: 'BACK TO LOGIN',
    password: 'Password',
    confirmPassword: 'Confirm password',
    btn: 'Reset Password',
  },
  mentorsMatch: {
    skillPlaceholder: 'Skill',
    industryPlaceholder: 'Industry',
    rolePlaceholder: 'Role',
    genderPlaceholder: 'Gender',
  },
  podcast: {
    bannerTitle: 'Listen | Podcasts',
    subTitle: 'Be inspired by female role models and find out the latest news to supercharge your career.',
    subTitle_2024_11_08: 'Tune into our Game Changers series and dive into inspiring stories and career journeys from tech leaders, entrepreneurs, and trailblazers shaping the future.',
  },
};
