import { BaseResponse } from '@/shared/interface';
import { Timestamp } from 'firebase/firestore';

export enum ENotificationType {
  Comment = 'Comment',
  LikeComment = 'LikeComment',
  AcceptBookingMentor = 'AcceptBookingMentor',
  CancelledBookingMentor = 'CancelledBookingMentor',
  ChatMessage = 'ChatMessage',
}

export interface INotification {
  id: string;
  type?: ENotificationType;
  title?: string;
  body?: string;
  token?: string;
  userId?: string;
  createdAt?: Timestamp;
  isRead: boolean;
  link?: string;
}

export interface GetNotificationsResponse extends BaseResponse {
  data: INotification[];
}

export interface ReadNotificationsResponse extends BaseResponse {
  data: INotification;
}
