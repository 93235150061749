import { NotFoundImage } from '@/assets/images';
import { FooterBannerNewFeedLeft, RevistYourPlaylist } from '@/assets/redesign/images';
import BannerFooter from '@/component-redesigns/BannerFooter';
import ButtonBannerFooter from '@/component-redesigns/ButtonBannerFooter';
import CommentCustomList, { ETypeComment } from '@/component/CommentCustomList';
import CustomBanner from '@/component/CustomBanner';
import { formatDate } from '@/constants';
import { RouterName } from '@/router/config';
import { t } from 'i18next';
import moment from 'moment';
import DiscussionResourceInfo from '../DiscussionResourceInfo';
import './ProfilesDetail.scss';
import useProfilesDetailScreen from './hook';
import LoadingResourceDetail from '@/component/LoadingResourceDetail';
import ResourceEmpty from '../ResourceEmpty';

const ProfilesDetail = () => {
  const { comments, htmlData, resourceDetail, loadingGetResourceDetail } = useProfilesDetailScreen();

  return (
    <div className="profiles-container">
      {!loadingGetResourceDetail ? (
        <>
          {resourceDetail ? (
            <>
              <CustomBanner
                isGoback
                pathGoback={RouterName.careerStoriesDetail}
                bannerSrc={resourceDetail?.featured_image.thumb ?? NotFoundImage}
                title={resourceDetail?.title}
                subTitle={`${resourceDetail.content_type} | ${moment(resourceDetail.date).format(formatDate.MMDDyyyy)}`}
                hasButtons={false}
              />
              <div className="detail-container">
                <div className="detail-content">
                  <div className="content-container">
                    <div className="ck-content">
                      <p
                        dangerouslySetInnerHTML={{ __html: htmlData || '' }}
                        className="content-container__summary"
                      ></p>
                    </div>
                    <div className="content-container__author">{`Written by ${
                      resourceDetail?.author || t('childrenMenuLogged:womenLoveTech')
                    }`}</div>
                  </div>
                  <DiscussionResourceInfo totalComment={comments.length} />
                  <CommentCustomList
                    type={ETypeComment.Profiles}
                    comments={comments ?? []}
                    resource_id={resourceDetail.id}
                  />
                </div>
              </div>
            </>
          ) : (
            <ResourceEmpty />
          )}
        </>
      ) : (
        <LoadingResourceDetail />
      )}

      <BannerFooter
        titleLeft={t('newFeed:discoverOtherWays')}
        descriptionLeft={t('newFeed:embraceEnrichingExperience')}
        bannerLeft={FooterBannerNewFeedLeft}
        titleRight={t('newFeed:revisitYourPlaylist')}
        descriptionRight={t('newFeed:savedAResource')}
        bannerRight={RevistYourPlaylist}
        buttonsLeft={
          <>
            <ButtonBannerFooter text={t('newFeed:videos')} link={RouterName.videos} />
            <ButtonBannerFooter text={t('newFeed:podcasts')} link={RouterName.podcasts} />
            <ButtonBannerFooter text={t('newFeed:journal')} link={RouterName.journal} />
          </>
        }
        buttonsRight={
          <>
            <ButtonBannerFooter text={t('newFeed:mySavedPlaylist')} link={RouterName.myPlaylist} />
          </>
        }
      />
    </div>
  );
};

export default ProfilesDetail;
