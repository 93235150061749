import './resourceEmpty.scss';

const ResourceEmpty = () => {
  return (
    <div className="resource-empty">
      <p className="resource-empty__title">Resource doesn't exist</p>
    </div>
  );
};

export default ResourceEmpty;
