import { Location, LoginResponse, SessionData, TimeZone } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SessionState {
  session: SessionData | null;
  loading: boolean;
  ipInfo: TimeZone | null;
  error?: string;
  location: Location | null;
  isAuthenticated: boolean;
}

const initialState: SessionState = {
  session: null,
  loading: false,
  ipInfo: null,
  error: undefined,
  location: null,
  isAuthenticated: false,
};

export const sessionSlice = createSlice({
  name: 'sessionState',
  initialState,
  reducers: {
    requestLogin: (state: SessionState) => {
      state.loading = true;
      state.isAuthenticated = false;
    },
    loginFailure: (state: SessionState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
      state.isAuthenticated = false;
    },
    loginSuccess: (state: SessionState, action: PayloadAction<LoginResponse>) => {
      state.loading = false;
      state.session = action.payload.data;
      state.isAuthenticated = true;
    },
    requestSignOut: () => {
      // state.session = null;
      return initialState;
    },
    requestUpdateToken: (state: SessionState, action: PayloadAction<SessionData>) => {
      state.session = action.payload;
      state.isAuthenticated = true;
    },
    getIpInfo: (state: SessionState, action: PayloadAction<TimeZone>) => {
      state.ipInfo = action.payload;
    },
    getLocation: (state: SessionState, action: PayloadAction<Location>) => {
      state.location = action.payload;
    },
    cleanError: (state: SessionState) => {
      state.error = undefined;
    },
  },
});

export const {
  requestLogin,
  loginSuccess,
  loginFailure,
  requestSignOut,
  requestUpdateToken,
  getIpInfo,
  cleanError,
  getLocation,
} = sessionSlice.actions;

export default sessionSlice.reducer;
