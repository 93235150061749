import { CommentApi } from '@/api/comment';
import { CommentResourceApi } from '@/api/commentResource';
import {
  ArrowCTA,
  AvatarPlaceholder,
  ChatIcon,
  HeartFilledIcon,
  HeartIcon,
  SeeAllIcon,
} from '@/assets/redesign/images';
import DefaultLogo from '@/assets/redesign/images/icons/account/person.svg';
import { useAppDispatch, useAppSelector } from '@/redux/hook';
import { RootState } from '@/redux/store';
import { CommentData, EventData } from '@/shared/interface';
import { handleConvertNewComment } from '@/utils/convertNewComment';
import { Input } from 'antd';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { ETypeComment } from '../CommentCustomList';
import Image from '../Image';
import LoadingCircular from '../LoadingCircular';
import SubCommentList from '../SubCommentList';
import { getProfileAction } from '@/redux/profile/action';
import Avatar from '../Avatar';

type Props = {
  comment: CommentData;
  event?: EventData;
  type: ETypeComment;
  resource_id?: string;
};

const CommentCustomItem = ({ comment, event, type, resource_id }: Props) => {
  const dispatch = useAppDispatch();
  const { profileData: profile } = useAppSelector((state: RootState) => state.profileSessionState);
  const [subCommentOpenIds, setSubCommentOpenIds] = useState<string[]>([]);
  const [loadingReplySubComment, setLoadingReplySubComment] = useState<boolean>(false);
  const [loadingLikeComment, setLoadingLikeComment] = useState<boolean>(false);
  const [commentLikeActive, setCommentLikeActive] = useState<string>('');
  const [subComments, setSubComments] = useState<CommentData[]>(comment.sub_comments);
  const [subCommentValue, setSubCommentValue] = useState<string>('');
  const [totalLike, setTotalLike] = useState<number>(0);
  const [isLikedComment, setIsLikedComment] = useState<boolean>(false);

  useEffect(() => {
    if (comment.likes) {
      setTotalLike(comment.likes.length);
    }
  }, [comment]);

  useEffect(() => {
    if (!profile || !comment.likes) return;
    const like = profile.liked_comments?.find((item) => item === comment._id);
    if (like) {
      setIsLikedComment(true);
    } else {
      setIsLikedComment(false);
    }
  }, [profile, comment]);

  useEffect(() => {
    if (!comment.sub_comments) return;
    setSubComments(comment.sub_comments);
  }, [comment]);

  const handleChangeSubComments = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSubCommentValue(e.target.value);
  }, []);

  const handleGetAllSubComments = useCallback(async () => {
    let _data;
    if (resource_id) {
      const { data } = await CommentResourceApi.getCommentsList({ resource_id, parent_id: comment._id });
      _data = data;
    }
    if (event) {
      const { data } = await CommentApi.getCommentsList({ event_id: event.id, parent_id: comment._id });
      _data = data;
    }
    setSubComments(_data ?? []);
  }, [resource_id, event]);

  const handleReplyingComment = useCallback(
    async (comment_id: string) => {
      try {
        if (subCommentValue.length <= 0 || !comment) return;
        setLoadingReplySubComment(true);

        //****** Reply Comment EVENT ******//
        if (event && ETypeComment.Event) {
          const { data } = await CommentApi.replyAComment({
            content: subCommentValue,
            comment_id: comment_id,
            event_id: event.id,
          });
          if (data) {
            const newComment = handleConvertNewComment(data, profile);
            setSubComments((prev) => [...prev, newComment]);
          }
          setSubCommentValue('');
          setLoadingReplySubComment(false);
          return;
        }

        if (resource_id) {
          const { data } = await CommentResourceApi.replyAComment({
            comment_id,
            content: subCommentValue,
            resource_id,
          });
          if (data) {
            const newComment = handleConvertNewComment(data, profile);
            setSubComments((prev) => [...prev, newComment]);
          }
          setSubComments((prev) => [...prev]);
          setSubCommentValue('');
          setLoadingReplySubComment(false);
        }
      } catch (error) {
        setLoadingReplySubComment(false);
      }
    },
    [comment, dispatch, subCommentValue, event]
  );

  const handleLikeAComment = useCallback(
    async (comment_id: string) => {
      try {
        if (!comment) return;
        setLoadingLikeComment(true);
        setCommentLikeActive(comment_id);
        setTotalLike((prev) => prev + 1);
        setIsLikedComment(true);

        //****** Like Comment EVENT ******//
        if (event?.id && type === ETypeComment.Event) {
          await CommentApi.likeAComment({ comment_id });
        }

        //****** Like Comment RESOURCE ******//
        if (resource_id) {
          await CommentResourceApi.likeAComment(comment_id);
        }
        dispatch(getProfileAction());
        setLoadingLikeComment(false);
      } catch (error) {
        setLoadingLikeComment(false);
      }
    },
    [comment, dispatch, commentLikeActive, event, resource_id]
  );

  const handleUnLikeAComment = useCallback(
    async (comment_id: string) => {
      try {
        if (!comment) return;
        setLoadingLikeComment(true);
        setCommentLikeActive(comment_id);
        setTotalLike((prev) => prev - 1);
        setIsLikedComment(false);

        //****** Unlike Comment EVENT ******//
        if (event?.id && type === ETypeComment.Event) {
          await CommentApi.unlikeAComment({ comment_id });
        }

        //****** Like Comment RESOURCE ******//
        if (resource_id) {
          await CommentResourceApi.unlikeAComment(comment_id);
        }
        dispatch(getProfileAction());
        setLoadingLikeComment(false);
      } catch (error) {
        setLoadingLikeComment(false);
      }
    },
    [comment, dispatch, commentLikeActive, event, resource_id]
  );

  const handleShowingSubComments = useCallback(
    (id: string) => {
      if (subCommentOpenIds.includes(id)) {
        setSubCommentOpenIds((prev) => [...prev].filter((item) => item !== id));
      } else {
        handleGetAllSubComments();
        setSubCommentOpenIds((prev) => [...prev, id]);
      }
    },
    [subCommentOpenIds]
  );

  const handleEnterInputSubComment = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>, commentId: string) => {
      if (e.key === 'Enter') {
        handleReplyingComment(commentId);
      }
    },
    [handleReplyingComment]
  );

  const renderArrowComponent = useCallback(
    (id: string) => {
      return (
        <div className="container-see-more">
          {subCommentOpenIds.includes(id) ? (
            <Image src={SeeAllIcon} className={'container-see-more__active'} />
          ) : (
            <Image src={SeeAllIcon} className={'container-see-more__in-active'} />
          )}
        </div>
      );
    },
    [subCommentOpenIds, comment]
  );

  return (
    <div className="comment-custom-list__info--comments-container" key={comment._id}>
      <Avatar
        src={comment?.user?.user_profile ? comment.user.user_profile : DefaultLogo}
        alt={`avatar-${comment?.name}`}
        className="comment-custom-list__info--avatar"
        first_name={comment?.user.first_name}
      />
      <div className="comment-custom-list__info--comments-container--list">
        <h2 className="comment-custom-list__info--comments-container--list--name">
          {comment?.user.first_name} {comment?.user.last_name}
        </h2>

        <p className="comment-custom-list__info--comments-container--list--comment">{comment.content}</p>
        <div className="comment-custom-list__info--comments-container--list--icons">
          <div className="comment-custom-list__like--wrapper">
            {totalLike}
            <Image
              src={isLikedComment ? HeartFilledIcon : HeartIcon}
              className={`comment-custom-list__comment--icon--like ${
                loadingLikeComment && commentLikeActive === comment._id
                  ? 'comment-custom-list__comment--icon--like--loading'
                  : ''
              }`}
              onClick={() => {
                if (loadingLikeComment) return;
                isLikedComment ? handleUnLikeAComment(comment._id) : handleLikeAComment(comment._id);
              }}
            />
          </div>

          <div
            className="comment-custom-list__subcomment--wrapper"
            onClick={handleShowingSubComments.bind(null, comment._id)}
          >
            {subComments.length}
            <Image src={ChatIcon} className="comment-custom-list__comment--icon--like" />
            {renderArrowComponent(comment._id)}
          </div>
        </div>
        {subCommentOpenIds.includes(comment._id) && (
          <>
            <div className="comment-custom-list__info--sub-comments-container" key={comment._id}>
              <SubCommentList type={type} event={event} resource_id={resource_id} subComments={subComments} />
            </div>

            <div className="comment-custom-list__script--comments-input">
              <Avatar
                first_name={profile?.first_name}
                src={profile?.user_profile || AvatarPlaceholder}
                className="comment-custom-list__info--avatar"
              />
              <div className="comment-custom-list__input--wrapper">
                <Input
                  placeholder={t('pastEvent:startTyping')}
                  onChange={handleChangeSubComments}
                  value={subCommentValue}
                  className="comment-custom-list__input"
                  onKeyDown={(event) => handleEnterInputSubComment(event, comment._id)}
                />
                {loadingReplySubComment && (
                  <div className="comment-custom-list__loading--comment">
                    <LoadingCircular />
                  </div>
                )}
              </div>
              <Image
                src={ArrowCTA}
                className={`sending-comment-button ${loadingReplySubComment ? 'sending-comment-button__loading' : ''}`}
                onClick={() => {
                  if (loadingReplySubComment) return;
                  handleReplyingComment(comment._id);
                }}
              />
            </div>
          </>
        )}
        <hr className="comments-line" />
      </div>
    </div>
  );
};

export default CommentCustomItem;
