import { onBoardingRequest, updateNotificationTokenRequest } from '@/shared/interface';
import * as actionTypes from './actionTypes';
import { IPayloadChangePassword, IPayloadResendOTP, IPayloadVerifyAccount } from '../interfaces';

export const getProfileAction = () => {
  return {
    type: actionTypes.GET_PROFILE,
  };
};

export const updateProfileAction = (payload: onBoardingRequest) => {
  return {
    type: actionTypes.UPDATE_PROFILE,
    payload,
  };
};

export const updateProfileTokenAction = (payload: updateNotificationTokenRequest) => {
  return {
    type: actionTypes.UPDATE_TOKEN_PROFILE,
    payload,
  };
};

export const updateProfileMentorAction = (payload: onBoardingRequest) => {
  return {
    type: actionTypes.UPDATE_PROFILE_MENTOR,
    payload,
  };
};

export const updateTourCompletedAction = () => {
  return {
    type: actionTypes.UPDATE_TOUR_COMPLETED,
  };
};

export const deleteAccountAction = () => {
  return {
    type: actionTypes.DELETE_ACCOUNT,
  };
};

export const uploadImageToServiceAction = (payload: { URL: string; image?: File | string }) => {
  return {
    type: actionTypes.UPLOAD_IMAGE_TO_SERVICE,
    payload,
  };
};

export const verifyAccountAction = (payload: IPayloadVerifyAccount) => {
  return {
    type: actionTypes.VERIFY_ACCOUNT,
    payload,
  };
};

export const resendOTPVerifyAccountAction = (payload: IPayloadResendOTP) => {
  return {
    type: actionTypes.RESEND_CODE_VERIFY_ACCOUNT,
    payload,
  };
};

export const changePassword = (payload: IPayloadChangePassword) => {
  return {
    type: actionTypes.CHANGE_PASSWORD,
    payload,
  };
};

export const getResourceStatisticsAction = () => {
  return {
    type: actionTypes.GET_RESOURCE_STATISTICS,
  };
};
