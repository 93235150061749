import { AvailabilityData, MentorData, MentorsResponse } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MentorsState {
  listMentorData: MentorsResponse | null;
  singleMentorData: MentorData | null;
  availabilityData: AvailabilityData | null;
  loading: boolean;
  isLoading: boolean;
  error?: string;
  isFetchListMentorData?: boolean;
}

const initialState: MentorsState = {
  listMentorData: null,
  singleMentorData: null,
  availabilityData: null,
  loading: false,
  error: undefined,
  isLoading: false,
  isFetchListMentorData: false,
};

export const mentorsSlice = createSlice({
  name: 'MentorsState',
  initialState,
  reducers: {
    getMentors: (state: MentorsState) => {
      state.loading = true;
    },
    getMentorsSuccess: (state: MentorsState, action: PayloadAction<MentorsResponse>) => {
      state.loading = false;
      state.listMentorData = action.payload;
      state.isFetchListMentorData = true;
    },
    getMentorsFailure: (state: MentorsState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
      state.isFetchListMentorData = false;
    },
    getSingleMentorState: (state: MentorsState) => {
      state.loading = true;
    },
    getSingleMentorSuccess: (state: MentorsState, action: PayloadAction<MentorData>) => {
      state.loading = false;
      state.singleMentorData = action.payload;
    },
    getSingleMentorFailure: (state: MentorsState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearGetSingleMentor: (state: MentorsState) => {
      state.loading = false;
      state.singleMentorData = null;
      state.error = '';
    },
    getAvailabilityMentorStart: (state: MentorsState) => {
      state.isLoading = true;
    },
    getAvailabilityMentorSuccess: (state: MentorsState, action: PayloadAction<AvailabilityData>) => {
      state.isLoading = false;
      state.availabilityData = action.payload;
    },
    getAvailabilityMentorFailure: (state: MentorsState, action: PayloadAction<string | undefined>) => {
      state.isLoading = false;
      state.error = action.payload;
    },
    resetAvailabilityMentor: (state: MentorsState) => {
      state.isLoading = false;
      state.availabilityData = null;
      state.error = undefined;
    },
    clearIsFetchListMentorData: (state) => {
      state.isFetchListMentorData = false;
    },
  },
});

export const {
  getMentors,
  getMentorsSuccess,
  getMentorsFailure,
  getSingleMentorState,
  getSingleMentorSuccess,
  getSingleMentorFailure,
  getAvailabilityMentorStart,
  getAvailabilityMentorSuccess,
  getAvailabilityMentorFailure,
  resetAvailabilityMentor,
  clearGetSingleMentor,
  clearIsFetchListMentorData,
} = mentorsSlice.actions;

export default mentorsSlice.reducer;
