import { ICreateBookingRequest } from '@/shared/interface';
import {
  IPayloadAddCard,
  IPayloadConfirm,
  IPayloadDeleteCard,
  IPayloadRateMentor,
  IPayloadUpdateDefaultCard,
} from '../interfaces';
import * as actionTypes from './actionTypes';

export const addCardPaymentAction = (payload: IPayloadAddCard) => {
  return {
    type: actionTypes.ADD_CARD_PAYMENT,
    payload,
  };
};

export const getAllCardsPaymentAction = () => {
  return {
    type: actionTypes.GET_ALL_CARDS_PAYMENT,
  };
};

export const deleteCardPaymentAction = (payload: IPayloadDeleteCard) => {
  return {
    type: actionTypes.DELETE_CARD_PAYMENT,
    payload,
  };
};

export const updateCardPaymentDefaultAction = (payload: IPayloadUpdateDefaultCard) => {
  return {
    type: actionTypes.UPDATE_CARD_PAYMENT_DEFAULT,
    payload,
  };
};

export const getKeyStripePaymentAction = (payload: ICreateBookingRequest) => {
  return {
    type: actionTypes.GET_KEY_STRIPE_PAYMENT,
    payload,
  };
};

export const createStripePaymentAction = () => {
  return {
    type: actionTypes.CREATE_PAYMENT,
  };
};

export const confirmStripePaymentAction = (payload: IPayloadConfirm) => {
  return {
    type: actionTypes.CONFIRM_PAYMENT,
    payload,
  };
};

export const updateMentorRateAction = (payload: IPayloadRateMentor) => {
  return {
    type: actionTypes.MENTOR_RATE,
    payload,
  };
};

export const updateMentorStripeAction = () => {
  return {
    type: actionTypes.STRIPE_UPDATE,
  };
};
