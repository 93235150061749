import {
  CompletePowerClassResponse,
  GetPowerClassResponse,
  OpenPowerClassResponse,
  PowerClassRequest,
} from '../shared/interface';
import { ApiCall, endpoints } from './api';

export const PowerClassApi = {
  getPowerClassData: async (): Promise<GetPowerClassResponse> => await ApiCall.get(endpoints.powerClass),
  openPowerClass: async (data: PowerClassRequest): Promise<OpenPowerClassResponse> =>
    (await ApiCall.put())(`${endpoints.powerClass}/open`, data),
  completePowerClass: async (data: PowerClassRequest): Promise<CompletePowerClassResponse> =>
    (await ApiCall.put())(`${endpoints.powerClass}/complete`, data),
};
