import React, { useCallback } from 'react';
import './Avatar.scss';
import clsx from 'clsx';

interface ImageProps extends React.HTMLProps<HTMLImageElement> {
  src: string;
  alt?: string;
  first_name?: string;
  classNameForName?: string;
}

const Avatar: React.FC<ImageProps> = ({ alt, src, first_name, classNameForName, ...props }) => {
  const [isError, setIsError] = React.useState(false);

  const handleOnError = useCallback(() => {
    setIsError(true);
  }, []);

  return (
    <div>
      {!isError ? (
        <img src={src} alt={alt} onError={handleOnError} {...props} />
      ) : (
        <div className={clsx('avatar', classNameForName ? classNameForName : '')}>
          {first_name?.split('')[0] ?? 'N/A'}
        </div>
      )}
    </div>
  );
};

export default Avatar;
