import { toast } from 'react-toastify';

export const showErrorMessage = (message: string) => {
  return toast.error(message);
};

export const showSuccessMessage = (message: string) => {
  return toast.success(message);
};

export const showWarningMessage = (message: string) => {
  return toast.warn(message);
};
