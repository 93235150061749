import { IPayloadSaveToPlaylist, IPlaylistResponse } from '@/redux/interfaces';
import { ApiCall, endpoints } from './api';
import { ProfileResponse } from '@/shared/interface';

export const playlistsApi = {
  getAllSavedPlaylists: async (): Promise<IPlaylistResponse> => await ApiCall.get(endpoints.playlists),
  saveToPlaylist: async (data: IPayloadSaveToPlaylist): Promise<ProfileResponse> =>
    await ApiCall.post(endpoints.playlists, data),
  unSaveToPlaylist: async (data: IPayloadSaveToPlaylist): Promise<ProfileResponse> =>
    (await ApiCall.put())(`${endpoints.playlists}/unsave`, data),
};
