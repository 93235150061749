/* eslint-disable @typescript-eslint/no-explicit-any */
import { updateTokenAction } from '@/redux/session/action';
import { SIGN_OUT } from '@/redux/session/actionTypes';
import rootStore from '@/redux/store';
import { localStorageService } from '@/utils/storage';
import axios, { AxiosError } from 'axios';
import { StorageKeys } from '../utils/storage';
import { BaseResponse } from './../shared/interface';

const instance = axios.create({
  baseURL: process.env.BASE_URL,
  timeout: 60000,
});

const instanceNew = axios.create({
  baseURL: process.env.NEW_BASE_URL,
  timeout: 60000,
});

const cb = async (config: any) => {
  config.withCredentials = false;
  config.headers['Content-Type'] = 'application/json';
  const token = await localStorageService.getAccessToken();
  config.headers['Authorization'] = token;
  return config;
};

instanceNew.interceptors.request.use(cb, async (error) => {
  Promise.reject(error);
});

instance.interceptors.request.use(cb, async (error) => {
  Promise.reject(error);
});

const refreshToken = async () => {
  const refreshToken: string = localStorage.getItem(StorageKeys.refreshToken)!;
  return await instance.post(endpoints.refreshToken, {
    refresh_token: refreshToken,
  });
};

instance.interceptors.response.use(
  (res) => {
    res.headers['Access-Control-Allow-Origin'] = '*';
    return res.data;
  },
  async (error: AxiosError) => {
    // eslint-disable-next-line no-console
    console.log('Error', error);
    // eslint-disable-next-line no-console
    console.log('Response', error.response);
    if (
      error.code === 'ERR_NETWORK' &&
      !error.request?.responseURL.includes(endpoints.login) &&
      !error.request?.responseURL.includes(endpoints.refreshToken)
    ) {
      try {
        const res = await refreshToken();
        const newToken = res.data.AuthenticationResult.IdToken;
        if (newToken) {
          const config = error.config;
          config!.headers['Authorization'] = newToken;
          rootStore.store.dispatch(updateTokenAction(res.data));
          return instanceNew(config!);
        }
        return Promise.reject(error);
      } catch (error) {
        rootStore.store.dispatch({ type: SIGN_OUT });
        return Promise.reject(error);
      }
    } else {
      const errorData = error?.response?.data as BaseResponse;
      throw errorData;
    }
  }
);

// export default instance;

instanceNew.interceptors.response.use(
  (res) => {
    res.headers['Access-Control-Allow-Origin'] = '*';
    return res.data;
  },
  async (error: AxiosError) => {
    // eslint-disable-next-line no-console
    console.log('Error', error);
    // eslint-disable-next-line no-console
    console.log('Response', error.response);
    if (
      error.code === 'ERR_NETWORK' &&
      !error.request?.responseURL.includes(endpoints.login) &&
      !error.request?.responseURL.includes(endpoints.refreshToken)
    ) {
      try {
        const res = await refreshToken();
        const newToken = res.data.AuthenticationResult.IdToken;
        if (newToken) {
          const config = error.config;
          config!.headers['Authorization'] = newToken;
          rootStore.store.dispatch(updateTokenAction(res.data));
          return instanceNew(config!);
        }
        return Promise.reject(error);
      } catch (error) {
        rootStore.store.dispatch({ type: SIGN_OUT });
        return Promise.reject(error);
      }
    } else {
      const errorData = error?.response?.data as BaseResponse;
      throw errorData;
    }
  }
);

export default { instance, instanceNew };

export const ApiCall = {
  get: async (path: string, isNew?: boolean) => {
    if (isNew) {
      return instanceNew
        .get(path)
        .then((res) => res)
        .catch((err) => err);
    }
    return instance
      .get(path)
      .then((res) => res)
      .catch((err) => err);
  },
  post: async (path: string, data?: object, isNew?: boolean) => {
    if (isNew) {
      return instanceNew
        .post(path, data)
        .then((res) => res)
        .catch((err) => {
          throw err;
        });
    }
    return instance
      .post(path, data)
      .then((res) => res)
      .catch((err) => {
        throw err;
      });
  },
  put: async () => (path: string, data: object, isNew?: boolean) => {
    if (isNew) {
      return instanceNew
        .put(path, data)
        .then((res) => res)
        .catch((err) => err);
    }
    return instance
      .put(path, data)
      .then((res) => res)
      .catch((err) => err);
  },
  delete: async (path: string, isNew?: boolean) => {
    if (isNew) {
      return instanceNew
        .delete(path)
        .then((res) => res)
        .catch((err) => {
          throw err;
        });
    }
    return instance
      .delete(path)
      .then((res) => res)
      .catch((err) => err);
  },
  patch: async () => (path: string, data: object) =>
    instance
      .patch(path, data)
      .then((res) => res)
      .catch((err) => err),
};

const adminEndpointBase = '/admin';

export const endpoints = {
  login: '/signin',
  signup: '/signup',
  resources: '/resources',
  events: '/events',
  comments: '/events/comment',
  subComments: '/events/subcomment',
  upcomingEvents: '/events/upcoming',
  pastEvents: '/events/past',
  businessEvents: '/events/business',
  onboarding: '/onboarding',
  profile: '/profile',
  refreshToken: '/refresh-token',
  learning: '/learning',
  powerClass: '/powerclasses',
  categories: '/categories',
  mentors: '/mentors',
  single_mentor: '/mentor',
  availability: '/availability',
  profileImageUpload: '/profileimageupload',
  bookings: '/bookings',
  booking: '/booking',
  onboardingMentor: 'mentor/onboarding',
  learningStyles: '/learningstyles',
  learningPace: '/learningpace',
  tourCompleted: '/tourcompleted',
  contactUs: '/contact-us',
  playlists: '/playlists',
  account: '/account',
  updateCardDefault: '/stripe/card/default',
  cardPayment: '/stripe/card',
  confirmPayment: '/stripe/confirm',
  intentPayment: '/stripe/intent',
  createPayment: '/stripe/create',
  rateMentor: '/mentor/rate',
  stripeUpdate: '/stripe/update',
  webStyles: '/webStyles',
  verifyAccount: '/otp',
  resendOTPVerifyAccount: '/resend-otp',
  createPaymentEvent: '/stripe/event/intent',
  savedEvent: '/tickets',
  saveEvent: '/tickets',

  // * Admin
  adminResource: `${adminEndpointBase}/resources`,
  entry: `/entries`,

  commentResource: '/admin/resources/comment',
  subCommentResource: '/admin/resources/subcomment',
  zoomJoin: '/zoom/join',
  zoomToken: '/zoom/token',

  mentorsMatch: 'mentors-match',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password',
  resendForgotPassword: '/resend-forgot-password',
  changePassword: '/account/password',
  resourceStatistics: '/resource-statistics',
  myBookings: '/my-bookings',
  notifications: '/notifications',
  messages: '/messages',
};
