import { AuthApi } from '@/api/auth';
import { Location, LoginRequest, LoginResponse, SessionData, TimeZone } from '@/shared/interface';
import { StorageKeys } from '@/utils/storage';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
  cleanError,
  getIpInfo,
  loginFailure,
  loginSuccess,
  requestLogin,
  requestSignOut,
  requestUpdateToken,
  getLocation,
} from './slice';
import moment from 'moment';

const expiresInMS = 3600;

function* loginRequest(action: PayloadAction<LoginRequest>) {
  try {
    yield put(requestLogin());
    const res: LoginResponse = yield call(AuthApi.login, action.payload);
    if (res?.success) {
      yield put(loginSuccess(res));
      yield localStorage.setItem(StorageKeys.session, JSON.stringify(res.data));
      const expiresIn = moment().add(expiresInMS, 'seconds').valueOf();
      yield localStorage.setItem(StorageKeys.timeExpires, expiresIn.toString());
      yield localStorage.setItem(StorageKeys.refreshToken, res.data?.AuthenticationResult?.RefreshToken);
    } else {
      yield put(loginFailure(res?.message));
    }
  } catch (error) {
    const err = error as { message: string };
    yield put(loginFailure(err.message?.toString()));
  }
}

function* signOutRequest() {
  yield put(requestSignOut());
  yield localStorage.removeItem(StorageKeys.session);
  yield localStorage.removeItem(StorageKeys.timeExpires);
  yield localStorage.removeItem(StorageKeys.refreshToken);
  yield localStorage.removeItem(StorageKeys.isTour);
}

function* updateTokenRequest(action: PayloadAction<SessionData>) {
  yield put(requestUpdateToken(action.payload));
  yield localStorage.setItem(StorageKeys.session, JSON.stringify(action.payload));
  const expiresIn = moment().add(expiresInMS, 'seconds').valueOf();
  yield localStorage.setItem(StorageKeys.timeExpires, expiresIn.toString());
}

function* getIpInfoRequest(action: PayloadAction<TimeZone>) {
  yield put(getIpInfo(action.payload));
}

function* getLocationRequest(action: PayloadAction<Location>) {
  yield put(getLocation(action.payload));
}

function* cleanErrorRequest() {
  yield put(cleanError());
}

export function* watchSession() {
  yield takeLatest(actionTypes.LOGIN, loginRequest);
  yield takeLatest(actionTypes.SIGN_OUT, signOutRequest);
  yield takeLatest(actionTypes.UPDATE_TOKEN, updateTokenRequest);
  yield takeLatest(actionTypes.GET_IP_INFO, getIpInfoRequest);
  yield takeLatest(actionTypes.LOCATION, getLocationRequest);
  yield takeLatest(actionTypes.CLEAN_ERROR, cleanErrorRequest);
}
