import { ResourcesResponse } from '@/shared/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ResourcesState {
  data: ResourcesResponse | null;
  loading: boolean;
  error?: string;
}

const initialState: ResourcesState = {
  data: null,
  loading: false,
  error: undefined,
};

export const resourcesSlide = createSlice({
  name: 'EventSlide',
  initialState,
  reducers: {
    getResources: (state: ResourcesState) => {
      state.loading = true;
    },
    getResourcesFailure: (state: ResourcesState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getResourcesSuccess: (state: ResourcesState, action: PayloadAction<ResourcesResponse>) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { getResources, getResourcesSuccess, getResourcesFailure } = resourcesSlide.actions;

export default resourcesSlide.reducer;
