export const GET_LEARNING = 'GET_LEARNING';
export const OPEN_LEARNING = 'OPEN_LEARNING';
export const COMPLETE_LEARNING = 'COMPLETE_LEARNING';
export const TRACKING_LEARNING = 'TRACKING_LEARNING';
export const GET_RESOURCE = 'GET_RESOURCE';
export const CREATE_ENTRY = 'CREATE_ENTRY';
export const GET_USER_ENTRY = 'GET_USER_ENTRY';
export const DELETE_ENTRY = 'DELETE_ENTRY';
export const GET_RESOURCE_DETAIL = 'GET_RESOURCE_DETAIL';
export const FETCH_RESOURCE_CONTENT_TYPE_CAREER_STORIES = 'FETCH_RESOURCE_CONTENT_TYPE_CAREER_STORIES';
export const FETCH_RESOURCE_CONTENT_TYPE_PROFILES = 'FETCH_RESOURCE_CONTENT_TYPE_PROFILES';
export const GET_MY_LOVE_CONTENT_REQUEST = 'GET_MY_LOVE_CONTENT_REQUEST';
