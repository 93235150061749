/* eslint-disable no-useless-catch */
import {
  CommentRequest,
  CommentResponse,
  CommentsResponse,
  EventRequest,
  PostEventCommentRequest,
  ReplyCommentRequest,
} from '@/shared/interface';
import queryString from 'query-string';
import { ApiCall, endpoints } from './api';

export const CommentApi = {
  postCommentOnEvent: async (data: PostEventCommentRequest): Promise<CommentResponse> => {
    try {
      const response = await ApiCall.post(`${endpoints.comments}`, data);
      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  getCommentsList: async ({ event_id, parent_id }: EventRequest): Promise<CommentsResponse> => {
    try {
      const query = queryString.stringify({
        event_id,
        parent_id,
      });
      const response = await ApiCall.get(`${endpoints.comments}?${query}`);

      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  likeAComment: async ({ comment_id }: CommentRequest): Promise<CommentResponse> => {
    try {
      const query = queryString.stringify({
        comment_id,
      });
      const response = (await ApiCall.put())(`${endpoints.comments}/like?${query}`, {});
      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  unlikeAComment: async ({ comment_id }: CommentRequest): Promise<CommentResponse> => {
    try {
      const query = queryString.stringify({
        comment_id,
      });
      const response = (await ApiCall.put())(`${endpoints.comments}/unlike?${query}`, {});

      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  replyAComment: async (data: ReplyCommentRequest): Promise<CommentResponse> => {
    try {
      const response = await ApiCall.post(`${endpoints.subComments}`, data);

      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
};
