import Button from '@/component/Button';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './buttonBannerFooter.scss';
type Props = {
  text: string;
  link: string;
};

const ButtonBannerFooter = React.memo(({ text, link }: Props) => {
  const navigate = useNavigate();
  const handleNavigate = useCallback(() => {
    navigate(link);
  }, [link]);
  return <Button onClick={handleNavigate} text={text} customClassName="button-banner-footer" />;
});
ButtonBannerFooter.displayName = 'ButtonBannerFooter';
export default ButtonBannerFooter;
