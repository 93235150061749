import { ResourceResponse } from '@/shared/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ResourcesState {
  data: ResourceResponse | null;
  loading: boolean;
  error?: string;
}

const initialState: ResourcesState = {
  data: null,
  loading: false,
  error: undefined,
};

export const resourcesAdminSlice = createSlice({
  name: 'EventSlide',
  initialState,
  reducers: {
    getResourcesAdmin: (state: ResourcesState) => {
      state.loading = true;
    },
    getResourcesAdminFailure: (state: ResourcesState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getResourcesAdminSuccess: (state: ResourcesState, action: PayloadAction<ResourceResponse>) => {
      state.loading = false;
      state.data = action.payload;
    },
  },
});

export const { getResourcesAdmin, getResourcesAdminSuccess, getResourcesAdminFailure } = resourcesAdminSlice.actions;

export default resourcesAdminSlice.reducer;
