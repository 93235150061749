export const CREATE_BOOKING = 'CREATE BOOKING';
export const GET_SINGLE_BOOKING = 'GET SINGLE BOOKING';
export const GET_BOOKINGS = 'GET BOOKINGS';
export const START_BOOKING = 'START BOOKING';
export const ACCEPT_BOOKING = 'ACCEPT BOOKING';
export const REJECT_BOOKING = 'REJECT BOOKING';
export const REMOVE_BOOKING = 'REMOVE BOOKING';
export const UPDATE_BOOKING = 'UPDATE BOOKING';
export const RESET_BOOKING = 'RESET BOOKING';
export const RESET_BOOKING_DETAIL = 'RESET BOOKING DETAIL';
export const GET_CREATE_BOOKING = 'GET CREATE BOOKING';
export const GET_MY_BOOKINGS = 'GET MY BOOKINGS';
