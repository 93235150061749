import { ILearningPace } from '@/shared/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  learningPace: ILearningPace[];
  loading: boolean;
  isFetched: boolean;
}

const initialState: IInitialState = {
  learningPace: [],
  loading: false,
  isFetched: false,
};

const learningPaceSlice = createSlice({
  name: 'MentorsState',
  initialState,
  reducers: {
    getLearningPace: (state) => {
      state.loading = true;
    },
    getLearningPaceSuccess: (state, action: PayloadAction<ILearningPace[]>) => {
      state.loading = false;
      state.learningPace = action.payload;
      state.isFetched = true;
    },
    getLearningPaceFailure: (state) => {
      state.loading = false;
      state.isFetched = false;
    },
  },
});

export const { getLearningPace, getLearningPaceSuccess, getLearningPaceFailure } = learningPaceSlice.actions;

export default learningPaceSlice.reducer;
