import SuspenseLoading from '@/component/SuspenseLoading';
// import Home from '@/pages/Home';
import { useAppSelector } from '@/redux/hook';
import { RootState } from '@/redux/store';
import { lazy, Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { RouterName } from './config';
import { useDispatch } from 'react-redux';
import { getWebStyleAction } from '@/redux/webStyles/action';
import { lazyRetry } from './lazyRetry';
import CareerStoriesDetail from '@/pages-redesign/LeanMenu/CareerStoriesDetail';
import ProfilesDetail from '@/pages-redesign/LeanMenu/ProfilesDetail';
const Leadership = lazy(() => lazyRetry(() => import('@/pages-redesign/LeaderShip')));
const Dashboard = lazy(() => lazyRetry(() => import('@/pages-redesign/Dashboard')));
const EventScreen = lazy(() => lazyRetry(() => import('@/pages-redesign/EventScreen')));
const LoginPage = lazy(() => lazyRetry(() => import('@/pages-redesign/Login')));
const NewsFeed = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/NewsFeed')));
const Articles = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/Articles')));
const Videos = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/Videos')));
const Podcasts = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/Podcasts')));
const Journal = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/Journal')));
const EventDetail = lazy(() => lazyRetry(() => import('@/pages-redesign/EventScreen/EventDetail')));
const ArticleDetail = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/ArticleDetail')));
const MainLayout = lazy(() => lazyRetry(() => import('@/component/MainLayout')));
const GuestLayout = lazy(() => lazyRetry(() => import('@/layouts/GuestLayout')));
const MentorDetail = lazy(() => lazyRetry(() => import('@/pages-redesign/MentorDetail')));
const VideoDetail = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/VideoDetail')));
const JournalDetail = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/JournalDetail')));
const PodcastDetail = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/PodcastDetail')));
const MyProgress = lazy(() => lazyRetry(() => import('@/pages-redesign/MyProgress')));
const PastEvent = lazy(() => lazyRetry(() => import('@/pages-redesign/EventScreen/PastEvent')));
const MySavedPlaylist = lazy(() => lazyRetry(() => import('@/pages-redesign/MySavedPlaylist')));
const EventDiscussionBoard = lazy(() => lazyRetry(() => import('@/pages-redesign/EventScreen/EventDiscussionBoard')));
const NewsFeedDetail = lazy(() => lazyRetry(() => import('@/pages-redesign/LeanMenu/NewsFeedDetail')));
const JoinNow = lazy(() => lazyRetry(() => import('@/pages-redesign/MyBooking/JoinNow')));
const MyBooking = lazy(() => lazyRetry(() => import('@/pages-redesign/MyBooking')));
const MyMentorBooking = lazy(() => lazyRetry(() => import('@/pages-redesign/MyMentorBooking')));
const MyJournal = lazy(() => lazyRetry(() => import('@/pages-redesign/MyJournal')));
const AccountSetting = lazy(() => lazyRetry(() => import('@/pages-redesign/AccountSetting')));
const Notifications = lazy(() => lazyRetry(() => import('@/pages-redesign/Notifications')));
const FAQ = lazy(() => lazyRetry(() => import('@/pages-redesign/FAQ')));
const ContactUs = lazy(() => lazyRetry(() => import('@/pages-redesign/ContactUs')));
const Availability = lazy(() => lazyRetry(() => import('@/pages-redesign/Availability')));
const BookingDetailMentee = lazy(() => lazyRetry(() => import('@/pages-redesign/BookingDetailMentee')));
const BookingDetailMentor = lazy(() => lazyRetry(() => import('@/pages-redesign/BookingDetailMentor')));
const BookingRequestForMentee = lazy(() => lazyRetry(() => import('@/pages/BookingRequesForMentee')));
const BudgetPlanner = lazy(() => lazyRetry(() => import('@/pages/BudgetPlanner')));
const HomeLoanSaving = lazy(() => lazyRetry(() => import('@/pages/HomeLoanSaving')));
const MoneyCalculator = lazy(() => lazyRetry(() => import('@/pages/MoneyCalculator')));
const OnBoarding = lazy(() => lazyRetry(() => import('@/pages-redesign/Onboarding')));
const PayCalculator = lazy(() => lazyRetry(() => import('@/pages/PayCalculator')));
const PowerClasses = lazy(() => lazyRetry(() => import('@/pages/PowerClasses')));
const PowerClassesDetail = lazy(() => lazyRetry(() => import('@/pages/PowerClassesDetail')));
const PowerClassesSeriesDetail = lazy(() => lazyRetry(() => import('@/pages/PowerClassesSeriesDetail')));
const Profile = lazy(() => lazyRetry(() => import('@/pages/Profile')));
const SignUp = lazy(() => lazyRetry(() => import('@/pages-redesign/SignUp')));
const MainSurvey = lazy(() => lazyRetry(() => import('@/pages/Survey')));
const BrainDominance = lazy(() => lazyRetry(() => import('@/pages/Survey/BrainDominance')));
const HumanMotivation = lazy(() => lazyRetry(() => import('@/pages/Survey/HumanMotivation')));
const SelfAwareness = lazy(() => lazyRetry(() => import('@/pages/Survey/Self-Awarness')));
const VAKLearning = lazy(() => lazyRetry(() => import('@/pages/Survey/VAKLearning')));
const VideoZoom = lazy(() => lazyRetry(() => import('@/pages-redesign/Zoom')));
const NotFound = lazy(() => lazyRetry(() => import('@/pages/NotFound')));
const EditProfile = lazy(() => lazyRetry(() => import('@/pages/Profile/EditProfile')));
const Messages = lazy(() => lazyRetry(() => import('@/pages-redesign/Messages')));
const Mentors = lazy(() => lazyRetry(() => import('@/pages-redesign/Mentors')));
const WomenLoveTech = lazy(() => lazyRetry(() => import('@/pages-redesign/WomenLoveTech')));
const ForgotPassword = lazy(() => lazyRetry(() => import('@/pages-redesign/ForgotPassword')));
const ResetPassword = lazy(() => lazyRetry(() => import('@/pages-redesign/ResetPassword')));

function ProtectedRoute({ children }: { children: React.ReactElement }) {
  const { isAuthenticated } = useAppSelector((state: RootState) => state.sessionState);
  const { data } = useAppSelector((state: RootState) => state.profileSessionState);
  if (isAuthenticated) {
    return data?.data?.first_time === true ? <OnBoarding /> : children;
  } else {
    return <Navigate to="/login" />;
  }
}

export const RouterApp = () => {
  const { isAuthenticated } = useAppSelector((state: RootState) => state.sessionState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getWebStyleAction());
  }, []);

  return (
    <BrowserRouter>
      <Suspense fallback={<SuspenseLoading />}>
        <Routes>
          <Route element={<GuestLayout />}>
            <Route path={RouterName.initialPage} element={isAuthenticated ? <Dashboard /> : <LoginPage />} />
            {/* <Route path={RouterName.homePage} element={<Home />} /> */}
            <Route path={RouterName.loginPage} element={<LoginPage />} />
            <Route path={RouterName.onBoarding} element={<OnBoarding />} />
            <Route path={RouterName.signUp} element={<SignUp />} />
            <Route path={RouterName.forgotPassword} element={<ForgotPassword />} />
            <Route path={RouterName.resetPassword} element={<ResetPassword />} />
          </Route>

          {/* Logged in layout EVENTS */}
          <Route element={<MainLayout isCustomFooter={true} />}>
            <Route
              path={RouterName.eventDetail}
              element={
                <ProtectedRoute>
                  <EventDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.event}
              element={
                <ProtectedRoute>
                  <EventScreen />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.eventDiscussionBoard}
              element={
                <ProtectedRoute>
                  <EventDiscussionBoard />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.pastEvent}
              element={
                <ProtectedRoute>
                  <PastEvent />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.newsFeed}
              element={
                <ProtectedRoute>
                  <NewsFeed />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.articles}
              element={
                <ProtectedRoute>
                  <Articles />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.videos}
              element={
                <ProtectedRoute>
                  <Videos />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.podcasts}
              element={
                <ProtectedRoute>
                  <Podcasts />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.journal}
              element={
                <ProtectedRoute>
                  <Journal />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.articleDetail}
              element={
                <ProtectedRoute>
                  <ArticleDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.videoDetail}
              element={
                <ProtectedRoute>
                  <VideoDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.journalDetail}
              element={
                <ProtectedRoute>
                  <JournalDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.podcastDetail}
              element={
                <ProtectedRoute>
                  <PodcastDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.careerStoriesDetail}
              element={
                <ProtectedRoute>
                  <CareerStoriesDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.profilesDetail}
              element={
                <ProtectedRoute>
                  <ProfilesDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.dashBoard}
              element={
                <ProtectedRoute>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.zoom}
              element={
                <ProtectedRoute>
                  <VideoZoom />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.moneyCalculators}
              element={
                <ProtectedRoute>
                  <MoneyCalculator />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.budgetPlanner}
              element={
                <ProtectedRoute>
                  <BudgetPlanner />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.homeLoan}
              element={
                <ProtectedRoute>
                  <HomeLoanSaving />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.homeSurvey}
              element={
                <ProtectedRoute>
                  <MainSurvey />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.payCalculator}
              element={
                <ProtectedRoute>
                  <PayCalculator />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.selfAwareness}
              element={
                <ProtectedRoute>
                  <SelfAwareness />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.vakLearning}
              element={
                <ProtectedRoute>
                  <VAKLearning />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.humanMotivation}
              element={
                <ProtectedRoute>
                  <HumanMotivation />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.brainDominance}
              element={
                <ProtectedRoute>
                  <BrainDominance />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.powerClasses}
              element={
                <ProtectedRoute>
                  <PowerClasses />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.powerClassesDetail}
              element={
                <ProtectedRoute>
                  <PowerClassesDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.powerClassesSeriesDetail}
              element={
                <ProtectedRoute>
                  <PowerClassesSeriesDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.myAvailability}
              element={
                <ProtectedRoute>
                  <Availability />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.profile}
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.bookingRequestForMentee}
              element={
                <ProtectedRoute>
                  <BookingRequestForMentee />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.bookingDetailMentor}
              element={
                <ProtectedRoute>
                  <BookingDetailMentor />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.bookingDetailMentee}
              element={
                <ProtectedRoute>
                  <BookingDetailMentee />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.editProfile}
              element={
                <ProtectedRoute>
                  <EditProfile />
                </ProtectedRoute>
              }
            />

            <Route
              path={RouterName.messages}
              element={
                <ProtectedRoute>
                  <Messages />
                </ProtectedRoute>
              }
            />

            <Route
              path={RouterName.mentors}
              element={
                <ProtectedRoute>
                  <Mentors />
                </ProtectedRoute>
              }
            />

            <Route
              path={RouterName.mentorDetail}
              element={
                <ProtectedRoute>
                  <MentorDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.faq}
              element={
                <ProtectedRoute>
                  <FAQ />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.contactUs}
              element={
                <ProtectedRoute>
                  <ContactUs />
                </ProtectedRoute>
              }
            />

            <Route
              path={RouterName.notifications}
              element={
                <ProtectedRoute>
                  <Notifications />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.myProgress}
              element={
                <ProtectedRoute>
                  <MyProgress />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.myPlaylist}
              element={
                <ProtectedRoute>
                  <MySavedPlaylist />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.accountSettings}
              element={
                <ProtectedRoute>
                  <AccountSetting />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.myJournal}
              element={
                <ProtectedRoute>
                  <MyJournal />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<NotFound />} />

            <Route
              path={RouterName.womenLoveTech}
              element={
                isAuthenticated ? (
                  <ProtectedRoute>
                    <WomenLoveTech />
                  </ProtectedRoute>
                ) : (
                  <WomenLoveTech />
                )
              }
            />
            <Route
              path={RouterName.aboutTheStory}
              element={
                isAuthenticated ? (
                  <ProtectedRoute>
                    <WomenLoveTech />
                  </ProtectedRoute>
                ) : (
                  <WomenLoveTech />
                )
              }
            />

            <Route
              path={RouterName.myMentorBookings}
              element={
                <ProtectedRoute>
                  <MyMentorBooking />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.myBooking}
              element={
                <ProtectedRoute>
                  <MyBooking />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.joinNow}
              element={
                <ProtectedRoute>
                  <JoinNow />
                </ProtectedRoute>
              }
            />
            <Route
              path={RouterName.newsFeedDetails}
              element={
                <ProtectedRoute>
                  <NewsFeedDetail />
                </ProtectedRoute>
              }
            />

            <Route
              path={RouterName.topicLeaderShip}
              element={
                <ProtectedRoute>
                  <Leadership />
                </ProtectedRoute>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};
