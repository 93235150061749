import { IParamsGetAllComments, IParamsPostComment } from '@/api/commentResource';
import * as actionTypes from './actionTypes';

export const getCommentsResourceAction = (payload: IParamsGetAllComments) => {
  return {
    type: actionTypes.GET_All_COMMENTS_RESOURCE,
    payload,
  };
};

export const postACommentResourceAction = (payload: IParamsPostComment) => {
  return {
    type: actionTypes.POST_COMMENT_RESOURCE,
    payload,
  };
};
