/* eslint-disable no-useless-catch */
import { CommentResponse, CommentsResponse } from '@/shared/interface';
import queryString from 'query-string';
import { ApiCall, endpoints } from './api';

export interface IParamsPostComment {
  resource_id: string;
  content: string;
}

export interface IParamsGetAllComments {
  resource_id: string;
  parent_id?: string;
}

export interface IParamsReplyComment {
  comment_id: string;
  content: string;
  resource_id: string;
}

export const CommentResourceApi = {
  postCommentOnEvent: async (data: IParamsPostComment): Promise<CommentResponse> => {
    try {
      const response = await ApiCall.post(`${endpoints.commentResource}`, data, true);
      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  getCommentsList: async ({ resource_id, parent_id }: IParamsGetAllComments): Promise<CommentsResponse> => {
    try {
      const query = queryString.stringify({
        resource_id,
        parent_id,
      });
      const response = await ApiCall.get(`${endpoints.commentResource}?${query}`, true);

      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  likeAComment: async (comment_id: string): Promise<CommentResponse> => {
    try {
      const query = queryString.stringify({
        comment_id,
      });
      const response = (await ApiCall.put())(`${endpoints.commentResource}/like?${query}`, {}, true);
      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  unlikeAComment: async (comment_id: string): Promise<CommentResponse> => {
    try {
      const query = queryString.stringify({
        comment_id,
      });
      const response = (await ApiCall.put())(`${endpoints.commentResource}/unlike?${query}`, {}, true);

      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
  replyAComment: async (data: IParamsReplyComment): Promise<CommentResponse> => {
    try {
      const response = await ApiCall.post(`${endpoints.subCommentResource}`, data, true);

      return response;
    } catch (error) {
      return { data: null, error };
    }
  },
};
