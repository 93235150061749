export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_MENTOR = 'UPDATE_PROFILE_MENTOR';
export const TIME_ZONE = 'TIME_ZONE';
export const UPDATE_TOUR_COMPLETED = 'UPDATE_TOUR_COMPLETED';
export const DELETE_ACCOUNT = 'DELETE_ACCOUNT';
export const UPLOAD_IMAGE_TO_SERVICE = 'UPLOAD_IMAGE_TO_SERVICE';
export const VERIFY_ACCOUNT = 'VERIFY_ACCOUNT';
export const RESEND_CODE_VERIFY_ACCOUNT = 'RESEND_CODE_VERIFY_ACCOUNT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const GET_RESOURCE_STATISTICS = 'GET_RESOURCE_STATISTICS';
export const UPDATE_TOKEN_PROFILE = 'UPDATE_TOKEN_PROFILE';
