import { CommentApi } from '@/api/comment';
import { CommentsResponse, EventRequest } from '@/shared/interface';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { getCommentsList, getCommentsFailure, getCommentsSuccess } from './slice';
import { PayloadAction } from '@reduxjs/toolkit';

function* commentsRequest(action: PayloadAction<EventRequest>) {
  try {
    yield put(getCommentsList());
    const res: CommentsResponse = yield call(CommentApi.getCommentsList, action.payload);
    if (res.success) {
      yield put(getCommentsSuccess(res));
    } else {
      yield put(getCommentsFailure(res.message));
    }
  } catch (error) {
    yield put(getCommentsFailure(error?.toString()));
  }
}

export function* watchComments() {
  yield takeLatest(actionTypes.GET_COMMENT_LISTS, commentsRequest);
}
