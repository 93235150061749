import { AuthApi } from '@/api/auth';
import { ResourcesResponse } from '@/shared/interface';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { getResources, getResourcesSuccess, getResourcesFailure } from './slice';

function* resourcesRequest() {
  try {
    yield put(getResources());
    const res: ResourcesResponse = yield call(AuthApi.resources);
    if (res.success) {
      yield put(getResourcesSuccess(res));
    } else {
      yield put(getResourcesFailure(res.message));
    }
  } catch (error) {
    yield put(getResourcesFailure(error?.toString()));
  }
}

export function* watchResources() {
  yield takeLatest(actionTypes.GET_RESOURCES, resourcesRequest);
}
