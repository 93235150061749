import { t } from 'i18next';
import React from 'react';
import './discussionResourceInfo.scss';
type Props = {
  totalComment: number;
};

const DiscussionResourceInfo = React.memo(({ totalComment }: Props) => {
  return (
    <div className="discussion-info">
      <div className="discussion-info__content">
        <h4 className="discussion-info__content__title">{t('learn:discussBoard')}</h4>
        <div className="discussion-info__content__subTitle">{t('learn:shareYourThought')}</div>
      </div>
      <div className="discussion-info__total--comments">
        {totalComment} {t('learn:comments')}
      </div>
    </div>
  );
});
DiscussionResourceInfo.displayName = 'DiscussionResourceInfo';
export default DiscussionResourceInfo;
