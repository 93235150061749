export const StorageKeys = {
  session: 'session',
  timeExpires: 'timeExpires',
  refreshToken: 'RefreshToken',
  firebaseToken: 'firebaseToken',
  isTour: 'isTour',
};

export const localStorageService = {
  getAccessToken: async (): Promise<string | null> => {
    const sessionInfo = await localStorage.getItem(StorageKeys.session);
    if (sessionInfo) {
      return JSON.parse(sessionInfo)?.AuthenticationResult?.IdToken;
    }
    return null;
  },
  getExpireTime: async () => {
    const expireTime = await localStorage.getItem(StorageKeys.timeExpires);
    return expireTime;
  },
  getRefreshToken: async (): Promise<string | null> => {
    const refreshToken = await localStorage.getItem(StorageKeys.refreshToken);
    if (refreshToken) {
      return refreshToken;
    }
    return null;
  },
};
