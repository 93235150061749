import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CategoriesResponse } from '@/shared/interface';

interface CategoriesState {
  categoriesData: CategoriesResponse | null;
  loading: boolean;
  error?: string;
  isFetched: boolean;
}

const initialState: CategoriesState = {
  categoriesData: null,
  loading: false,
  error: undefined,
  isFetched: false,
};

export const categoriesSlide = createSlice({
  name: 'CategoriesSlide',
  initialState,
  reducers: {
    getCategories: (state: CategoriesState) => {
      state.loading = true;
    },
    getCategoriesFailure: (state: CategoriesState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
      state.isFetched = false;
    },
    getCategoriesSuccess: (state: CategoriesState, action: PayloadAction<CategoriesResponse>) => {
      state.loading = false;
      state.categoriesData = action.payload;
      state.error = initialState.error;
      state.isFetched = true;
    },
  },
});

export const { getCategories, getCategoriesSuccess, getCategoriesFailure } = categoriesSlide.actions;

export default categoriesSlide.reducer;
