import { TakeSurveyData } from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {
  requestSaveTakeSurvey,
  requestClearTakeSurvey,
  requestVAK_LearningSurvey,
  requestCleanVAK_LearningSurvey,
  requestHumanSurvey,
  requestCleanHumanSurvey,
  requestBrainSurvey,
  requestCleanBrainSurvey,
} from './slice';

function* saveTakeSurveyRequest(action: PayloadAction<TakeSurveyData>) {
  yield put(requestSaveTakeSurvey(action.payload));
}

function* clearTakeSurveyRequest() {
  yield put(requestClearTakeSurvey());
}

function* saveVAKSurveyRequest(action: PayloadAction<TakeSurveyData>) {
  yield put(requestVAK_LearningSurvey(action.payload));
}

function* clearVAKSurveyRequest() {
  yield put(requestCleanVAK_LearningSurvey());
}

function* saveHumanSurveyRequest(action: PayloadAction<TakeSurveyData>) {
  yield put(requestHumanSurvey(action.payload));
}

function* clearHumanSurveyRequest() {
  yield put(requestCleanHumanSurvey());
}

function* saveBrainSurveyRequest(action: PayloadAction<TakeSurveyData>) {
  yield put(requestBrainSurvey(action.payload));
}

function* clearBrainSurveyRequest() {
  yield put(requestCleanBrainSurvey());
}

export function* watchSurvey() {
  yield takeLatest(actionTypes.SAVE_TAKE_DATA, saveTakeSurveyRequest);
  yield takeLatest(actionTypes.CLEAR_TAKE_DATA, clearTakeSurveyRequest);
  yield takeLatest(actionTypes.SAVE_DATA_VAK, saveVAKSurveyRequest);
  yield takeLatest(actionTypes.CLEAR_DATA_VAK, clearVAKSurveyRequest);
  yield takeLatest(actionTypes.SAVE_DATA_HUMAN, saveHumanSurveyRequest);
  yield takeLatest(actionTypes.CLEAR_DATA_HUMAN, clearHumanSurveyRequest);
  yield takeLatest(actionTypes.SAVE_DATA_BRAIN, saveBrainSurveyRequest);
  yield takeLatest(actionTypes.CLEAR_DATA_BRAIN, clearBrainSurveyRequest);
}
