import { ApiCall, endpoints } from '../api';
import { Resource } from '@/shared/interface';

export const resourceAdminApi = {
  async getAll(): Promise<Resource[]> {
    try {
      const res = await ApiCall.get(endpoints.adminResource, true);
      return res;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error({ error });
      throw error;
    }
  },
};
