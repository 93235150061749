import { ILearningStyles } from '@/shared/interface';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IInitialState {
  learningStyles: ILearningStyles[];
  loading: boolean;
  isFetched: boolean;
}

const initialState: IInitialState = {
  learningStyles: [],
  loading: false,
  isFetched: false,
};

const learningStylesSlice = createSlice({
  name: 'MentorsState',
  initialState,
  reducers: {
    getLearningStyles: (state) => {
      state.loading = true;
    },
    getLearningStylesSuccess: (state, action: PayloadAction<ILearningStyles[]>) => {
      state.loading = false;
      state.learningStyles = action.payload;
      state.isFetched = true;
    },
    getLearningStylesFailure: (state) => {
      state.loading = false;
      state.isFetched = false;
    },
  },
});

export const { getLearningStyles, getLearningStylesSuccess, getLearningStylesFailure } = learningStylesSlice.actions;

export default learningStylesSlice.reducer;
