import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface ForgotPasswordState {
  email: string;
  loading: boolean;
  error?: string;
  success: boolean;

  loadingResetPassword: boolean;
  errorResetPassword?: string;
  successResetPassword: boolean;

  loadingResendForgotPassword: boolean;
  errorResendForgotPassword?: string;
  successResendForgotPassword: boolean;
}

const initialState: ForgotPasswordState = {
  email: '',
  loading: false,
  error: '',
  success: false,

  loadingResetPassword: false,
  errorResetPassword: '',
  successResetPassword: false,

  loadingResendForgotPassword: false,
  errorResendForgotPassword: '',
  successResendForgotPassword: false,
};

const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {
    forgotPassword: (state: ForgotPasswordState) => {
      state.loading = true;
    },
    forgotPasswordFailure: (state: ForgotPasswordState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    forgotPasswordSuccess: (state: ForgotPasswordState) => {
      state.loading = false;
      state.error = '';
      state.success = true;
    },

    resetPassword: (state: ForgotPasswordState) => {
      state.loadingResetPassword = true;
    },
    resetPasswordFailure: (state: ForgotPasswordState, action: PayloadAction<string | undefined>) => {
      state.loadingResetPassword = false;
      state.errorResetPassword = action.payload;
      state.successResetPassword = false;
    },
    resetPasswordSuccess: (state: ForgotPasswordState) => {
      state.loadingResetPassword = false;
      state.errorResetPassword = '';
      state.successResetPassword = true;
    },

    resendForgotPassword: (state: ForgotPasswordState) => {
      state.loadingResendForgotPassword = true;
    },
    resendForgotPasswordFailure: (state: ForgotPasswordState, action: PayloadAction<string | undefined>) => {
      state.loadingResendForgotPassword = false;
      state.errorResendForgotPassword = action.payload;
      state.successResendForgotPassword = false;
    },
    resendForgotPasswordSuccess: (state: ForgotPasswordState) => {
      state.loadingResendForgotPassword = false;
      state.errorResendForgotPassword = '';
      state.successResendForgotPassword = true;
    },

    setForgotPasswordEmail: (state: ForgotPasswordState, action: PayloadAction<string>) => {
      state.email = action.payload;
    },

    resetAllForgotPasswordState: () => {
      return initialState;
    },

    resetForgotPasswordState: (state: ForgotPasswordState) => {
      state.loading = false;
      state.error = '';
      state.success = false;
    },
  },
});

export const {
  forgotPassword,
  forgotPasswordFailure,
  forgotPasswordSuccess,

  resendForgotPassword,
  resendForgotPasswordFailure,
  resendForgotPasswordSuccess,

  resetPassword,
  resetPasswordFailure,
  resetPasswordSuccess,

  setForgotPasswordEmail,
  resetAllForgotPasswordState,
  resetForgotPasswordState,
} = forgotPasswordSlice.actions;
export default forgotPasswordSlice.reducer;
