import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ETypePlaylist, IPlaylistData } from '../interfaces';

interface IInitialState {
  playlists: IPlaylistData | null;
  loading: boolean;
  saveToPlaylistSuccess: boolean;
  unSaveToPlaylistSuccess: boolean;
  saveToPlayListError: string;
  unSaveToPlayListError: string;
  loadingSavePlaylist: boolean;
}

const initialState: IInitialState = {
  playlists: null,
  loading: false,
  saveToPlaylistSuccess: false,
  unSaveToPlaylistSuccess: false,
  saveToPlayListError: '',
  unSaveToPlayListError: '',
  loadingSavePlaylist: false,
};

const playlistsSlice = createSlice({
  name: 'MentorsState',
  initialState,
  reducers: {
    getAllSavedPlaylists: (state) => {
      state.loading = true;
    },
    getAllSavedPlaylistsSuccess: (state, action: PayloadAction<IPlaylistData>) => {
      state.loading = false;
      state.playlists = action.payload;
    },
    getAllSavedPlaylistsFailure: (state) => {
      state.loading = false;
    },
    saveToPlaylist: (state) => {
      state.saveToPlaylistSuccess = false;
      state.saveToPlayListError = '';
      state.loadingSavePlaylist = true;
    },
    saveToPlaylistSuccess: (state) => {
      state.saveToPlaylistSuccess = true;
      state.saveToPlayListError = '';
      state.loadingSavePlaylist = false;
    },
    saveToPlaylistFailure: (state, action: PayloadAction<string>) => {
      state.saveToPlaylistSuccess = false;
      state.saveToPlayListError = action.payload || `Save to playlist fail`;
      state.loadingSavePlaylist = false;
    },

    unSaveToPlaylist: (state) => {
      state.unSaveToPlaylistSuccess = false;
      state.unSaveToPlayListError = '';
      state.loadingSavePlaylist = true;
    },
    unSaveToPlaylistSuccess: (state, action: PayloadAction<{ type: ETypePlaylist; id: string | number }>) => {
      state.unSaveToPlaylistSuccess = true;
      state.unSaveToPlayListError = '';
      state.loadingSavePlaylist = false;
      const initialPlaylist: IPlaylistData | null = {
        ...state.playlists,
        articles: state.playlists?.articles ?? [],
        events: state.playlists?.events ?? [],
        journals: state.playlists?.journals ?? [],
        mentors: state.playlists?.mentors ?? [],
        podcasts: state.playlists?.podcasts ?? [],
        videos: state.playlists?.videos ?? [],
        career_stories: state.playlists?.career_stories ?? [],
        profiles: state.playlists?.profiles ?? [],
      };
      switch (action.payload.type) {
        //***** ARTICLE ***//
        case ETypePlaylist.Articles:
          state.playlists = {
            ...initialPlaylist,
            articles: state.playlists?.articles.filter((item) => item.id !== action.payload.id) ?? [],
          };
          break;

        //***** VIDEO ***//
        case ETypePlaylist.Videos:
          state.playlists = {
            ...initialPlaylist,
            videos: state.playlists?.videos.filter((item) => item.id !== action.payload.id) ?? [],
          };
          break;

        //***** MENTOR ***//
        case ETypePlaylist.Mentor:
          state.playlists = {
            ...initialPlaylist,
            mentors: state.playlists?.mentors.filter((item) => item.user_id !== action.payload.id) ?? [],
          };
          break;

        //***** EVENT ***//
        case ETypePlaylist.Event:
          state.playlists = {
            ...initialPlaylist,
            events: state.playlists?.events.filter((item) => item.id !== action.payload.id) ?? [],
          };
          break;

        //***** PODCAST ***//
        case ETypePlaylist.Podcast:
          state.playlists = {
            ...initialPlaylist,
            podcasts: state.playlists?.podcasts.filter((item) => item.id !== action.payload.id) ?? [],
          };
          break;

        //***** JOURNAL ***//
        case ETypePlaylist.Journal:
          state.playlists = {
            ...initialPlaylist,
            journals: state.playlists?.journals.filter((item) => item.id !== action.payload.id) ?? [],
          };
          break;

        //***** CAREER STORIES ***//
        case ETypePlaylist.Career_Stories:
          state.playlists = {
            ...initialPlaylist,
            career_stories: state.playlists?.career_stories.filter((item) => item.id !== action.payload.id) ?? [],
          };
          break;

        //***** PROFILES ***//
        case ETypePlaylist.Profiles:
          state.playlists = {
            ...initialPlaylist,
            profiles: state.playlists?.profiles.filter((item) => item.id !== action.payload.id) ?? [],
          };
          break;
        default:
          break;
      }
    },
    unSaveToPlaylistFailure: (state, action: PayloadAction<string>) => {
      state.unSaveToPlaylistSuccess = false;
      state.unSaveToPlayListError = action.payload || `UnSave from playlist fail`;
      state.loadingSavePlaylist = false;
    },
    clearUnSavePlaylistSuccess: (state) => {
      state.unSaveToPlaylistSuccess = false;
    },
    clearSavePlaylistSuccess: (state) => {
      state.saveToPlaylistSuccess = false;
    },
  },
});

export const {
  getAllSavedPlaylists,
  getAllSavedPlaylistsSuccess,
  getAllSavedPlaylistsFailure,
  saveToPlaylist,
  saveToPlaylistFailure,
  saveToPlaylistSuccess,
  unSaveToPlaylist,
  unSaveToPlaylistFailure,
  unSaveToPlaylistSuccess,
  clearUnSavePlaylistSuccess,
  clearSavePlaylistSuccess,
} = playlistsSlice.actions;

export default playlistsSlice.reducer;
