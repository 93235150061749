import { createSlice } from '@reduxjs/toolkit';
import { IConversation } from './interface';

interface MessageState {
  listMessages: IConversation[] | null;
  numberOfUnRead: number;
}

const initialState: MessageState = {
  listMessages: null,
  numberOfUnRead: 0,
};

export const messageSlice = createSlice({
  name: 'messageUserState',
  initialState,
  reducers: {
    setListMessageData: (state, action) => {
      state.listMessages = action.payload;
    },
    setUnReadData: (state, action) => {
      state.numberOfUnRead = action.payload;
    },
    resetNumberRead: (state) => {
      state.numberOfUnRead = 0;
    },
    resetMessage: () => initialState,
  },
});

export const { setListMessageData, setUnReadData, resetMessage, resetNumberRead } = messageSlice.actions;

export default messageSlice.reducer;
