import { WebStyleResponse } from '@/shared/interface';
import { call, takeLatest, put } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { getWebStyle, getWebStyleFailure, getWebStyleSuccess } from './slice';
import { WebStylesApi } from '@/api/webStyles';

function* getWebStyleFun() {
  try {
    yield put(getWebStyle());
    const res: WebStyleResponse = yield call(WebStylesApi.getWebStyles);
    if (res.success) {
      yield put(getWebStyleSuccess(res));
    } else {
      yield put(getWebStyleFailure(res.message));
    }
  } catch (error) {
    yield put(getWebStyleFailure(error?.toString()));
  }
}

export function* watchWebStyle() {
  yield takeLatest(actionTypes.GET_WEB_STYLE, getWebStyleFun);
}
