import { AxiosError } from 'axios';
import {
  BaseResponse,
  LoginRequest,
  LoginResponse,
  onBoardingRequest,
  ProfileResponse,
  ResourcesResponse,
  SignUpRequest,
  SignUpResponse,
  updateNotificationTokenRequest,
} from '@/shared/interface';
import { ApiCall, endpoints } from './api';
import { IPayloadChangePassword, IPayloadVerifyAccount } from '@/redux/interfaces';

export const AuthApi = {
  login: async (data: LoginRequest): Promise<LoginResponse> => await ApiCall.post(endpoints.login, data),

  resources: async (): Promise<ResourcesResponse> => await ApiCall.get(endpoints.resources),

  profile: async (): Promise<ProfileResponse> => ApiCall.get(endpoints.profile),

  signUp: async (data: SignUpRequest): Promise<SignUpResponse | AxiosError> =>
    await ApiCall.post(endpoints.signup, data),

  updateProfile: async (data: onBoardingRequest): Promise<ProfileResponse> =>
    (await ApiCall.put())(`${endpoints.profile}`, data),

  updateNotiTokenProfile: async (data: updateNotificationTokenRequest): Promise<ProfileResponse> =>
    (await ApiCall.put())(`${endpoints.profile}`, data),

  updateProfileMentor: async (data: onBoardingRequest): Promise<ProfileResponse> =>
    (await ApiCall.put())(`${endpoints.single_mentor}`, data),
  deleteAccount: async (): Promise<BaseResponse> => await ApiCall.delete(endpoints.account),
  verifyAccount: async (payload: IPayloadVerifyAccount): Promise<{ data: BaseResponse }> =>
    await ApiCall.post(endpoints.verifyAccount, payload, true),
  resendOTPVerify: async (payload: IPayloadVerifyAccount): Promise<{ data: BaseResponse }> =>
    await ApiCall.post(endpoints.resendOTPVerifyAccount, payload, true),

  changePassword: async (payload: IPayloadChangePassword): Promise<{ data: BaseResponse }> =>
    (await ApiCall.put())(endpoints.changePassword, payload),

  getResourceStatistics: async (): Promise<ResourcesResponse> => await ApiCall.get(endpoints.resourceStatistics, true),
};
