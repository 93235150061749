import { getCommentsResourceAction } from '@/redux/commentResource/action';
import { useAppDispatch, useAppSelector } from '@/redux/hook';
import { getResourceDetail } from '@/redux/learning/action';
import { RootState } from '@/redux/store';
import { scrollToTop } from '@/utils/scrollToTop';
import { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

const useCareerStoriesDetailScreen = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { data: dataComments } = useAppSelector((state: RootState) => state.commentResourceReducer);
  const { resourceDetail, loadingGetResourceDetail } = useAppSelector((state) => state.learningState);

  const htmlData = useMemo(() => {
    return resourceDetail?.data?.content;
  }, [resourceDetail?.data?.content]);

  useEffect(() => {
    if (!id) return;
    dispatch(getCommentsResourceAction({ resource_id: id }));
    dispatch(getResourceDetail({ id }));
  }, [id]);

  useEffect(() => {
    scrollToTop();
  }, [resourceDetail]);

  return {
    data: resourceDetail?.data || null,
    resourceDetail: resourceDetail?.data || null,
    comments: dataComments?.data || [],
    htmlData,
    loadingGetResourceDetail,
  };
};

export default useCareerStoriesDetailScreen;
