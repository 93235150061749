import { IResourceStatistics, ProfileData, ProfileResponse } from '@/shared/interface';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ProfileState {
  data: ProfileResponse | null;
  profileData: ProfileData | null;
  loading: boolean;
  loadingUpdate: boolean;
  error?: string;
  successUpdate: boolean;
  roleUser: string | null;
  errorUpdateProfile?: string;
  tourCompleted: boolean;
  loadingDeleteAccount: boolean;
  uploadAvatarSuccess: boolean;
  loadingVerifyAccount: boolean;
  verifyAccountSuccess: boolean;
  verifyAccountError: string;
  loadingResendOTPVerify: boolean;
  resendOTPVerifySuccess: boolean;
  resendOTPVerifyError: string;
  resourceStatistics: IResourceStatistics[] | null;
}

const initialState: ProfileState = {
  data: null,
  profileData: null,
  loading: false,
  loadingUpdate: false,
  error: undefined,
  successUpdate: false,
  roleUser: null,
  errorUpdateProfile: undefined,
  tourCompleted: false,
  loadingDeleteAccount: false,
  uploadAvatarSuccess: false,
  loadingVerifyAccount: false,
  verifyAccountError: '',
  verifyAccountSuccess: false,
  loadingResendOTPVerify: false,
  resendOTPVerifySuccess: false,
  resendOTPVerifyError: '',
  resourceStatistics: null,
};

export const sessionSlice = createSlice({
  name: 'ProfileState',
  initialState,
  reducers: {
    getProfile: (state: ProfileState) => {
      state.loading = true;
    },
    getProfileFailure: (state: ProfileState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getProfileSuccess: (state: ProfileState, action: PayloadAction<ProfileResponse>) => {
      state.loading = false;
      state.data = action.payload;
      state.roleUser = action.payload.data?.role;
      state.profileData = action.payload.data;
    },
    updateProfile: (state: ProfileState) => {
      state.loadingUpdate = true;
    },
    updateProfileFailure: (state: ProfileState, action: PayloadAction<string | undefined>) => {
      state.loadingUpdate = false;
      state.errorUpdateProfile = action.payload;
      state.successUpdate = false;
    },
    updateProfileSuccess: (state: ProfileState) => {
      state.loadingUpdate = false;
      state.successUpdate = true;
    },
    setRole: (state: ProfileState, action: PayloadAction<string>) => {
      state.roleUser = action.payload;
    },
    cleanUpdateProfileSuccess: (state: ProfileState) => {
      state.successUpdate = false;
    },
    cleanUpdateProfile: (state: ProfileState) => {
      state.successUpdate = false;
      state.loadingUpdate = false;
      state.errorUpdateProfile = undefined;
    },
    updateTourCompleted: (state) => {
      state.tourCompleted = false;
    },
    updateTourCompletedSuccess: (state) => {
      state.tourCompleted = true;
    },
    updateTourCompletedFail: (state) => {
      state.tourCompleted = false;
    },
    deleteAccount: (state) => {
      state.loadingDeleteAccount = true;
    },
    deleteAccountSuccess: (state) => {
      state.loadingDeleteAccount = false;
    },
    deleteAccountFailure: (state) => {
      state.loadingDeleteAccount = false;
    },

    uploadImageToService: (state) => {
      state.uploadAvatarSuccess = false;
    },
    uploadImageToServiceSuccess: (state) => {
      state.uploadAvatarSuccess = true;
    },
    uploadImageToServiceFailure: (state) => {
      state.uploadAvatarSuccess = false;
    },
    clearUploadAvatarSuccess: (state) => {
      state.uploadAvatarSuccess = false;
    },
    clearTourCompleted: (state) => {
      state.tourCompleted = false;
    },
    verifyAccount: (state) => {
      state.loadingVerifyAccount = true;
    },
    verifyAccountSuccess: (state) => {
      state.loadingVerifyAccount = false;
      state.verifyAccountSuccess = true;
      state.verifyAccountError = '';
    },
    verifyAccountFailure: (state, action: PayloadAction<string>) => {
      state.loadingVerifyAccount = false;
      state.verifyAccountSuccess = false;
      state.verifyAccountError = action.payload;
    },

    resendOTPVerify: (state) => {
      state.loadingResendOTPVerify = true;
    },
    resendOTPVerifySuccess: (state) => {
      state.loadingResendOTPVerify = false;
      state.resendOTPVerifySuccess = true;
      state.resendOTPVerifyError = '';
    },
    resendOTPVerifyFailure: (state, action: PayloadAction<string>) => {
      state.loadingResendOTPVerify = false;
      state.resendOTPVerifySuccess = false;
      state.resendOTPVerifyError = action.payload;
    },
    clearVerifyAccount: (state) => {
      state.verifyAccountError = '';
      state.verifyAccountSuccess = false;
    },
    clearResendOTPVerifyAccount: (state) => {
      state.resendOTPVerifyError = '';
      state.resendOTPVerifySuccess = false;
    },
    changePassword: (state) => {
      state.loading = true;
    },
    changePasswordSuccess: (state) => {
      state.loading = false;
    },
    changePasswordFailure: (state) => {
      state.loading = false;
    },
    getResourceStatistics: (state) => {
      state.loading = true;
    },
    getResourceStatisticsSuccess: (state, action: PayloadAction<IResourceStatistics[]>) => {
      state.loading = false;
      state.resourceStatistics = action.payload;
    },
    getResourceStatisticsFailure: (state) => {
      state.loading = false;
    },
  },
});

export const {
  getProfile,
  getProfileFailure,
  getProfileSuccess,
  updateProfile,
  updateProfileFailure,
  updateProfileSuccess,
  cleanUpdateProfileSuccess,
  setRole,
  cleanUpdateProfile,
  updateTourCompleted,
  updateTourCompletedFail,
  updateTourCompletedSuccess,
  deleteAccount,
  deleteAccountSuccess,
  deleteAccountFailure,
  clearUploadAvatarSuccess,
  uploadImageToService,
  uploadImageToServiceSuccess,
  uploadImageToServiceFailure,
  clearTourCompleted,
  verifyAccount,
  verifyAccountFailure,
  verifyAccountSuccess,
  resendOTPVerify,
  resendOTPVerifyFailure,
  resendOTPVerifySuccess,
  clearResendOTPVerifyAccount,
  clearVerifyAccount,
  changePassword,
  changePasswordSuccess,
  changePasswordFailure,
  getResourceStatistics,
  getResourceStatisticsSuccess,
  getResourceStatisticsFailure,
} = sessionSlice.actions;

export default sessionSlice.reducer;
