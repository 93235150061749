import './CustomBanner.scss';
import { CSSProperties } from 'react';
import Button from '@/component/Button';
import Image from '../Image';
import { BannerGoBack } from '@/assets/images';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

interface CustomBannerProps {
  bannerSrc: string;
  title: string;
  subTitle: string;
  isGoback?: boolean;
  pathGoback?: string;
  hasButtons?: boolean;
}
const CustomBanner = ({ bannerSrc, title, subTitle, isGoback, pathGoback, hasButtons }: CustomBannerProps) => {
  const navigate = useNavigate();

  return (
    <div className="banner" style={{ background: `url(${bannerSrc}) center / cover no-repeat` } as CSSProperties}>
      <div className="custom-banner__background"></div>
      {isGoback && pathGoback ? (
        <div className="back-button-container" onClick={() => navigate(-1)}>
          <Image src={BannerGoBack} className="back-button-container__icon" />
          <div className="back-button-container__text">{t('calculator:back')}</div>
        </div>
      ) : null}
      <h2 className="banner__title">{title}</h2>
      <div className="banner__text">{subTitle}</div>
      {hasButtons && (
        <div className="banner-button-container">
          <Button text={t('learn:discoverMore')} customClassName="banner-button-container__discover-btn" />
          <Button text={t('learn:trackMyProgress')} customClassName="banner-button-container__track-btn" />
        </div>
      )}
    </div>
  );
};

export default CustomBanner;
