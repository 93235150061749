import { BaseResponse, EventData, MentorData, Resource } from '@/shared/interface';
export enum ETypePlaylist {
  Articles = 'ARTICLE',
  Videos = 'VIDEO',
  Podcast = 'PODCAST',
  Journal = 'JOURNAL',
  Mentor = 'MENTOR',
  Event = 'EVENT',
  Career_Stories = 'CAREER STORIES',
  Profiles = 'PROFILES',
}
export interface IPlaylistData {
  articles: Resource[];
  videos: Resource[];
  podcasts: Resource[];
  journals: Resource[];
  career_stories: Resource[];
  profiles: Resource[];
  events: EventData[];
  mentors: MentorData[];
}

export interface IPlaylistResponse extends BaseResponse {
  data: IPlaylistData;
}

export interface IPayloadSaveToPlaylist {
  type: ETypePlaylist;
  id: string | number;
}

export interface IPayloadUnSaveToPlaylist extends IPayloadSaveToPlaylist {}
