import { categoriesApi } from '@/api/categories';
import { CategoriesResponse } from '@/shared/interface';
import { call, takeLatest, put, select } from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import { getCategories, getCategoriesFailure, getCategoriesSuccess } from './slice';
import { RootState } from '../store';

function* categoriesRequest() {
  try {
    const { isFetched } = yield select((state: RootState) => state.categoriesState);
    if (isFetched) {
      const res: CategoriesResponse = yield call(categoriesApi.getCategories);
      if (res.success) {
        yield put(getCategoriesSuccess(res));
      } else {
        yield put(getCategoriesFailure(res.message));
      }
      return;
    }

    // Put action for first time
    yield put(getCategories());
    const res: CategoriesResponse = yield call(categoriesApi.getCategories);
    if (res.success) {
      yield put(getCategoriesSuccess(res));
    } else {
      yield put(getCategoriesFailure(res.message));
    }
  } catch (error) {
    yield put(getCategoriesFailure(error?.toString()));
  }
}

export function* watchCategories() {
  yield takeLatest(actionTypes.GET_CATEGORIES, categoriesRequest);
}
