/* eslint-disable @typescript-eslint/no-explicit-any */
export const uploadFile = async (uploadURL: string, file?: File | string) => {
  if (!file || !uploadURL) return;
  try {
    const headers = {
      'Content-Type': 'image/jpeg',
    };
    const response = await fetch(uploadURL, {
      method: 'put',
      headers,
      body: file,
    });
    return response;
  } catch (error: any) {
    throw error.response;
  }
};

// eslint-disable-next-line prettier/prettier
export const uploadAvatar = async (URL: string, image?: File | string) => {
  const data = await uploadFile(URL, image);
  return data;
};
