import {
  ICardResponse,
  IPayloadAddCard,
  IPayloadConfirm,
  IPayloadDeleteCard,
  IPayloadRateMentor,
  IPayloadUpdateDefaultCard,
} from '@/redux/interfaces';
import { CreateStripeResponse, ICreateBookingRequest, KeyStripeResponse, ProfileResponse } from '@/shared/interface';
import { ApiCall, endpoints } from './api';

export const paymentApi = {
  getAllCardsPayment: async (): Promise<ProfileResponse> => await ApiCall.get(endpoints.profile),

  addCardPayment: async (data: IPayloadAddCard): Promise<ICardResponse> => {
    // eslint-disable-next-line no-useless-catch
    try {
      return await ApiCall.post(endpoints.cardPayment, data);
    } catch (error) {
      throw error;
    }
  },

  deleteCardPayment: async (data: IPayloadDeleteCard): Promise<ICardResponse> =>
    await ApiCall.delete(`${endpoints.cardPayment}?payment_method_id=${data.payment_method_id}`),

  updateCardPaymentDefault: async (data: IPayloadUpdateDefaultCard): Promise<ICardResponse> =>
    (await ApiCall.put())(endpoints.updateCardDefault, data),

  confirmPayment: async (data: IPayloadConfirm): Promise<CreateStripeResponse> =>
    await ApiCall.post(endpoints.confirmPayment, data),

  createPayment: async (): Promise<CreateStripeResponse> => await ApiCall.post(endpoints.createPayment, {}, true),
  updateStripeMentor: async (): Promise<ProfileResponse> => (await ApiCall.put())(endpoints.stripeUpdate, {}, true),

  getKeyStripe: async (data: ICreateBookingRequest): Promise<KeyStripeResponse> =>
    await ApiCall.post(endpoints.intentPayment, data),

  rateMentor: async (data: IPayloadRateMentor): Promise<ProfileResponse> =>
    (await ApiCall.put())(endpoints.rateMentor, data),
};
