import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetNotificationsResponse } from '../interfaces';

interface NotificationState {
  dataNotification: GetNotificationsResponse | null;
  loading: boolean;
  error?: string;
}

const initialState: NotificationState = {
  dataNotification: null,
  loading: false,
  error: undefined,
};

export const sessionSlice = createSlice({
  name: 'NotificationState',
  initialState,
  reducers: {
    getNotification: (state: NotificationState) => {
      state.loading = true;
    },
    getNotificationFailure: (state: NotificationState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    getNotificationSuccess: (state: NotificationState, action: PayloadAction<GetNotificationsResponse>) => {
      state.loading = false;
      state.dataNotification = action.payload;
      state.error = undefined;
    },
    clearGetNotification: (state: NotificationState) => {
      state.error = undefined;
      state.dataNotification = null;
      state.loading = false;
    },

    readNotification: (state: NotificationState) => {
      state.loading = true;
    },
    readNotificationFailure: (state: NotificationState, action: PayloadAction<string | undefined>) => {
      state.loading = false;
      state.error = action.payload;
    },
    readNotificationSuccess: (state: NotificationState) => {
      state.loading = false;
      state.error = undefined;
    },
  },
});

export const {
  getNotification,
  getNotificationFailure,
  getNotificationSuccess,
  clearGetNotification,
  readNotification,
  readNotificationFailure,
  readNotificationSuccess,
} = sessionSlice.actions;

export default sessionSlice.reducer;
