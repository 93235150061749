import { BaseResponse } from '@/shared/interface';

export interface IPayloadCreatePaymentRegisterEvent {
  ticket_id: string;
  quantity: number;
}

export interface IDataPaymentIntentEvent {
  intent_id: string;
  client_secret: string;
}

export interface IResponseCreatePaymentRegisterEvent extends BaseResponse {
  data: IDataPaymentIntentEvent;
}

export interface IPayloadGetSavedEvent {
  event_id: string;
  status: string;
}

export interface IDataSavedEvent {
  user_id: string;
  event_id: string;
  quantity: number;
  status: string;
  _id: string;
  id: string;
}

export interface IResponseGetSavedEvent extends BaseResponse {
  data: IDataSavedEvent;
}

export enum IEventStatus {
  Pending = 'pending',
  Completed = 'completed',
}

export interface IPayloadSaveEvent {
  event_id: string;
  quantity: number;
}

export interface IPayloadFilterGetAllEvents {
  page?: number;
  limit?: number;
  search?: string;
}

export enum ETypeEventBusiness {
  Upcoming = 'Upcoming',
  Past = 'Past',
}
