import React from 'react';
import '@/assets/scss/component/button.scss';
import '@/assets/scss/component/loading.scss';
interface ButtonProps
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  text: string;
  customClassName?: string;
  isLoading?: boolean;
}

const Button: React.FC<ButtonProps> = ({ text, customClassName, isLoading, ...props }) => {
  return (
    <button {...props} className={`container_button ${customClassName}`}>
      {isLoading ? (
        <div>
          <span className="loading"></span>
        </div>
      ) : (
        text
      )}
    </button>
  );
};

export default Button;
