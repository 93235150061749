import {
  AvailabilityResponse,
  MentorRequest,
  UpdateAvailabilityRequest,
  UpdateAvailabilityResponse,
} from '@/shared/interface';
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeLatest, put } from 'redux-saga/effects';
import {
  getAvailabilityData,
  getAvailabilitySuccess,
  getAvailabilityFailure,
  updateAvailabilityData,
  updateAvailabilitySuccess,
  updateAvailabilityFailure,
} from './slice';
import * as actionTypes from './actionTypes';
import { AvailabilityApi } from '@/api/availability';

function* getAvailabilityRequest(action: PayloadAction<MentorRequest>) {
  try {
    yield put(getAvailabilityData());
    const res: AvailabilityResponse = yield call(AvailabilityApi.getAvailability, action.payload);
    if (res.success) {
      yield put(getAvailabilitySuccess(res));
    } else {
      yield put(getAvailabilityFailure(res.message));
    }
  } catch (error) {
    yield put(getAvailabilityFailure(error?.toString()));
  }
}

function* updateAvailabilityRequest(action: PayloadAction<UpdateAvailabilityRequest>) {
  try {
    yield put(updateAvailabilityData());
    const res: UpdateAvailabilityResponse = yield call(AvailabilityApi.updateAvailability, action.payload);
    if (res.success) {
      yield put(updateAvailabilitySuccess(res));
    } else {
      yield put(updateAvailabilityFailure(res.message));
    }
  } catch (error) {
    yield put(updateAvailabilityFailure(error?.toString()));
  }
}

export function* watchAvailability() {
  yield takeLatest(actionTypes.GET_AVAILABILITY, getAvailabilityRequest);
  yield takeLatest(actionTypes.UPDATE_AVAILABILITY, updateAvailabilityRequest);
}
